<div *ngIf="!noHeader" class="header pb-5 pt-5 pt-lg-7 d-flex align-items-center">
  <!-- Mask -->
  <span class="mask bg-gradient-primary opacity-8"></span>
  <!-- Header container -->
  <div class="container-fluid d-flex align-items-center">
    <div class="row">
      <div class=" col-12">
        <button class="btn btn-sm btn-warning mb-2" (click)="location.back()">Go Back</button>
      </div>
    </div>
  </div>
</div>

<div class="{{(!noHeader)?'container-fluid mt--5':''}}">
  <div class="card shadow border-0">
    <div class="card-header">
      <h3 *ngIf="patient" class="mb-0">{{this.patient.title}} {{this.patient.firstName}} {{this.patient.lastName}}'s
        Profile</h3>
      <div *ngIf="patient" style="position: absolute; right:0; top:0; background-color: rgba(0, 0, 0, 0.08);"
        class="px-3 py-1">
        <label style="font-size: 0.75em;" class="mb-0">CR Number</label> <br />
        {{(this.patient.crNumber)?this.patient.crNumber:"No CR Number"}}
      </div>
    </div>
    <div *ngIf="patient" class="card-body">
      <div class="row no-gutters">
        <div class="col-auto">
          <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            style="width:200px;" />
        </div>
        <div class="col-12 col-md-auto pl-md-3">

          <div class="table table-responsive table">
            <tr>
              <td style="font-weight: 600;">Name:</td>
              <td>{{this.patient.title}} {{this.patient.firstName}} {{this.patient.lastName}}</td>
              <td style="font-weight: 600;">Gender:</td>
              <td>{{this.patient.gender}}</td>
              <td style="font-weight: 600;">Contact Number:</td>
              <td>{{this.patient.phoneNumber}}</td>
            </tr>
            <!-- <tr>
                            <td>CR Number:</td>
                            <td>{{this.patient.crNumber}}</td>
                        </tr> -->
            <tr *ngIf="this.patient.age">
              <td style="font-weight: 600;">Age:</td>
              <td>{{this.patient.age}}</td>
            </tr>
            <tr *ngIf="this.patient.dob">
              <td style="font-weight: 600;">Date of Birth:</td>
              <td>{{this.patient.dob}}</td>
              <td style="font-weight: 600;">Blood Group:</td>
              <td>{{this.patient?.bloodGroup}}</td>
              <td style="font-weight: 600;">Identity Proff:</td>
              <td colspan="4">{{this.patient.AadharCardNumber}}</td>
            </tr>
            <tr>
              <td style="font-weight: 600;">E-mail Address:</td>
              <td colspan="6">
                <div style="word-wrap: break-word; white-space: initial;">
                  {{this.patient.email}}
                </div>
              </td>
            </tr>
            <tr>
              <td style="font-weight: 600;">Address:</td>
              <td colspan="6">
                <div style="word-wrap: break-word; white-space: initial;">
                  {{this.patient.line1}} , {{this.patient.line2}}
                </div>
              </td>
            </tr>
          </div>
          <div *ngIf="['admin','superadmin'].includes(this.authService.getTokens()[1])" class="form-group form-check">
            <input type="checkbox" class="form-check-input " name="poorFree" id="poorFree"
              (ngModelChange)="poorFreeChanged()" [(ngModel)]="poorFree.checked">
            <label class="form-check-label" for="poorFree">Poor Free</label>
          </div>
          <div class="mt-3">
            <button class="btn btn-sm btn-warning" (click)="router.navigate(['/edit-patient/'+patient._id])"><i
                class="fa fa-edit"></i> Edit</button>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="!this.patient" class="card-body d-flex justify-content-center align-items-center"
      style="background-color: rgba(0, 0, 0, 0.08);">

      <h2 class="text-gray">Loading...</h2>
    </div>
    <div class="p-3">
      <ul *ngIf="patient" class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['./history']" routerLinkActive="active">History</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['./visits']" routerLinkActive="active">OPD Visits</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['./transactions']" routerLinkActive="active">Transactions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Addmissions</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['./appointments']" routerLinkActive="active">Appointments</a>
        </li>
        <li class="nav-item">
          <a class="nav-link">Labs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['./imaging']" routerLinkActive="active">Imaging</a>
        </li>
      </ul>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- <div class="nav-wrapper">
    <ul class="nav nav-pills nav-pills-circle" id="tabs_2" role="tablist">
        <li class="nav-item">
          <a class="nav-link rounded-circle active" id="home-tab" data-toggle="tab" href="#tabs_2_1" role="tab" aria-controls="home" aria-selected="true">
            <span class="nav-link-icon d-block"><i class="ni ni-atom"></i></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabs_2_2" role="tab" aria-controls="profile" aria-selected="false">
            <span class="nav-link-icon d-block"><i class="ni ni-chat-round"></i></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#tabs_2_3" role="tab" aria-controls="contact" aria-selected="false">
            <span class="nav-link-icon d-block"><i class="ni ni-cloud-download-95"></i></span>
          </a>
        </li>
    </ul>
</div>
<div class="card shadow">
    <div class="card-body">
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="tabs-icons-text-1" role="tabpanel" aria-labelledby="tabs-icons-text-1-tab">
                <p class="description">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth.</p>
                <p class="description">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse.</p>
            </div>
            <div class="tab-pane fade" id="tabs-icons-text-2" role="tabpanel" aria-labelledby="tabs-icons-text-2-tab">
                <p class="description">Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
            </div>
            <div class="tab-pane fade" id="tabs-icons-text-3" role="tabpanel" aria-labelledby="tabs-icons-text-3-tab">
                <p class="description">Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth.</p>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="container-fluid mt-4">
    <div class="row no-gutters my-3 px-1">
        <div class="nav-tabs-navigation">
            <div class="nav-tabs-wrapper">
                <ul class="nav nav-tabs w-100">
                    <li class="nav-item">
                        <a class="nav-link active" href="#!">History</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#!">General</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="#!">OPD Visit</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="#!">Transactions</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#!">Appointments</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="mx-3">
        <h2>OPD Entries:</h2>
    </div>
</div> -->
