import {AfterViewInit, OnInit, Component, ElementRef, ViewChild} from '@angular/core';
// import {environment} from '../../environments/environment';
//import {DataService} from '../../data.service';
// import {Message} from './types/message';

export const ENV_RTCPeerConfiguration = {
  iceServers: [
    {
      urls: 'stun:stun1.l.google.com:19302'
    }
  ]
};

const mediaConstraints = {
  audio: true,
  video: {width: 1280, height: 720}
  // video: {width: 1280, height: 720} // 16:9
  // video: {width: 960, height: 540}  // 16:9
  // video: {width: 640, height: 480}  //  4:3
  // video: {width: 160, height: 120}  //  4:3
};

const offerOptions = {
  offerToReceiveAudio: true,
  offerToReceiveVideo: true
};

@Component({
  selector: 'app-demo-appointment',
  templateUrl: './demo-appointment.component.html',
  styleUrls: ['./demo-appointment.component.css'],
})

export class DemoAppointmentComponent implements OnInit {
  // public isCallStarted$: Observable<boolean>;
  // private peerId: string;

  // @ViewChild('localVideo') localVideo: ElementRef<HTMLVideoElement>;
  // @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;

  constructor(/*public dialog: MatDialog, private callService: CallService*/) {
    // this.isCallStarted$ = this.callService.isCallStarted$;
    // this.peerId = this.callService.initPeer();
  }

  ngOnInit(): void {
    // this.callService.localStream$
    //   .pipe(filter(res => !!res))
    //   .subscribe(stream => this.localVideo.nativeElement.srcObject = stream)
    // this.callService.remoteStream$
    //   .pipe(filter(res => !!res))
    //   .subscribe(stream => this.remoteVideo.nativeElement.srcObject = stream)
  }

  // ngOnDestroy(): void {
  //   this.callService.destroyPeer();
  // }

  // public showModal(joinCall: boolean): void {
  //   console.log(this.peerId);
  //   let dialogData: DialogData = joinCall ? ({ peerId: null, joinCall: true }) : ({ peerId: this.peerId, joinCall: false });
  //   const dialogRef = this.dialog.open(CallInfoDialogComponents, {
  //     width: '250px',
  //     data: dialogData
  //   });

  //   dialogRef.afterClosed()
  //     .pipe(
  //       switchMap(peerId =>
  //         joinCall ? of(this.callService.establishMediaCall(peerId)) : of(this.callService.enableCallAnswer())
  //       ),
  //     )
  //     .subscribe(_  => { });
  // }

  // public endCall() {
  //   this.callService.closeMediaCall();
  // }
}
