import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {baseUrl} from './config';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  async getUsers() {
    try {
      let res:any = await this.httpClient.get(baseUrl+"users", this.getHeaders()).toPromise();
      return res.users;
    }
    catch (e) {
      console.log(e);
      return {error: "Some error occured fetching users"}
    }
    
  }

  async postUser(user) {
    try {
      let res:any = await this.httpClient.post(baseUrl+"users",user, this.getHeaders()).toPromise();
      return res.user;
    }
    catch (e) {
      console.log(e);
      return {error: "Some error occured fetching users"}
    }
  }

  async changePassword(id,newpassword) {
    try {
      let res:any = await this.httpClient.post(baseUrl+`user/${id}/password`,{password:newpassword}, this.getHeaders()).toPromise();
      return res.user;
    }
    catch (e) {
      console.log(e);
      return {error: "Some error occured changing password"}
    }
  }

  async changeSelfPassword(password) {
    try {
      let res:any = await this.httpClient.post(baseUrl+`user/me/password`,{password}, this.getHeaders()).toPromise();
      return res.user;
    }
    catch (e) {
      console.log(e);
      return {error: "Some error occured changing password"}
    }
  }

  async deleteUser(id) {
    try {
      let res:any = await this.httpClient.delete(baseUrl+`user/${id}`,this.getHeaders()).toPromise();
      return res.user;
    }
    catch (e) {
      console.log(e);
      return {error: "Some error occured fetching users"}
    }
  }

  getHeaders() {
    return {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}};
  }

}
