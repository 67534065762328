<app-default-header></app-default-header>
<div class="container-fluid mt-4">
  <div class="row">
    <div class="col">
      <div class="card shadow border-0">
        <div class="card-header">
          <div class="row no-gutters align-items-center justify-content-between">
            <div class="col-auto">
              <h2>OPD Patients</h2>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-4 align-items-center">
            <div class="col">
              <div class="row">
                <!-- <div class="col-auto">
                  <button class="btn btn-sm btn-primary" (click)="prev()">Prev</button>
                </div>
                <div class="col-auto text-center">
                  <h3 *ngIf="!edit" (click)="edit=true">{{date | date:'fullDate'}}</h3>
                  <input *ngIf="edit" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="setDate($event)" type="date"
                    name="date" class="form-control mx-auto " style="width: 250px !important;">
                </div>
                <div class="col-auto">
                  <button class="btn btn-sm btn-primary" (click)="next()" [disabled]="date >= todays_date">Next</button>
                </div> -->
                <div class="col-auto">
                  <label for="fromDate" class="form-control-label">From:</label>
                  <input type="date" name="fromDate" [(ngModel)]="from" (ngModelChange)="refreshPatients()"
                    class="form-control form-control-alternative" />
                </div>
                <div class="col-auto">
                  <label for="toDate" class="form-control-label">To:</label>
                  <input type="date" name="toDate" [(ngModel)]="to" (ngModelChange)="refreshPatients()"
                    class="form-control form-control-alternative" />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button [disabled]="loading || exporting || paitents.length < 1" (click)="export()"
                class="btn-sm btn btn-success">
                <i *ngIf="!loading && !exporting" class="fa fa-cloud-download-alt mr-1"></i><i
                  *ngIf="loading || exporting" class="fa fa-spinner fa-spin mr-1"></i>Export
              </button>
            </div>
          </div>

          <div class="row no-gutters g-0 mb-1 align-items-center">
            <div class="col pb-1">
              <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control"
                placeholder="Search by name or CR No.">
            </div>
            <div *ngIf="search" (click)="cancel()" class="col-auto">
              <button class="btn btn-warning ml-2 mt--1">Cancel</button>
            </div>
          </div>

          <div *ngIf="loading"
            style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
            <span style="color:#333">Loading...</span>
          </div>
          <div *ngIf="paitents && paitents.length == 0 && !loading"
            style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
            <h3 *ngIf="search" style="color:#333">No Search Items</h3>
            <h3 *ngIf="!search">No OPD Visits or Patients created today. Please create a new paitent / mark an opd
              visit.</h3>
          </div>

          <app-patient-list [patients]="paitents" (patientUpdated)="opdModalSuccess()"></app-patient-list>


        </div>
        <div *ngIf="page_numbers.length>1 && showPaging" class="card-footer py-4">
          <nav aria-label="...">
            <ul class="pagination justify-content-end mb-0">
              <li class="page-item {{(page_no==1)?'disabled':''}}">
                <a class="page-link" [routerLink]="(page_no!=1)?['/opd-patients/'+(+page_no-1)]:[]" tabindex="-1">
                  <i class="fas fa-angle-left"></i>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li *ngFor="let page of page_numbers" class="page-item {{(page == page_no)?'active':''}}">
                <a class="page-link" [routerLink]="['/opd-patients/'+page]">{{page}}</a>
              </li>
              <li class="page-item">
                <a class="page-link" [routerLink]="(page_no<page_numbers.length)?['/opd-patients/'+(+page_no+1)]:[]">
                  <i class="fas fa-angle-right"></i>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
