import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Route } from "@angular/compiler/src/core";
import { Location } from "@angular/common";
import { PatientService } from "src/app/patient.service";
import { AuthService } from "src/app/auth.service";

@Component({
  selector: "app-patient-profile",
  templateUrl: "./patient-profile.component.html",
  styleUrls: ["./patient-profile.component.css"],
})
export class PatientProfileComponent implements OnInit {
  loading = false;
  patient = undefined;
  @Input() patient_id = undefined;
  @Input() noHeader;
  entries;

  public poorFree = { checked: false, loading: true };
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public patientService: PatientService,
    public authService: AuthService
  ) {}

  refershPatient() {
    this.loading = true;
    this.patientService.getPatient(this.patient_id).then(async (res) => {
      this.patient = res;
      this.poorFree.loading = false;
      this.poorFree.checked = res.poorFree;
      this.entries = await this.patientService.getOPDEntry(res._id);
      console.log(this.entries);
    });
  }

  poorFreeChanged() {
    this.poorFree.loading = true;
    window.setTimeout(async () => {
      console.log(this.poorFree);
      this.patient = await this.patientService.markPoorFree(
        this.patient._id,
        this.poorFree.checked
      );
      this.poorFree.loading = false;
    }, 0);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (!params["id"] && !this.patient_id) this.location.back();
      if (params["id"]) this.patient_id = params["id"];
      this.refershPatient();
    });
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
     console.log(this.patient_id);
     this.refershPatient();
  }
}
