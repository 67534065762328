<app-modal [visible]="visible" (onClose)="closeHandler()" (onSuccess)="successHandler()" title="Refund Transaction">
    <div *ngIf="!transacted">
        <h6 class="heading-small font-weight-bold">Refund {{(amount>0)?'Rs. '+amount+' ':""}}To {{transaction.patient_id.title}} {{transaction.patient_id.firstName}} {{transaction.patient_id.lastName}} </h6>
        <div class="from-group">
            <label for="" class="label-form-control">Amount</label>
            <input type="number" name="amount" class="form-control form-control-alternative" [(ngModel)]="amount" (ngModelChange)="changeHandler(e)" />
            <div *ngIf="validationError" class="alert px-0 text-danger">{{validationError}}</div>
        </div>
    </div>
    <div *ngIf="transacting">
        Transacting...
    </div>
    <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
        <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
        <button class="d-block ml-auto btn btn-danger btn-sm" (click)="clickHandler()">Request Refund Approval</button>
    </div>    
</app-modal>
