<div class="card shadow-0 border">
    <div *ngIf="appointmentsData.length > 0">
        <div class="table-responsive">
            <table class="table" style="overflow-x: scroll;">
                <thead class="thead-dark">
                    <tr>
                        <th>Date</th>
                        <th>Patient Name</th>
                        <th>App. Number</th>
                        <th>Gender</th>
                        <th>Date of Birth</th>
                        <th>Mobile Number</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let appointment of appointmentsData">
                        <td>{{appointment.date | date:'mediumDate'}}</td>
                        <td>{{appointment.title}} {{appointment.firstName}} {{appointment.lastName}}</td>
                        <td>{{appointment.appointmentNumber}}</td>
                        <td>{{appointment.gender}}</td>
                        <td>{{appointment.dob | date:'mediumDate'}}</td>
                        <td>{{appointment.phoneNumber}}</td>
                        <td>
                            <div *ngIf="appointment.status == 'completed'"> ✅ Completed </div>
                            <div *ngIf="appointment.status == 'canceled'" class="text-warning">❌ Canceled </div>
                            <div *ngIf="appointment.status == 'pending'" class="text-warning">Pending </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="!appointmentsData"
        style="height: 300px; display: flex; align-items: center; justify-content: center; background-color: rgb(221, 221, 221);">
        <h3>Loading...</h3>
    </div>
    <div *ngIf="appointmentsData && appointmentsData.length < 1"
        style="height: 300px; display: flex; align-items: center; justify-content: center; background-color: rgb(221, 221, 221);">
        <h3>No Appointment!</h3>
    </div>
</div>
