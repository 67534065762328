<app-default-header></app-default-header>
<div class="container-fluid mt-4">
  <div class="row">
    <div class="col">
      <div class="card shadow border-0">
        <div class="card-header">
          <div class="row no-gutters align-items-center justify-content-between">
            <div class="col-auto">
              <h2>Appointment Settings</h2>
            </div>
          </div>
        </div>
        <div class="card-body" style="background-color: rgb(253, 253, 253);">
          <h4>General Settings</h4>
          <div class="row align-items-center no-gutters">
            <div class="col-4 col-md-3 p-2 setting-text">
              Weekday Appointments per Day:
            </div>
            <div class="col-8 col-md-9 p-2">
              <input [disabled]="loading" type="text" class="form-control form-control-sm form-control-alternative" />
            </div>
          </div>
          <div class="row align-items-center no-gutters">
            <div class="col-4 col-md-3 p-2 setting-text">
              Weekend Appointments per Day:
            </div>
            <div class="col-8 col-md-9 p-2">
              <input type="text" [disabled]="loading" class="form-control form-control-sm form-control-alternative" />
            </div>
          </div>
          <h4 class="mt-3">Excluded / Holidays</h4>
          <div class="row no-gutters ">
            <div class="col-auto p-2 setting-text">
              Weekday OPD excluded dates:
            </div>
            <div class="col p-2">
              <div *ngIf="!loading">
                <span *ngFor="let i of array" class="badge badge-pill badge-primary ml-1 mb-1">
                  12/08/2018
                  <a href="#" (click)="alert('hey')"><i class="fa fa-times ml-2 text-danger"></i></a>
                </span>
              </div>
              <div *ngIf="loading" class="setting-text">
                <i class="fa fa-spinner fa-spin"></i> Loading....
              </div>
            </div>
            <div class="col-auto p-2">
              <button [disabled]="loading" class="btn btn-sm btn-success">Add <i class="fa fa-plus"></i></button>
            </div>
          </div>
          <div class="row no-gutters ">
            <div class="col-auto p-2 setting-text">
              Weekday OPD excluded dates:
            </div>
            <div class="col p-2">
              <div *ngIf="!loading">
                <span *ngFor="let i of array" class="badge badge-pill badge-primary ml-1 mb-1">
                  12/08/2018 <a href="#" (click)="alert('hey')"><i class="fa fa-times ml-2 text-danger"></i></a>

                </span>
              </div>
              <div *ngIf="loading" class="setting-text">
                <i class="fa fa-spinner fa-spin"></i> Loading....
              </div>
            </div>
            <div class="col-auto p-2">
              <button [disabled]="loading" class="btn btn-sm btn-success">Add <i class="fa fa-plus"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
