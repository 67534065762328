import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Location } from '@angular/common';
import { PatientService } from 'src/app/patient.service';
import { Router, ActivatedRoute } from '@angular/router';
import {startCase} from 'lodash';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let crNumberRegEx = /^.{10,10}$/;

@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.css']
})
export class CreatePatientComponent implements OnInit {

  formData:any = {
    title:'',
    gender:'',
    bloodGroup:'',
  };
  error = {}
  loading = false;
  main_error = {
    visible:false,
    message:""
  }
  age;
  fields = ["title","firstName","lastName","gender","dob","line1","line2","phoneNumber","email","crNumber","AadharCardNumber",];
  requiredFields = ["title","firstName","lastName","gender","dob","line1","line2","phoneNumber","AadharCardNumber",];
  edit:boolean;
  patient_id;
  patientRealCR = undefined;
  checkingCR = false;
  crExists = false;
  crID;

  constructor(public auth:AuthService, public location:Location, public patientService:PatientService, public router:Router, public route:ActivatedRoute) { }

  printFormData(){ console.log(this.formData, this.error) }

  async validateField(selector) {
    if(this.requiredFields.includes(selector) && (!this.formData[selector] || this.formData[selector] == "")){
      this.error[selector] =startCase(selector) + " is Required!";
      return false;
    }
    if(!await this.customValidations(selector)){
      this.error[selector] =`Please enter a valid ${startCase(selector)}`;
      return false;
    }
    this.main_error.visible = false;
    this.error[selector] = undefined
    this.main_error.message = ""
    return true;
  }

  async customValidations(selector) {

    if(selector == "dob") {
        let formDate = new Date(this.formData['dob']);
        formDate.setHours(0,0,0,0);
        let todayDate = new Date();
        todayDate.setHours(0,0,0,0);
        let lowerLimt = new Date(1900,0);
        console.log({formDate,todayDate,lowerLimt});
        if( todayDate < formDate  || formDate < lowerLimt) return false;
        let b = new Date(todayDate.getTime() - formDate.getTime());
        let age = {
          years:b.getFullYear() - 1970,
          months:b.getMonth()
        }
        this.age = `${age.years} Year${(age.years>1)?"s":""}, ${age.months} Month${(age.months>1)?"s":""}.`
    }

    if(selector == "phoneNumber") {
      if(this.formData["phoneNumber"] < 5999999999 || this.formData["phoneNumber"] > 9999999999) return false
    }

    if(selector == "email") {
      let email = this.formData["email"];
      if(!email || email=="") return true;
      this.formData["email"] = email.toLowerCase();
      return emailRegEx.test(email);
    }

    if(selector == "crNumber") {
      let crNumber = this.formData["crNumber"];

      if(!crNumber || crNumber == "") {
        this.formData["crNumber"] = undefined;
        return true;
      }

      return crNumberRegEx.test(crNumber);

    }

    return true;
  }

  async validateForm() {

    let flag = true;
    for(let field of this.fields) {
      if(!await this.validateField(field)) flag = false;
    }
    return flag;

  }

  async submit() {
    this.loading = true;
    let crValid = await this.validateCR();
    if(!(await this.validateForm()) || !crValid) {
      this.main_error.visible = true;
      this.main_error.message = "All Fields marked with asterisk(*) are required!";
      this.loading = false
      return;
    }

    this.main_error.visible = false;
    this.main_error.message = "";

    if(this.edit) {
      this.patientService.addPatient(this.formData).then(res=>{
        this.loading = false;
        this.router.navigate(['/reception']);
      }).catch(rej=>{
        this.loading = false;
      })
    }

    if(!this.edit) {
      this.patientService.addPatient(this.formData).then(res=>{
        this.loading = false;
        this.router.navigate(['/reception']);
      }).catch(rej=>{
        this.loading = false;
      })
    }



  }

  async validateCR() {

    if(this.edit && this.formData["crNumber"] == this.patientRealCR) {
      this.crExists = false;
      this.crID = undefined;
      return true;
    }

    this.checkingCR = true;
    let res = await this.patientService.checkCRNumber(this.formData["crNumber"]);
    let checkCrNumber = res.available;
    this.checkingCR =false;

    if(checkCrNumber) {
      this.crExists = true;
      this.crID = res.id;
    }

    return !this.crExists;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if(!params.patient_id) return;
      this.edit = true;
      this.patient_id  = params.patient_id;
      this.loading = true;
      this.patientService.getPatient(this.patient_id).then(patient=>{
        this.formData = Object.assign({},patient);
        this.patientRealCR = patient.crNumber;
        this.loading = false;
      })


    })
  }

}
