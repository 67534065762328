<div *ngIf="patients">
  <div *ngFor="let patient of patients" class="row no-gutters align-items-center g-0"
    style="border-bottom: 0.5px solid rgb(0, 0, 0, 0.04)" style="cursor: pointer;">
    <div class="col py-3">
      <div class="row no-gutters">
        <div class="col-auto"></div>
        <div class="col ml-2">
          <!--  style="display: flex;" <input type="checkbox" id="chkPatientId" style="width: 20px;height: 20px;" class="mr-2"> -->
          <div (click)="this.router.navigate(['patient/'+patient._id])" class="mb-1 name " placement="left"
            ngbTooltip="Open Profile">
            <h4>
              {{ patient.title }} {{ patient.firstName }} {{ patient.lastName }} <span
                *ngIf="patient.token && showToken" class="text-success" style="font-weight: 1000;">
                ({{patient.token.type}}-{{patient.token.number}}) </span>
            </h4>
            <span *ngIf="patient.token && showToken" class="badge badge-pill badge-info ml--1">Token:
              ({{patient.token.type}}-{{patient.token.number}})</span>
            <span class="badge badge-pill badge-primary ml-2">Gender: {{patient.gender}}</span>
            <span class="badge badge-pill badge-primary ml-2">Age:
              {{(patient.age)?patient.age:formatAge(patient.dob)}}</span>
            <span class="badge badge-pill badge-primary ml-2">Date of Birth: {{(patient.dob)}}</span>
            <span class="badge badge-pill badge-primary ml-2">CR Number: {{patient.crNumber}}</span>
            <span class="badge badge-pill badge-primary ml-2">Address: {{patient.line1}}, {{patient.line2}}.</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!mode" class="col-auto">
      <button *ngIf="patient.noEntry" (click)="this.router.navigate(['patient/'+patient._id])"
        class="ml-2 mr-0 btn btn-success btn-sm">Profile</button>
      <button (click)="create_bill_click(patient)" class="ml-2 mr-0 btn btn-info btn-sm">Create Bill</button>
      <button *ngIf="!patient.noEntry" (click)="opd_entry_click(patient)" class="ml-2 mr-0 btn btn-primary btn-sm">Mark
        OPD Visit</button>
      <button
        *ngIf="patient.token && showToken && ['admin','superadmin'].includes(this.authService.getTokens()[1]) && patient.token.type != 'E'"
        (click)="convertToEmergency(patient.token._id)" class="ml-2 mr-0 btn btn-danger btn-sm">Convert to
        Emergency</button>
      <!-- <button class="ml-2 mr-0 btn btn-danger btn-sm" (click)="confirmBox(patient)">Cancel Appointment</button> -->
      <button class="btn btn-sm btn-warning ml-2 mr-0" (click)="router.navigate(['/edit-patient/'+patient._id])"><i
          class="fa fa-pencil-alt"></i></button>
      <!-- <button  class="ml-2 mr-0 btn btn-primary btn-sm">New Test</button> -->
    </div>
    <div *ngIf="mode=='link'" class="col-auto">
      <button class="ml-2 mr-0 my-2 btn btn-success btn-lg" (click)="onLink(patient._id)">Link to Appointment</button>
    </div>
  </div>
</div>

<app-create-opd-entry *ngIf="selectedPatient && visible" [visible]="visible" (onClose)="onClose1()"
  (onSuccess)="onSuccess()" [patient]="selectedPatient">
</app-create-opd-entry>

<app-create-bill *ngIf="selectedPatient && createBillVisible" [visible]="createBillVisible"
  (onClose)="createBillVisible = false;" [patient]="selectedPatient">
</app-create-bill>

<app-modal [visible]="showModal" (onClose)="closeHandler()" title="" bodyColor="#EEE">
  <div *ngIf="order_status != 'Success' && order_status != 'Shipped'">
    <h2 class="text-danger">❌ Your transaction has been failed.</h2>
    <ul>
      <li class="py-2">Your Transaction No. is <b>{{tracking_id}}</b></li>
      <li class="py-2">Please try again.</li>
    </ul>
  </div>
  <div *ngIf="order_status == 'Success' || order_status == 'Shipped'">
    <h2 class="text-success">✔ Your transaction has been Successfully Processed.</h2>
    <ul>
      <li class="py-2">Your Transaction No. is <b>{{tracking_id}}</b></li>
    </ul>
  </div>
</app-modal>