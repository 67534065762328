
import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { PatientService } from 'src/app/patient.service';
import { AppointmentsService } from 'src/app/appointments.service';
@Component({
  selector: 'app-appointment-patient-link',
  templateUrl: './appointment-patient-link.component.html',
  styleUrls: ['./appointment-patient-link.component.css']
})
export class AppointmentPatientLinkComponent implements OnInit {
  
  @Input() visible:boolean;
  closeDisabled:boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Input() appointment;
  patient_id;
  search;
  linking = false;
  linked = false;
  error = false;
  patients = [];
  loading
  constructor(public patientService:PatientService, public appointmentService:AppointmentsService) { }

  ngOnInit(): void {
  }

  modalClose() {
    this.patient_id = "";
    this.linked = false;
    this.error = false;
    this.onClose.emit();
  }

  async searchHandler(e) {
    
    if(!e || e=="" ) return this.patients = [];
    this.patients = [];
    
    this.loading = true;
    this.patients = await this.patientService.searchPatient(e);
    this.loading = false;
  
  }

  success() {
    this.patient_id = "";
    this.linked = false;
    this.error = false;
    this.onSuccess.emit();
    this.onClose.emit();
  }

  async linkClickHandler(patient_id) {
    this.linking = true;
    this.closeDisabled =true;
    await this.appointmentService.linkAppointment(this.appointment._id,patient_id);
    this.success();
    this.linking = false;
    this.closeDisabled =false;
    this.modalClose();
  }

}
