<app-modal [visible]="visible" (onClose)="closeHandler()" [closeDisabled]="transacting" title="Create Patient Bill" width="70">
<div *ngIf="!transacting">
    <h6 class="heading-small font-weight-bold">Create {{(amount>0)?'Rs. '+amount+' ':""}}Transaction Record for {{patient.title}} {{patient.firstName}} {{patient.lastName}} </h6>
    <div class="row align-items-center mb-3 no-gutters">
        <div class="col">

            <mat-form-field class="w-100  pb-0" appearance="outline" style="margin-bottom: -1.34375em;">
                <mat-label>Items</mat-label>
                <input #newInput type="text"
                    name="selectedValue"
                    [(ngModel)]="selectedValue"
                    (keyup.enter)="add()"
                    placeholder="Pick one"
                    matInput

                    [formControl]="myControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option}}
                </mat-option>
                </mat-autocomplete>

            </mat-form-field>
        </div>
        <div class="col-auto pl-2">
            <button class="btn btn-success" (click)="add()">
                <i class="fa fa-plus"></i>
            </button>
        </div>
    </div>
    <div class="table-responsive">
        <table class="table table-sm">
            <thead class="thead thead-dark text-white">
                <th>
                    Sr. No.
                </th>
                <th>
                    Item{{(items.length>1)?"s":""}}
                </th>
                <th>
                    Amount
                </th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let item of items; let i = index">
                    <td style="width: 10%;">
                        {{i+1}}
                    </td>
                    <td style="width: 60%;">
                        {{item.name}} <br/>

                    </td>
                    <td style="width: 30%;">
                        <input type="number" min="0" [(ngModel)]="item.amount" (ngModelChange)="updateTotal()" (focus)="item.amount=undefined; updateTotal()" class="form-control form-control-alternative form-control-sm"/>
                    </td>
                    <td >
                        <span class="text-danger ml-1" style="cursor: pointer;" ><i class="fa fa-times" (click)="remove(i)"></i></span>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
    <div *ngIf="items.length < 1" class="d-flex w-100 align-items-center justify-content-center" style="height: 100px; background-color: #DEDEDE;">
        <h6>No Items!</h6>
    </div>
</div>

<div *ngIf="transacting" class="d-flex w-100 align-items-center justify-content-center" style="height: 200px; background-color: #DEDEDE;">
    <h6><i class="fas fa-spinner fa-spin"></i>&nbsp; Transacting</h6>
</div>

<hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;"/>

<div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div *ngIf="errorMessage" class="mb-3 text-danger w-100 text-right">{{errorMessage}}</div>
    <div class="row no-gutters justify-content-between">
        <div class="col">
            <h2 *ngIf="patient.poorFree" class="m-0 text-danger" style="text-transform: uppercase; font-weight: 1000;">Poor Free</h2>
            <h3 *ngIf="!patient.poorFree" class="m-0 text-success" style="text-transform: uppercase; font-weight: 1000;">Total: Rs. {{total}}</h3>
        </div>
        <div class="col-auto">
            <mat-radio-group [disabled]="transacting" [(ngModel)]="mode_of_payment" (ngModelChange)="errorMessage=undefined;" name="mode_of_payment" aria-label="Select an option" class="mr-3">
                <mat-radio-button value="cash">Cash</mat-radio-button>
                <mat-radio-button value="digital">Net Banking</mat-radio-button>
                <mat-radio-button value="other">Other</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-auto">
            <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
            <button [disabled]="transacting" class="d-block ml-auto btn btn-success btn-sm" (click)="transact()">Create Entry</button>
        </div>
    </div>
</div>

<div *ngIf="mode_of_payment == 'digital'">
  <form #form ngNoForm id="nonseamless" method="post" name="redirect"
    action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
    <input type="hidden" id="encRequest" name="encRequest" value="{{encRequestRes}}">
    <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
  </form>
</div>
</app-modal>


