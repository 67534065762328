import { Component, OnInit } from '@angular/core';
import { CashService } from 'src/app/cash.service';
import {startCase,upperCase} from 'lodash';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/dashboard.service';
@Component({
  selector: 'app-cash-register',
  templateUrl: './cash-register.component.html',
  styleUrls: ['./cash-register.component.css']
})
export class CashRegisterComponent implements OnInit {

  loading = false;
  transactions = [];
  cash_in_hand;
  cash_transactions;
  startCase = startCase;
  clear_till_visible = false;

  constructor(public cashService:CashService, public dashboardService:DashboardService, public router:Router) { }

  ngOnInit(): void {
    this.refreshTransactions();

    this.dashboardService.sharedCashInHand.subscribe(cash_in_hand=>{
      this.cash_in_hand = cash_in_hand;
    })
  }

  opdModalSuccess() {
    this.refreshTransactions();
  }

  async refreshTransactions() {
    this.cash_transactions = undefined;
    this.loading = true;
    this.transactions = await this.cashService.getRecentRegister();
    this.cash_transactions = 0;
    this.transactions = this.transactions.map(transaction=>{
      transaction["type"] = upperCase(transaction["type"]);

      if(!transaction.transcation_id) return transaction;
      let patient = transaction.transcation_id.patient_id
      transaction["name"] = startCase(transaction.transcation_id.name);
      if(!transaction["amount"]) transaction["amount"] = transaction.transcation_id.amount;

      // transaction["patient_name"] = patient.title + " " + patient.firstName + ' ' + patient.lastName;
      this.cash_transactions += ((transaction.type=="CR")?1:-1) * transaction.transcation_id.amount;

      return transaction;
    })

    if(!this.cash_transactions) this.cash_transactions = "Zero";
    this.loading = false;
  }

}
