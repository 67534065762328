import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppointmentsService } from 'src/app/appointments.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reschedule-appointment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrls: ['./reschedule-appointment.component.css'],
})
export class RescheduleAppointmentComponent implements OnInit {
  @Input() visible;
  @Input() appointments;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() isUpdateAppointment: boolean = false;
  errorMessage;
  rescheduleDate;
  transacting;
  errored;
  minDate: Date;
  bokkingType: string;
  error: String;
  isShowErrorMsg;
  remaingSlots: Number;
  isShowRemaining;
  weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  rescheduleStatus = "";

  constructor(public appointmentService: AppointmentsService) {
    this.minDate = new Date();
  }

  ngOnInit(): void {
    this.isShowErrorMsg = false;
    this.isShowRemaining = false;
  }

  successHandler() {
    this.onSuccess.emit();
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.onClose.emit();
  }

  async RescheduleDate() {
    if (!this.rescheduleDate) return (this.errorMessage = 'Please enter date.');

    if(this.isUpdateAppointment && !this.rescheduleStatus)
      return (this.errorMessage = "Please appoitment select status.");

    if (this.appointments.length == 0)
      return (this.errorMessage = 'Some error occured.');

    if (this.weekday[this.rescheduleDate.getDay()] == 'Saturday') {
      this.bokkingType = 'weekend';
    } else {
      this.bokkingType = 'weekday';
    }

    let check = await this.appointmentService.checkDate(
      this.rescheduleDate.getFullYear(),
      ('0' + (this.rescheduleDate.getMonth() + 1)).slice(-2),
      ('0' + this.rescheduleDate.getDate()).slice(-2),
      this.bokkingType
    );
    if (!check.check && !this.isUpdateAppointment) {
      this.isShowErrorMsg = true;
      this.error = 'Sorry, No slots available for selected date!';
      return;
    } else {
      this.isShowErrorMsg = false;
    }

    let datePipe = new DatePipe('en-US');
    var date = datePipe.transform(this.rescheduleDate, 'yyyy-MM-dd');
    this.transacting = true;
    var data = {
      date: date,
      status: this.isUpdateAppointment ? this.rescheduleStatus : 'pending', 
      appointments: this.appointments,
    };

    await this.appointmentService.rescheduleAppointments(data);

    this.transacting = false;
    this.successHandler();
  }

  async remainingSlots(selectedDate){
    if(this.isUpdateAppointment){
      this.isShowRemaining = false;
      return;
    }

    if (this.weekday[selectedDate.getDay()] == 'Saturday') {
      this.bokkingType = 'weekend';
    } else {
      this.bokkingType = 'weekday';
    }   

    let check = await this.appointmentService.checkDate(
      selectedDate.getFullYear(),
      ('0' + (selectedDate.getMonth() + 1)).slice(-2),
      ('0' + selectedDate.getDate()).slice(-2),
      this.bokkingType
    );

    this.remaingSlots = check.remainingAppSlots;
    this.isShowRemaining = true;
  }
}
