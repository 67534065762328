<div class="w-100" style="overflow-x:auto;">
  <!-- <div class="col-md-12">
    <div class="row no-gutters g-0 mb-1 align-items-center">
      <div class="col pb-1">
        <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control"
          placeholder="Search by name">
      </div>
      <div *ngIf="search" (click)="search = undefined" class="col-auto">
        <button class="btn btn-warning ml-2 mt--1">Cancel</button>
      </div>
    </div>
  </div> -->
  <div style="margin-bottom: 1%;">
    <button class="btn btn-sm btn-warning" [disabled]="recheduleAppointmentList.length==0"
      (click)="linkRescheduleAppointment()">Reschedule Appointment</button>
  </div>
  <table class="table" style="overflow-x: scroll;">
    <thead class="thead-dark">
      <tr>
        <th class="sticky-col first-col"></th>
        <th class="sticky-col second-col">Date</th>
        <th class="sticky-col third-col">App. Number</th>
        <th>Patient Name</th>
        <th>Gender</th>
        <th>Date of Birth</th>
        <th>Mobile Number</th>
        <th>Type</th>
        <th>Reason</th>
        <th>Transaction ID</th>
        <th *ngIf="!isUpdateAppointment">Actions</th>
        <th *ngIf="isUpdateAppointment">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let appointment of appointments">
        <td class="sticky-col first-col">
          <input type="checkbox" id="chk_{{appointment._id}}"
            (change)="selectAppointmentChange(appointment._id, $event)">
        </td>
        <td class="sticky-col second-col">{{appointment.date | date:'mediumDate'}}</td>
        <td class="sticky-col third-col">{{appointment.appointmentNumber}}</td>
        <td>{{appointment.title}} {{appointment.firstName}} {{appointment.lastName}}</td>
        <td>{{appointment.gender}}</td>
        <td>{{appointment.dob | date:'mediumDate'}}</td>
        <td>{{appointment.phoneNumber}}</td>
        <td>{{appointment.reason}}</td>
        <td>{{appointment.reason}}</td>
        <td>{{appointment.trackingId}}</td>
        <td *ngIf="!isUpdateAppointment">
          <button *ngIf="!appointment.patient && appointment.status != 'canceled' " class="btn btn-sm btn-primary"
            (click)="linkPatient(appointment)">Link Patient</button>
          <button
            *ngIf="appointment.patient && appointment.status != 'completed' && appointment.status != 'canceled' && ((appointment.date | date:'yyyy-MM-dd') == this.date)"
            (click)="openOpdEntry(appointment)" class="btn btn-sm btn-success">Mark Entry</button>
          <button *ngIf="appointment.status != 'canceled' && appointment.status != 'completed'"
            class="btn btn-sm btn-danger" (click)="confirmBox(appointment)">Cancel Appointment</button>
          <div *ngIf="appointment.status == 'completed'"
            style="background-color: yellow; padding:4px; border-radius: 2px;"> ✅ Added to OPD Queue </div>
          <div *ngIf="appointment.status == 'canceled'" class="text-warning">❌ Canceled </div>
        </td>
        <td *ngIf="isUpdateAppointment">
          {{appointment.status}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="appointments.length < 1"
  style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
  No Appointments
</div>

<app-appointment-patient-link [visible]="visible" (onClose)="visible=false" [appointment]="selectedAppointment"
  (onSuccess)="refreshAppointments()"></app-appointment-patient-link>

<app-create-opd-entry *ngIf="selectedPatient" [visible]="opd_entry_visible" (onClose)="onEntryClose()"
  (onSuccess)="refreshAppointments()" [patient]="selectedPatient" [appointment]="selectedAppointment">
</app-create-opd-entry>

<app-alert-confirm *ngIf="confirmAlert" [visible]="confirmAlert" (onClose)="confirmAlert = false;"
  (onSuccess)="refreshAppointments()" [patient]="selectedPatient" [appointment]="selectedAppointment">
</app-alert-confirm>

<app-reschedule-appointment *ngIf="reschedule" [visible]="reschedule" (onClose)="reschedule = false;"
  (onSuccess)="refreshAppointments()" [appointments]="recheduleAppointmentList" [isUpdateAppointment]="isUpdateAppointment"></app-reschedule-appointment>
