import { Component, OnInit, OnDestroy } from "@angular/core";
import { SettingsService } from 'src/app/settings.service';
import { TokenService } from "src/app/token.service";

@Component({
  selector: "app-virtual-lounge",
  templateUrl: "./virtual-lounge.component.html",
  styleUrls: ["./virtual-lounge.component.css"],
})
export class VirtualLoungeComponent implements OnInit, OnDestroy {
  token_loading = false;
  loading = false;
  next_tokens_loading = false;
  deffered_tokens_loading = false;
  interval;
  dateInterval;
  current_token;
  next_tokens = [];
  deffered_tokens = [];
  date = new Date();

  settings: any = {
    liveMsg: "",
  };

  constructor(
    public tokenService: TokenService,
    public settingService: SettingsService
  ) {}

  ngOnInit(): void {
    this.getCurrentToken();
    this.getNextTokens();
    this.getDefferedTokens();
    this.getSettings();
    this.interval = setInterval(() => {
      this.silentRefresh();
    }, 1000);

    this.dateInterval = setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.interval) clearInterval(this.interval);
    if (this.dateInterval) clearInterval(this.dateInterval);
  }

  async silentRefresh() {
    [this.current_token, this.next_tokens, this.deffered_tokens] =
      await Promise.all([
        this.tokenService.getActiveToken(),
        this.tokenService.getNextTokens(8),
        this.tokenService.getDefferedToken(8),
      ]);
  }

  async getCurrentToken() {
    this.token_loading = true;
    this.current_token = await this.tokenService.getActiveToken();
    this.token_loading = false;
  }

  async getNextTokens() {
    this.next_tokens_loading = true;
    this.next_tokens = await this.tokenService.getNextTokens(8);
    this.next_tokens_loading = false;
  }

  async getDefferedTokens() {
    this.deffered_tokens_loading = true;
    this.deffered_tokens = await this.tokenService.getDefferedToken(8);
    this.deffered_tokens_loading = false;
  }

  async getSettings() {
    this.loading = true;

    try {
      this.settings = await this.settingService.getLiveMessage();
    } catch (e) {
      alert("Some Error Occured!");
    }

    this.loading = false;
  }
}
