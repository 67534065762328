<app-modal [visible]="visible" (onClose)="closeHandler()" title="Important Notice" style="width: 60vw;min-width: 450px;">
  <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div class="mb-3 w-100" [innerHTML]="notificationMessage"></div>
    <div><input type="checkbox" [(ngModel)]="chkAcceptTerms"> I Agree</div>

    <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;" />
    <div class="row no-gutters justify-content-between">
      <div class="col-auto">
      </div>
      <div class="col-auto">
        <button [disabled]="!chkAcceptTerms" class="d-block ml-auto btn btn-success btn-md" style="margin-top: 10%;"
          (click)="successHandler()">Make Payment</button>
      </div>
    </div>
  </div>
</app-modal>
