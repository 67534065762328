import { Injectable } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import {baseUrl} from './config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public jwtHelper: JwtHelperService, private httpClient: HttpClient) { }

  public isAuthenticated() : boolean {
    const [token,user_type] = this.getTokens();
    return (token && user_type)?true:false;
  }

  public isSuperAuthenticated(): boolean {
    const [token,user_type] = this.getTokens();
    if(token && user_type) return (user_type === "superadmin")
    else return false;
  }

  public doLogin(data) {
    return this.httpClient.post(baseUrl + "users/login",data).toPromise().then((res:any)=>{
      localStorage.setItem('token',res.token);
      localStorage.setItem('user_type',res.user.type)
      localStorage.setItem('user',res.user.username);
      return res;
    });
  }

  public doLogout() {
    let headers = {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}};
    let promise = this.httpClient.post(baseUrl + "users/me/logoutall",{},headers).toPromise();
    localStorage.removeItem('token');
    localStorage.removeItem('user_type')
    localStorage.removeItem('user')
    return promise;
  }

  public getTokens(): [String,String,String] {
    const token = localStorage.getItem('token');
    const user_type = localStorage.getItem('user_type');
    const user = localStorage.getItem('user')
    try{
      if(this.jwtHelper.isTokenExpired(token)) {

        localStorage.removeItem('token');
        localStorage.removeItem('user_type');
        localStorage.removeItem('user')
        return [undefined,undefined,undefined];
      }
    }
    catch(e) {
      localStorage.removeItem('token');
      localStorage.removeItem('user_type');
      return [undefined,undefined,undefined];
    }
    return [token,user_type,user]
  }

}
