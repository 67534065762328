<app-default-header>

</app-default-header>
<div class="container-fluid mt-4">

    <div class="card border-0" style="background-color: white; border-radius: 10px;">
        <div class="card-header">
            <h2 class="m-0">Current Token</h2>
        </div>
        <div *ngIf="!loading" class="row no-gutters align-items-center">
            <div class="col">
                <div *ngIf="current_token" class="token-wrapper py-2">
                    <h5 class="m-0 heading-small text-center text-primary">Token Number</h5>
                    <div class="token-number text-warning">
                        {{current_token.type}}-{{current_token.number}}
                    </div>
                    <div class="patient-name">
                        {{current_token.patient.title}} {{current_token.patient.firstName}} {{current_token.patient.middleName && current_token.patient.middleName+" "}}{{current_token.patient.lastName}}
                    </div>
                </div>
                <div *ngIf="!current_token" style="height: 200px; flex-direction: column;"  class="d-flex justify-content-center align-items-center">
                    <h4 class="text-center">No Active Token!</h4>
                    <h5 class="text-center">Please click start OPD to start calling tokens.</h5>
                </div>
            </div>
            <div *ngIf="!current_token" class="col-auto px-md-5 px-2">
                <button *ngIf="this.next_tokens && this.next_tokens.length > 0" [disabled]="next_token_loading || transacting" class="btn btn-success" (click)="startOPD()">Start OPD</button>
            </div>
            <div *ngIf="current_token" class="col-auto pr-md-3">
                <button [disabled]="transacting" class="btn btn-success" (click)="completeVisit()">Complete Visit</button>
                <!-- <button [disabled]="transacting" class="btn btn-warning " (click)="defferOpen = true;">Defer Patient</button> -->
                <button [disabled]="transacting || current_token.type=='E'" class="btn btn-pending" (click)="pendingOPD()">Pending</button>
                <button [disabled]="transacting" class="btn btn-danger" (click)="stopOPD()">Stop OPD</button>
            </div>
        </div>

        <div *ngIf="loading" style="height: 150px;" class="d-flex align-items-center justify-content-center">
            <h3><i class="fa fa-spinner fa-spin"></i> Loading...</h3>
        </div>
    </div>

    <div class="row no-gutters pt-3">
        <div class="col-xl-8 ">
            <app-patient-profile *ngIf="this.patient_id" [patient_id]="patient_id" [noHeader]="true"></app-patient-profile>
        </div>
        <div class="col-xl-4">
            <div class="card border-0 ml-xl-3" style="background-color: white; border-radius: 10px;">
                <div class="card-header">
                    <h3 class="m-0">Next Tokens</h3>
                </div>
                <div class="card-body">
                    <div *ngIf="!next_token_loading">
                        <div *ngFor="let token of next_tokens" class="row no-gutters mb-2" style="border:2px dashed rgba(0, 0, 0, 0.178); cursor: pointer;" (click)="router.navigate(['/patient/'+token.patient._id])">
                            <div class="col-auto">
                                    <div class="bg-danger m-2  py-3 px-2 text-white d-flex align-items-center justify-content-center" style="min-width: 60px;">
                                        <p class="m-0" style="font-weight: 1000;">
                                            {{token.type}}-{{token.number}}
                                        </p>
                                    </div>
                            </div>
                            <div class="col py-1">
                                <p class="m-0" style="font-weight: 1000;">{{token.patient.title}} {{token.patient.firstName}} {{token.patient.middleName && token.patient.middleName+" "}}{{token.patient.lastName}}</p>
                                <p class="m-0 mt--1 text-muted" style="font-size:0.8em; font-weight: 700;">Male - {{token.patient.dob | date:'mediumDate'}}</p>
                            </div>
                        </div>

                        <div *ngIf="next_tokens && next_tokens.length < 1" style="height: 200px;" class="d-flex justify-content-center align-items-center">
                            <h4>No Next Tokens!</h4>
                        </div>
                    </div>

                    <div *ngIf="next_token_loading" style="height: 200px;" class="d-flex justify-content-center align-items-center">
                        <h4>
                            <i class="fa fa-spinner fa-spin"></i> Loading..
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-deffer-token *ngIf="current_token" [visible]="defferOpen" (onClose)="defferOpen=false;"  (onSuccess)="refreshCurrentToken(); refreshNextTokens();"></app-deffer-token>
