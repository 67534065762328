<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8" style="background:linear-gradient(40deg, #B060FF 30%, #FD6EC8 100%) !important;">
    <div class="container-fluid">
      <div class="header-body">
        <!-- Card stats -->
        <div class="row">
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Amount In <span class="text-nowrap">Cash Register</span></h5>
                    <span *ngIf="!dashLoading" class="h2 font-weight-bold mb-0">Rs. {{cash_in_hand}}</span>
                    <span *ngIf="dashLoading || cash_in_hand === undefined" class="h2 font-weight-bold mb-0">Loading...</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i class="fas fa-chart-bar"></i>
                    </div>
                  </div>
                </div>
                <!-- <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                  <span class="text-nowrap">Cleared at</span>
                  <span class="text-nowrap text-success">&nbsp;4:45PM 31/03/2021 </span>
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Total Transactions</h5>
                    <span *ngIf="!dashLoading" class="h2 font-weight-bold mb-0">Rs. {{total_transactions}}</span>
                    <span *ngIf="dashLoading || total_transactions === undefined" class="h2 font-weight-bold mb-0">Loading...</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                      <i class="fas fa-chart-pie"></i>
                    </div>
                  </div>
                </div>
                <!-- <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> 3.48%</span>
                  <span class="text-nowrap">Since last week</span>
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Digital Transcations</h5>
                    <span *ngIf="!dashLoading" class="h2 font-weight-bold mb-0">Rs. {{card_transactions}}</span>
                    <span *ngIf="dashLoading || card_transactions === undefined" class="h2 font-weight-bold mb-0">Loading...</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                      <i class="fas fa-users"></i>
                    </div>
                  </div>
                </div>
                <!-- <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
                  <span class="text-nowrap">Since yesterday</span>
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Cash Transactions</h5>
                    <span *ngIf="!dashLoading" class="h2 font-weight-bold mb-0">Rs. {{cash_transactions}}</span>
                    <span *ngIf="dashLoading || cash_transactions === undefined" class="h2 font-weight-bold mb-0">Loading...</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                      <i class="fas fa-percent"></i>
                    </div>
                  </div>
                </div>
                <!-- <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> 12%</span>
                  <span class="text-nowrap">Since last month</span>
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="container-fluid mt--7">
    <div class="row">
      <div class="col">
        <div class="card shadow border-0">
          <div class="card-header">
              <div class="row no-gutters align-items-center justify-content-between">
                <div class="col-auto">
                    <h2>Today's Patient List</h2>
                </div>
                <div class="col-auto">
                    <button class="btn btn-success mx-1 mx-sm-0" (click)="this.router.navigate(['/create-patient'])">Create Patient</button>
                </div>
              </div>
          </div>

          <div class="card-body">
            <div class="row no-gutters g-0 mb-1 align-items-center">
                <div class="col pb-1">
                    <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control" placeholder="Search by name or CR No.">
                </div>
                <div *ngIf="search" (click)="search = undefined" class="col-auto">
                    <button  class="btn btn-warning ml-2 mt--1">Cancel</button>
                </div>
            </div>

            <div *ngIf="loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                <span style="color:#333">Loading...</span>
            </div>

            <div *ngIf="paitents && paitents.length == 0 && !loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
              <h3 *ngIf="search" style="color:#333">No Search Items</h3>
              <h3 *ngIf="!search">No OPD Visits or Patients created today. Please create a new paitent / mark an opd visit.</h3>
            </div>

            <app-patient-list [patients]="paitents" (patientUpdated)="opdModalSuccess()"></app-patient-list>
          </div>
        </div>
      </div>
    </div>
  </div>

