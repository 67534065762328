import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {startCase} from 'lodash';
import { UsersComponent } from 'src/app/pages/users/users.component';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {

  @Input() visible:boolean;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  loading:boolean = false;

  fields = {
    confirmPassword:undefined,
    password:undefined,
  }
  errors = {};
  main_error = {
    visible:false,
    message:""
  }
  errored;
  requiredFields = ["password","confirmPassword"];

  constructor(public userService:UserService) { }

   validateForm() {

    let flag = true;
    for(let field of this.requiredFields) {
      if(!this.validateField(field)) flag = false;
    }
    return flag;
    
  }

  customValidations(selector):boolean {
    
    if(this.fields['password'] && this.fields['confirmPassword'] && this.fields["password"]!="" && this.fields["confirmPassword"]!="") {
      if(this.fields['password'] != this.fields['confirmPassword']) {
        setTimeout(()=>{this.errors['confirmPassword'] = "Password Don't match!";},0);
        if(selector =="confirmPassword") return false;
      }
      else setTimeout(()=>{this.errors['confirmPassword'] = undefined;},0);
    }

    return true;
  }

  validateField(selector) {
    if(this.requiredFields.includes(selector) && (!this.fields[selector] || this.fields[selector] == "")){
      this.errors[selector] =startCase(selector) + " is Required!";
      return false;  
    }
    if(!this.customValidations(selector)){
      this.errors[selector] =`Please enter a valid ${startCase(selector)}`;
      return false;
    } 
    this.main_error.visible = false;
    this.errors[selector] = undefined
    this.main_error.message = ""
    return true;
  }

  async changePassword() {
    if(!this.validateForm()) {
      this.main_error.visible = true;
      this.main_error.message = "All Fields marked with asterisk(*) are required!<br/>Password and Confirm Password are required to match!";
      this.loading = false
      return;
    }

    this.main_error.visible = false;
    this.main_error.message = "";
    this.loading = true;
    let res = await this.userService.changeSelfPassword(this.fields.password);
    console.log(res);
    this.loading = false;
    alert('Password Changed!');
    this.modalClose();
  }


  modalClose() {
    this.visible = false;
    this.onClose.emit();
  }

  ngOnInit(): void {
  }

}
