<div class="my-3" style="background-color: rgb(230, 230, 230);">
  <div class="row">
    <div class="col-md-12">
      <div class="card">

        <div id="content">
          <ul class="timeline">
            <li *ngFor="let entry of entries" class="event" [attr.data-date]="entry.createdAt | date:'fullDate'">
              <div class="row no-gutters">
                <div class="col">
                  <h3>{{entry.createdAt | date:'mediumTime'}} - OPD Visit</h3>
                  <p>{{entry.purpose}} <span *ngIf="entry.note" class="note"><br>Note: {{entry.note}}</span> </p>
                </div>
                <div class="col-auto">
                  <button class="btn btn-sm btn-primary" (click)="createNote(entry)">
                    <i class="fa fa-file-medical"></i> Notes
                  </button>
                  <button class="btn btn-sm btn-primary" (click)="printOPD(entry)">
                    <i class="fa fa-print"></i> Print
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<app-create-note *ngIf="createNotes" [visible]="createNotes" (onClose)="createNotes = false;"
  (onSuccess)="refreshVisits()" [entries]="selectedEntry"></app-create-note>

<app-modal [visible]="visible" (onClose)="closeHandler()" title="" width="70">
  <div id="print-section">
    <div style="text-align: center; font-size: 26px; font-weight: 800;">REGIONAL SPINAL INJURIES CENTRE</div>
    <div style="text-align: center; font-size: 11px;">(Joint Venture of Govt. of Pb. & Govt. of India under Deptt. of
      Social Security Pb.)</div>
    <div style="text-align: center; font-size: 14px;">SECTOR 70, MOHALI</div>
    <div style="text-align: center; font-size: 12px; font-weight: 800;">Tel.: 0172:2218004 Email: mohalirsic@gmail.com
    </div>
    <!-- <div style="text-align: center; font-size: 12px; font-weight: 800;">Drug Licence No. :</div> -->
    <!-- <div style="text-align: right; font-size: 12px; font-weight: 800;"><b>Date :</b>&nbsp;&nbsp;Date()</div> -->

    <div class="table-responsive" style="margin-top: 3%;">
      <table cellpadding="0" cellspacing="0" class="table" style="max-width: 100%;">
        <tbody>
          <tr>
            <td>
              <strong>Patient Name</strong> : {{patient?.title}} {{patient?.firstName}} {{patient?.lastName}}<br>
              <strong>Patient DOB</strong> : {{patient?.dob}}<br>
              <strong>Contact Number</strong> : {{patient?.phoneNumber}}<br>
            </td>
            <td style="text-align: right;">
              <strong>Dated</strong> : {{selectedEntry?.date | date: 'dd/MM/yyyy'}}<br />
              <strong>CR No.</strong> : {{patient?.crNumber}}
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <strong>Address</strong> : {{patient?.line1}} {{patient?.line2}}
            </td>
          </tr>
        </tbody>
      </table>
     <br>
      <div style="text-align: center; font-size: 18px; font-weight: 800;">OPD - Patient Report</div><br>
      <table cellpadding="0" cellspacing="0" class="table">
        <tbody>
          <tr>
            <td style="width: 100px;"><b>Date</b></td>
            <td><b>Treatment and Investigation Ordered</b></td>
          </tr>
          <tr>
            <td>{{selectedEntry?.date | date: 'dd/MM/yyyy'}}</td>
            <td>
              <div style="word-wrap: break-word; width: 900px; white-space: initial;">
                {{selectedEntry?.note}}
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right;height: 100px;">
              Authorised Signatory<br>
              <!-- {{'Doctor Name'}} -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;" />

  <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div class="row no-gutters justify-content-between">
      <div class="col-auto">
      </div>
      <div class="col-auto">
        <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
        <button class="d-block ml-auto btn btn-success btn-sm" (click)="printDoc()">Print</button>
      </div>
    </div>
  </div>

</app-modal>
