import {
  Component,
  OnInit,
  AfterViewChecked,
  AfterViewInit,
  AfterContentInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/auth.service";

declare interface RouteInfo {
  path: string;
  title: string;
  icon?: string;
  class?: string;
  authType?: string[];
  subPaths?: RouteInfo[];
  opened?: boolean;
  _id?: string;
}
export const ROUTES: RouteInfo[] = [
  // { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 icon-color', class: '', authType: ["admin","superadmin"]},
  {
    path: "/reception",
    title: "Reception",
    icon: "ni-archive-2 icon-color",
    class: "",
    authType: ["receptionist", "admin"],
    subPaths: [
      { path: "/reception", title: "Today's Patients" },
      { path: "/reception-appointments", title: "Today's Appointments" },
      { path: "/cash-register", title: "Cash Register" },
    ],
  },
  {
    path: "/patients",
    title: "Patients",
    icon: "ni-badge icon-color",
    class: "",
    authType: ["admin", "superadmin", "receptionist", "doctor"],
    subPaths: [
      { path: "/patients", title: "All Patients" },
      { path: "/opd-patients", title: "OPD Patients" },
      { path: "/admitted-patients", title: "IPD Patients" },
      { path: "/create-patient", title: "Create Patient" },
    ],
  },
  {
    path: "/appointment-calendar",
    title: "Scheduling",
    icon: "ni-calendar-grid-58 icon-color",
    class: "",
    authType: ["admin", "superadmin", "receptionist", "doctor"],
    subPaths: [
      { path: "/appointment-calendar", title: "Appointment Calendar" },
      { path: "/all-appointments", title: "Appointment Search" },
      { path: "/book-appointment", title: "Create Appointment" },
      { path: "/edit-appointment", title: "Edit Appointment" },
    ],
  },
  {
    path: "/all-tokens",
    title: "OPD",
    icon: "fas fa-procedures icon-color",
    class: "",
    authType: ["admin", "superadmin", "doctor"],
    subPaths: [
      { path: "/all-tokens", title: "All Tokens" },
      { path: "/live-opd", title: "Live OPD" },
      //{path:'virtual-lounge', title:'Virtual Lounge'}
    ],
  },
  {
    path: "/transactions",
    title: "Billing",
    icon: "ni-bullet-list-67  icon-color",
    class: "",
    authType: ["admin", "superadmin"],
    subPaths: [
      { path: "/invoices", title: "Invoices" },
      { path: "/transactions", title: "All Transactions" },
      { path: "/billing-cash-register", title: "Cash Register" },
    ],
  },
  // { path: '/labs',
  //   title: 'Labs',
  //   icon:'ni-tv-2 text-success',
  //   class: '',
  //   authType: ["admin","superadmin"] ,
  //   subPaths:[
  //     {path:'/lab_pending_request', title:"Pending Requests",},
  //     {path:'/lab_completed_request', title:"Completed",},
  //     {path:'/new_lab_request', title:"New Request",},
  //   ]
  // },
  {
    path: "/users",
    title: "Administration",
    icon: "ni-settings icon-color",
    class: "",
    authType: ["admin", "superadmin"],
    subPaths: [
      { path: "/users", title: "Staff Accounts" },
      { path: "/add-account", title: "Add New Account" },
      // {path:'/tokens', title:"Tokens",},
      { path: "/settings", title: "Settings" },
      //{ path: "/bank-response", title: "Bank Response" },
      { path: "/new-bank-response", title: "Bank Response" },
      { path: "/update-appointments", title: "Update Appointments" },
    ],
  },

  // { path: '/maps', title: 'Maps',  icon:' text-orange', class: '', authType: ["admin","superadmin"] },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, AfterContentInit {
  public menuItems: any[];
  public isCollapsed = true;
  public changePasswordVisible = false;

  constructor(
    private router: Router,
    public auth: AuthService,
    public route: ActivatedRoute
  ) {}

  supportClick() {
    // alert(
    //   "Incase of any malfunction or error , kindly contact Healthrun at +91-917814851231 or use the <a href='#'></a>"
    // );

    if (
      confirm(
        "Incase of any malfunction or error, Kindly contact Healthrun at +91-917814851231\n\nDo you want to use support form?"
      )
    ) {
      window.open(
        "https://mobehealth.com/#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjI4MDUiLCJ0b2dnbGUiOmZhbHNlfQ%3D%3D",
        "_blank"
      );
    }
  }

  ngOnInit() {
    this.menuItems = ROUTES.map((menuItem) => {
      return { ...menuItem, opened: false };
    });

    this.router.events.subscribe(this.routerEventHandler.bind(this));
  }

  ngAfterContentInit() {
    this.expandAsPerUrl();
  }

  routerEventHandler(event) {
    window.scrollTo(0, 0);
    this.expandAsPerUrl();
    // this.isCollapsed = true;
  }

  expandAsPerUrl() {
    console.log(this.menuItems);
    for (let menuItem of this.menuItems) {
      let flag = false;
      if (this.router.url.includes(menuItem.path)) flag = true;

      for (let subItem of menuItem.subPaths) {
        if (this.router.url.includes(subItem.path)) flag = true;
      }

      // console.log(menuItem.title +": " + flag);
      menuItem.opened = flag;
    }
  }

  expand() {}

  doLogout() {
    this.auth.doLogout().then((res) => {
      this.router.navigate(["../login"]);
      console.log("Log Out Successful!");
    });
    console.log(this.router);
    this.router.navigate(["../login"]);
  }
}
