<app-modal [visible]="visible" (onClose)="modalClose()" [closeDisabled]="closeDisabled" title="OPD Visit" width="50">
  <div *ngIf="!transacting && !transacted" style="margin: -1.5rem;  ">
    <div *ngIf="patient">
      <div class="my-3 px-3">
        <div class="table-responsive">
          <table class="table">
            <thead class="thead-dark">
              <th colspan="4" scope="col">
                OPD Visit Details
              </th>
            </thead>
            <tbody class="list">
              <tr>
                <th scope="row">
                  Name:
                </th>
                <td>
                  {{patient.title}} {{patient.firstName}} {{patient.lastName}}
                </td>
                <th scope="row">
                  CR Number:
                </th>
                <td>
                  {{patient.crNumber}}
                </td>
              </tr>
              <tr *ngIf="appointment">
                <th scope="row">
                  Appointment Number:
                </th>
                <td colspan="3">
                  {{appointment.appointmentNumber}}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  Contact Number:
                </th>
                <td>
                  {{patient.phoneNumber}}
                </td>
                <th scope="row">
                  Date Of Birth:
                </th>
                <td>
                  {{patient.dob | date:'dd/MM/yyyy'}}
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <label class="form-control-label">Mode Of Payment: </label>
                  <select [(ngModel)]="mode_of_payment" class="form-control form-control-alternative">
                    <option value="">Please select Mode of Payment</option>
                    <option value="cash">Cash</option>
                    <option value="digital">Other</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <label class="form-control-label">Purpose:</label>
                  <textarea type="text" class="form-control form-control-alternative" [(ngModel)]="purpose"
                    name="purpose">
                                    </textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <p class="mb-1">Name: {{patient.title}} {{patient.firstName}} {{patient.lastName}}</p>
                <p *ngIf="appointment" class="mb-1">Appointment Number: {{appointment.appointmentNumber}}</p>
                <p class="mb-1">CR Number: {{patient.crNumber}}</p>
                <p>Date Of Birth: {{patient.dob | date:'fullDate'}}</p> -->
      </div>
      <!-- <h6 class="heading-small text-muted mb-2">Entry information</h6> -->
    </div>
  </div>
  <div *ngIf="transacting">
    Transacting...
  </div>
  <div *ngIf="transacted">
    OPD Entry Marked for {{patient.title}} {{patient.firstName}} {{patient.lastName}}.<br />
    <h4>Token {{entry.token_id.type}} - {{entry.token_id.number}} Generated!</h4>
  </div>

  <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;" />

  <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div class="row no-gutters justify-content-between">
      <div class="col">
        <h2 *ngIf="patient.poorFree" class="m-0 text-danger" style="text-transform: uppercase; font-weight: 1000;">Poor
          Free</h2>
      </div>
      <div class="col-auto">
        <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
        <p *ngIf="mode_of_payment == 'cash' && !transacting && !transacted && !patient.poorFree"
          class="mb-3 text-warning" style="font-size: 0.9em; font-weight: 500;">Please take Rs. 50 cash and return
          change before marking entry</p>
        <button *ngIf="!transacted && !appointment" [disabled]="transacting"
          class="d-block ml-auto btn btn-primary btn-sm" (click)="markEntry()">Mark General Entry</button>
        <button *ngIf="!transacted && appointment" [disabled]="transacting"
          class="d-block ml-auto btn btn-primary btn-sm" (click)="markEntry()">Mark Appointment Entry</button>
        <button *ngIf="transacted" [disabled]="transacting" class="d-block ml-auto btn btn-success btn-sm"
          (click)="print()">Print Receipt</button>
      </div>
    </div>
  </div>
</app-modal>