import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { AuthService } from "./auth.service";
import { UserService } from "./user.service";
import { ReceptionComponent } from "./pages/reception/reception.component";
import { PatientService } from "./patient.service";
import { CreatePatientComponent } from "./pages/create-patient/create-patient.component";
import { CreateOpdEntryComponent } from "./components/create-opd-entry/create-opd-entry.component";
import { TransactionsComponent } from "./pages/transactions/transactions.component";
import { TransactionsService } from "./transactions.service";
import { CashService } from "./cash.service";
import { PatientProfileComponent } from "./pages/patient-profile/patient-profile.component";
import { PatientListComponent } from "./components/patient-list/patient-list.component";
import { PatientListingComponent } from "./pages/patient-listing/patient-listing.component";
import { AddAccountComponent } from "./pages/add-account/add-account.component";
import { OpdPatientsComponent } from "./pages/opd-patients/opd-patients.component";
import { AddmitedPatientsComponent } from "./pages/addmited-patients/addmited-patients.component";
import { CashRegisterComponent } from "./pages/cash-register/cash-register.component";
import { ClearTillComponent } from "./components/clear-till/clear-till.component";
import { BillingCashRegisterComponent } from "./pages/billing-cash-register/billing-cash-register.component";
import { HistoryComponent } from "./pages/patient-profile/history/history.component";
import { ImagingComponent } from "./pages/patient-profile/imaging/imaging.component";
import { VisitsComponent } from "./pages/patient-profile/visits/visits.component";
import { DefaultHeaderComponent } from "./components/default-header/default-header.component";
import { BookAppointmentComponent } from "./pages/book-appointment/book-appointment.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatNativeDateModule,
  MatOptionModule,
  MatCommonModule,
} from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import {
  MatAutocompleteModule,
  MatAutocomplete,
} from "@angular/material/autocomplete";
import { AllAppointmentsComponent } from "./pages/all-appointments/all-appointments.component";
import { AppointmentPatientLinkComponent } from "./components/appointment-patient-link/appointment-patient-link.component";
import { TodayAppointmentsComponent } from "./pages/today-appointments/today-appointments.component";
import { ReceptionAppointmentsComponent } from "./pages/reception-appointments/reception-appointments.component";
import { TokensComponent } from "./pages/tokens/tokens.component";
import { UsersComponent } from "./pages/users/users.component";
import { ApprovalsService } from "./approvals.service";
import { DashboardService } from "./dashboard.service";
import { RequestRefundComponent } from "./components/request-refund/request-refund.component";
import { AppointmentsComponent } from "./pages/patient-profile/appointments/appointments.component";
import { TransactionsComponent as PatientTransactions } from "./pages/patient-profile/transactions/transactions.component";
import { CreateBillComponent } from "./components/create-bill/create-bill.component";
import { MatRadioModule } from "@angular/material/radio";
import { ViewTransactionItemModalComponent } from "./components/view-transaction-item-modal/view-transaction-item-modal.component";
import { AppointmentSettingsComponent } from "./pages/appointment-settings/appointment-settings.component";
import { SettingsService } from "./settings.service";
import { SettingsComponent } from "./pages/settings/settings.component";
import { AppointmentCalendarComponent } from "./pages/appointment-calendar/appointment-calendar.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AppointmentsListComponent } from "./components/appointments-list/appointments-list.component";
import { TokenService } from "./token.service";
import { AllTokensComponent } from "./pages/all-tokens/all-tokens.component";
import { LiveOpdComponent } from "./pages/live-opd/live-opd.component";
import { DefferTokenComponent } from "./components/deffer-token/deffer-token.component";
import { VirtualLoungeComponent } from "./pages/virtual-lounge/virtual-lounge.component";
import { CreateNoteComponent } from "./components/create-note/create-note.component";
import { AlertConfirmComponent } from "./components/alert-confirm/alert-confirm.component";
import { RescheduleAppointmentComponent } from "./components/reschedule-appointment/reschedule-appointment.component";
import { DemoAppointmentComponent } from "./pages/demo-appointment/demo-appointment.component";
import { CallInfoDialogComponents } from "../app/components/dialog/callinfo-dialog.component";
import { BankResponseComponent } from "./pages/bank-response/bank-response.component";
import { NewBankResponseComponent } from "./pages/new-bank-response/new-bank-response.component";
import { ConfirmNotificationComponent } from "./components/confirm-notification/confirm-notification.component";
import { HdfcTransactionsService } from "./hdfctransactions.service";
import { ImagingConfirmComponent } from "./components/imaging-confirm/imaging-confirm.component";
import { EditAppointmentComponent } from './pages/edit-appointment/edit-appointment.component';
import { UpdateAppointmentComponent } from './pages/update-appointment/update-appointment.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatOptionModule,
    MatCommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [
    AppComponent,
    UsersComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ReceptionComponent,
    CreatePatientComponent,
    CreateOpdEntryComponent,
    TransactionsComponent,
    PatientListComponent,
    PatientListingComponent,
    AddAccountComponent,
    OpdPatientsComponent,
    AddmitedPatientsComponent,
    CashRegisterComponent,
    ClearTillComponent,
    BillingCashRegisterComponent,
    HistoryComponent,
    VisitsComponent,
    ImagingComponent,
    DefaultHeaderComponent,
    BookAppointmentComponent,
    AllAppointmentsComponent,
    AppointmentPatientLinkComponent,
    TodayAppointmentsComponent,
    ReceptionAppointmentsComponent,
    TokensComponent,
    RequestRefundComponent,
    AppointmentsComponent,
    PatientTransactions,
    CreateBillComponent,
    ViewTransactionItemModalComponent,
    AppointmentSettingsComponent,
    SettingsComponent,
    AppointmentCalendarComponent,
    AppointmentsListComponent,
    AllTokensComponent,
    LiveOpdComponent,
    PatientProfileComponent,
    DefferTokenComponent,
    VirtualLoungeComponent,
    CreateNoteComponent,
    AlertConfirmComponent,
    RescheduleAppointmentComponent,
    DemoAppointmentComponent,
    CallInfoDialogComponents,
    BankResponseComponent,
    NewBankResponseComponent,
    ConfirmNotificationComponent,
    ImagingConfirmComponent,
    EditAppointmentComponent,
    UpdateAppointmentComponent,
  ],
  providers: [
    AuthService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    UserService,
    PatientService,
    TransactionsService,
    CashService,
    ApprovalsService,
    DashboardService,
    SettingsService,
    TokenService,
    HdfcTransactionsService,
    //CallService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
