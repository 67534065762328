import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {baseUrl} from './config';

@Injectable({
  providedIn: 'root'
})
export class CashService {
  
  constructor(private httpClient: HttpClient) { }
  
  getHeaders() {
    return {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}};
  }

  async getRegister() {
    let res:any = await this.httpClient.get(baseUrl + "cash_register",this.getHeaders()).toPromise()
    return res.register;
  }

  async getRecentRegister() {
    let res:any = await this.httpClient.get(baseUrl + "cash_register/recent",this.getHeaders()).toPromise()
    return res.transactions;
  }

  async clearRegister(amount) {
    let res:any = await this.httpClient.post(baseUrl + "cash_register/clear",{amount},{...this.getHeaders(),responseType:'text'}).toPromise()
    return res;
  }

  async getByDate(date?:Number,month?:Number,year?:Number) {
    let res:any = await this.httpClient.get(baseUrl + `cash_register/byDate?date=${date}&month=${month}&year=${year}`,this.getHeaders()).toPromise()
    return res.transactions;
  }

}
  
