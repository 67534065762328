import { Component, OnInit } from '@angular/core';
import { CashService } from 'src/app/cash.service';
import {startCase,upperCase} from 'lodash';
import { DashboardService } from 'src/app/dashboard.service';
import { DatePipe } from '@angular/common';
import Papa from 'papaparse'
@Component({
  selector: 'app-billing-cash-register',
  templateUrl: './billing-cash-register.component.html',
  styleUrls: ['./billing-cash-register.component.css']
})
export class BillingCashRegisterComponent implements OnInit {

  date = new Date();
  transactions = [];
  loading  = false;
  edit = false;
  todays_date = new Date();
  cash_in_hand;
  cash_transactions;
  exporting = false;
  constructor(public cashService:CashService, public dashboardService:DashboardService) {

  }

  prev() {
    this.date = new Date(this.date.setDate(this.date.getDate() - 1)),
    this.refreshTransactions();
  }

  next() {
    this.date = new Date(this.date.setDate(this.date.getDate() + 1)),
    this.refreshTransactions();
  }
  
  setDate = function(date) {
    this.date = new Date(date),
    this.refreshTransactions(),
    this.edit = false;
  }

  ngOnInit(): void {
    this.refreshTransactions();
    this.dashboardService.sharedCashInHand.subscribe(cash_in_hand=>{
      this.cash_in_hand = cash_in_hand;
      console.log(cash_in_hand);
    })
  }

  async refreshCashInHand() {
    this.cash_in_hand = (await this.cashService.getRegister()).cash_in_hand;
  }

  async refreshTransactions() {
    this.cash_transactions = undefined;
    this.loading = true;
    this.transactions = await this.cashService.getByDate(this.date.getDate(),this.date.getMonth(),this.date.getFullYear());
    this.loading = false;

    if(this.transactions.length < 1) {
      this.cash_transactions = "Zero";
      return;
    }

    this.cash_transactions = 0;
    console.log(this.transactions);
    this.transactions = this.transactions.map(transaction=>{
      
      transaction["type"] = upperCase(transaction["type"]);

      if(!transaction.transcation_id) return transaction;

      
      let patient = transaction.transcation_id.patient_id
      transaction["name"] = startCase((transaction.transcation_id.name=="other" && transaction.transcation_id.items[0])?transaction.transcation_id.items[0].name:(transaction.transcation_id.name));
      if(!transaction["amount"]) transaction["amount"] = transaction.transcation_id.amount;
      transaction["patient_name"] = patient.title + " " + patient.firstName + ' ' + patient.lastName; 
      
      if(transaction.type != "CLEAR")
        this.cash_transactions += ((transaction.type=="CR")?1:-1) * transaction.transcation_id.amount;

      return transaction;
    })
    
    if(this.cash_transactions ===  0) this.cash_transactions = "Zero"
  }


  async export() {
    this.exporting = true;
    let transactions = this.transactions;
    let datePipe = new DatePipe('en-US');
    
    let fields = ["Date","Time","Type","For","Patient","Amount","Opening Balance","Closing Balance"] 
    
    let data = transactions.map(transaction=>{
      let dateTime = new Date(transaction.createdAt);
      let formatedDate = datePipe.transform(dateTime,'mediumDate');
      let formatedTime = datePipe.transform(dateTime,'mediumTime');
      
      return [formatedDate,formatedTime,transaction.type.toUpperCase(),transaction.name,transaction.patient_name, (transaction.amount==0)?"Poor Free":transaction.amount,transaction.opening_balance,transaction.cash_in_hand]
    })

    let exportData = {
      fields,
      data
    }

    
    let csv = Papa.unparse(exportData,{quotes: false, quoteChar: '"',escapeChar: '"',delimiter: ",",header: true,newline: "\r\n",skipEmptyLines: false,columns: null });
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "data.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.exporting = false;
  }




}
