<app-default-header></app-default-header>
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col">
          <div class="card shadow border-0">
            <div class="card-header">
                <div class="row no-gutters align-items-center justify-content-between">
                  <div class="col-auto">
                      <h2>IPD Patients</h2>
                </div>
                  <div class="col-auto">
                      <!-- <button class="btn btn-success mx-1 mx-sm-0" (click)="this.router.navigate(['/create-patient'])">Create Patient</button> -->
                  </div>
                </div>
                
            </div>
            <div class="card-body">
                <div style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;"><h3 >No Admitted Patient. Please go to patient profile and admit a patient.</h3></div>
            </div>
          </div>
        </div>
      </div>
</div>