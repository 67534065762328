import { Component, OnInit } from "@angular/core";
import { AppointmentsService } from "src/app/appointments.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location, DatePipe } from "@angular/common";

@Component({
  selector: "app-edit-appointment",
  templateUrl: "./edit-appointment.component.html",
  styleUrls: ["./edit-appointment.component.css"],
})
export class EditAppointmentComponent implements OnInit {
  dbAppointments = undefined;
  appointments = undefined;
  page_no: Number = 0;
  offset;
  pages;
  total;
  loading = false;
  page_numbers = [];
  visible = false;
  selectedAppointment;
  selectedPatient;
  opd_entry_visible = false;
  search;
  page: any = {};
  totalRecords: number;
  from = "";
  to = "";
  buttonDisabled: boolean = false;

  ddStatus = [
    { value: 0, name: "- Select Status -" },
    { value: "canceled", name: "Canceled" },
    { value: "completed", name: "Completed" },
    { value: "pending", name: "Pending" },
  ];

  constructor(
    public appointmentService: AppointmentsService,
    public route: ActivatedRoute,
    public router: Router,
    public location: Location
  ) {}

  ngOnInit(): void {
    let from = new Date();
    let to = new Date();
    let datePipe = new DatePipe("en-US");
    //from.setDate(1);
    from.setHours(0, 0, 0, 0);
    //to.setMonth(+to.getMonth() + 1);
    to.setHours(0, 0, 0, 0);
    //to.setDate(0);
    this.to = datePipe.transform(to, "yyyy-MM-dd");
    this.from = datePipe.transform(from, "yyyy-MM-dd");

    this.refreshAppointments();
  }

  async refreshAppointments() {
    this.loading = true;
    this.appointmentService
      .getCancelledAppointment(this.from, this.to)
      .then((res) => {
        this.appointments = res.appointments;
        this.loading = false;
      });
  }

  async searchHandler(e) {
    console.log(e);
    if (!e || e == "") {
      this.appointments = this.dbAppointments;
      this.page_numbers = new Array(this.pages).fill(1).map((v, i) => i + 1);
      return;
    }
    this.page_numbers = [];
    this.appointments = [];
    this.page_no;
    this.loading = true;
    this.appointments = await this.appointmentService.searchAppointment(e);
    this.loading = false;
  }

  async setPage(page: number) {
    this.page = this.getPage(this.totalRecords, page);
    let res = await this.appointmentService.getAppointments(page);
    this.appointments = res.appointments;
  }

  getPage(totalItems: number, currentPage: number = 1, pageSize: number = 8) {
    // calculate total pages
    if (totalItems <= 0 || typeof totalItems == "undefined") totalItems = 1;
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 8) {
      // less than 8 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 4) {
        startPage = 1;
        endPage = 8;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 7;
        endPage = totalPages;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  ddlStatus: string = "";
  ddlStatusID;
  onChange(event) {
    this.ddlStatus = event;
    //this.buttonDisabled = true;
  }

  choosedId;
  message: string = "";
  successMessage: boolean = false;
  onClick(event) {
    this.choosedId = event.target.id;
    console.log("id: " + this.choosedId);
    let statusData = { id: this.choosedId, status: this.ddlStatus };

    this.appointmentService.updateAppointmentStatus(statusData).then((res) => {
      if (res.appointment !== null) {
        res.appointment;
        this.successMessage = true;
        this.message = `Your Appointment status has been updated for this Patient Name  ${res.appointment.title} ${res.appointment.firstName} ${res.appointment.lastName} and Appointment Number is ${res.appointment.appointmentNumber}`;
      }
    });
  }
}
