import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {baseUrl} from './config';

@Injectable({
  providedIn: 'root'
})
export class HdfcTransactionsService {

  constructor(private httpClient: HttpClient) { }

  getHeaders() {
    return {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}};
  }
  //---------------------------------------------------
  //HDFC RESPONSE API
  async getHdfcResponse(to:string, from:string, page_no){
    let res:any = await this.httpClient.get(baseUrl + "hdfcresponse/" + to + "/" + from + "/" + page_no, this.getHeaders()).toPromise()
    return res;
  }

  async getAllHdfcResponse(to:string, from:string){
    let res:any = await this.httpClient.get(baseUrl + "hdfcresponse/" + to + "/" + from + "/", this.getHeaders()).toPromise()
    return res;
  }
}
