import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/token.service';

@Component({
  selector: 'app-all-tokens',
  templateUrl: './all-tokens.component.html',
  styleUrls: ['./all-tokens.component.css'],
})
export class AllTokensComponent implements OnInit {
  date = new Date();
  tokens = [];
  loading  = false;
  edit = false;
  todays_date = new Date();
  cash_in_hand;
  cash_transactions;
  exporting = false;
  constructor(public tokenService:TokenService) {

   }

  ngOnInit(): void {
    this.refreshTokens();
  }

  prev() {
    this.date = new Date(this.date.setDate(this.date.getDate() - 1)),
    this.refreshTokens();
  }

  next() {
    this.date = new Date(this.date.setDate(this.date.getDate() + 1)),
    this.refreshTokens();
  }

  setDate = function(date) {
    this.date = new Date(date),
    this.refreshTokens(),
    this.edit = false;
  }

  async refreshTokens() {
    this.loading = true;
    this.tokens = await this.tokenService.getByDate(this.date.getDate(),this.date.getMonth(),this.date.getFullYear());
    this.loading = false;
  }

  async convertToEmergency(token_id) {
    if(!confirm("Are you sure you want to convert token to E type?\n\nThis action is irreversible.")) return;
    await this.tokenService.convertToEmergency(token_id);
    this.refreshTokens();
  }

}
