<app-default-header></app-default-header>
<!-- <div class="header bg-gradient-success pb-8 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body">

      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Receptionist</h5>
                  <span class="h2 font-weight-bold mb-0">2</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i class="fas fa-chart-bar"></i>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Technicians</h5>
                  <span class="h2 font-weight-bold mb-0">4</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                    <i class="fas fa-chart-pie"></i>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-6 col-xl-8 mt-5 order-2 order-lg-1">
        <div class="card shadow border-0">
          <div class="card-header">
            <h3>User Accounts</h3>
          </div>

          <div class="table-responsive">
            <table *ngIf="users" class="table align-items-center table-flush">
              <thead class="thead-light">
                <tr>
                  <th>Username</th>
                  <th>User Type</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users" >
                  <td>{{user.username}}</td>
                  <td>{{user.type}}</td>
                  <td>{{user.timestamp | date:'medium' }}</td>
                  <td *ngIf="!user.edit">
                    <button [disabled]="loading" class="btn btn-sm btn-primary m-1" (click)="user.edit=true">Change Password</button>
                    <button [disabled]="loading" class="m-1 btn-warning btn btn-sm" (click)="deleteUser(user._id)">Delete</button>
                  </td>
                  <td *ngIf="user.edit">
                    <div class="mb-2">
                      <label>New Password</label>
                      <input type="password" [disabled]="loading" class="form-control" [(ngModel)]="user.newPassword" (input)="passwordInputHandler(e,user)">
                    </div>
                    <div class="alert alert-danger mb-2" *ngIf="user.passwordError">Please enter new password.</div>
                    <button [disabled]="loading" class="btn btn-sm btn-success m-1" (click)="changePassword(user)">Change</button>
                    <button [disabled]="loading" class="btn btn-sm btn-danger m-1" (click)="user.edit=false; user.newPassword=undefined">Cancel</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <div class="card-footer py-4">
            <nav aria-label="...">
              <ul class="pagination justify-content-end mb-0">
                <li class="page-item disabled">
                  <a class="page-link" href="javascript:void(0)" tabindex="-1">
                    <i class="fas fa-angle-left"></i>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">1</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)">2 <span class="sr-only">(current)</span></a>
                </li>
                <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)">
                    <i class="fas fa-angle-right"></i>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div> -->

        </div>
    </div>
    <div class="col-lg-6 col-xl-4 mt-5 order-1 order-lg-2">
      <div class="card shadow border-0">
        <div class="card-header">
          <h3>Quick Add</h3>
        </div>
        <div class="card-body">
          <div class="mb-3 mt-3">
            <label>Username</label>
            <input [disabled]="loading" class="form-control" [(ngModel)]="username" (input)="addUserInputChanged($event)"/>
          </div>
          <div class="mb-3">
            <label>Password</label>
            <input [disabled]="loading" type="password" class="form-control" [(ngModel)]="password"  (input)="addUserInputChanged($event)"/>
          </div>
          <div class="mb-3">
            <label>Type</label>
            <select [disabled]="loading" class="form-control"  [(ngModel)]="type">
              <option value="">Please select account type.</option>
              <option value="superadmin">Super Admin</option>
              <option value="admin">Admin</option>
              <option value="doctor">Doctor</option>
              <option value="receptionist">Receptionist</option>
            </select>
          </div>
          <div class="alert alert-danger mb-3" *ngIf="formError">All fields are required!</div>
          <button [disabled]="loading" class="btn btn-success d-inline" (click)="saveUser()">Add</button>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-progress-bar *ngIf="loading" class="mt-3"  mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="col-12 col-xl col-lg-12 mt-3 order-2 order-xl-1">
      <mat-card>
        <div class="row  mb-3 no-gutters align-items-center">
          <div class="col my-0">
            <h3 class="mat-heading-3 m-0">User Accounts</h3>
          </div>
          <div *ngIf="users" class="mt-3" style="overflow-x:scroll;">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Username</th>
                  <th>User Type</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>

            </table>
          </div>

        </div>
      </mat-card>
    </div>
    <div class="col-12 col-xl-3 mt-3 order-1 order-xl-2">
      <mat-card>
        <div class="row  mb-3 no-gutters align-items-center">
          <div class="col my-0">
            <h3 class="mat-heading-3 m-0">Add User</h3>
          </div>


      </mat-card>
    </div>
  </div>
</div>
 -->
