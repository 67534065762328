import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/patient.service';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.css']
})
export class VisitsComponent implements OnInit {
  entries:[];
  patient_id;
  loading;
  createNotes;
  selectedEntry;
  errored;
  visible;
  patient;

  constructor(public route:ActivatedRoute, public router:Router, public patientService:PatientService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => {
      this.patient_id = params.id;
      this.refreshVisits();
    });
  }

  async refreshVisits() {
    this.loading = true;
    this.entries = await this.patientService.getOPDEntry(this.patient_id);
    console.log(this.entries);
    this.loading = false;
  }

  createNote(entry){
    this.selectedEntry = entry;
    this.createNotes = true;
  }

  async printOPD(entry){
    this.selectedEntry = entry;
    this.patient = await this.patientService.getPatient(this.patient_id);
    this.visible = true;
  }

  closeHandler() {
    this.visible = false;
  }

  printDoc(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <style media="print">
          @page
          {
              size:  auto;
              margin: 8mm 10mm;
          }
          table {
            border-collapse: collapse;
          }
          td {
            border: 1px solid black;
            vertical-align: top;
            padding: 1rem;
          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
