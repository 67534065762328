import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.css']
})
export class AddAccountComponent implements OnInit {

  username = undefined;
  password = undefined;
  loading = false;
  formError = false;
  type =  "";
  
  constructor(public userService:UserService ) { }

  ngOnInit(): void {
  }

  addUserInputChanged(e) {
    this.formError = false;
  }

  saveUser() {
    if(!this.username || this.username == "" || !this.password || this.password == "" || !this.type || this.type == "") return this.formError = true;
    this.loading = true;
    this.userService.postUser({username:this.username, password:this.password, type:this.type}).then(user=>{

      this.username = undefined;
      this.password = undefined;
      this.type = 'admin';
      this.loading = false;
    })
  };

}
