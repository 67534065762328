import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PatientService } from 'src/app/patient.service';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.css']
})
export class CreateNoteComponent implements OnInit {

  @Input() visible;
  @Input() entries;
  @Output() onSuccess:EventEmitter<any> = new EventEmitter();
  @Output() onClose:EventEmitter<any> = new EventEmitter();

  errorMessage;
  note;
  transacting;
  errored;

  constructor(public patientService:PatientService) { }

  ngOnInit(): void {
    this.note = this.entries?.note;
  }

  successHandler() {
    this.onSuccess.emit();
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.onClose.emit();
  }

  async createNote() {
    if(!this.note.trim()) return this.errorMessage = "Please add Note";

    this.transacting = true;
    var data = {
      note: this.note,
      _id: this.entries._id
    }

    await this.patientService.markOPDNote(data, this.entries.patient_id);
    this.transacting = false;

    this.successHandler();
  }

}
