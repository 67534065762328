<app-default-header></app-default-header>
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col">
            <div class="card shadow border-0">
                <div class="card-header">
                    <div class="row no-gutters align-items-center justify-content-between">
                        <div class="col-auto">
                            <h2>All Appointments</h2>
                    </div>
                        <div class="col-auto">
                            
                        </div>
                    </div>
                    
                </div>
                <div class="card-body">
                    <div style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                        <h3 style="color:#333">No Appointments for today</h3>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>