import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location, DatePipe } from "@angular/common";
import { PatientService } from "src/app/patient.service";

@Component({
  selector: "app-patient-listing",
  templateUrl: "./patient-listing.component.html",
  styleUrls: ["./patient-listing.component.css"],
})
export class PatientListingComponent implements OnInit {
  objectKeys = Object.keys;
  from = "";
  to = "";
  page_no: Number = 0;
  offset;
  search;
  pages;
  total;
  loading = false;
  page_numbers = [];
  dbPaitents: any[] = undefined;
  paitents: any[] = undefined;
  page: any = {};
  totalRecords: number

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public patientService: PatientService
  ) {}

  opdModalSuccess() {
    this.refreshPatients();
  }

  async refreshPatients() {
    this.loading = true;
    this.paitents = undefined;
    let res = await this.patientService.getPatients(this.to, this.from, this.page_no);
    this.page_no = Number(res.page);
    //this.pages = res.pages;
    //this.page_numbers = new Array(this.pages).fill(1).map((v,i)=>(i+1));
    //this.total = res;
    //this.offset = res.offset;
    //this.dbPaitents = res.patients;

    this.totalRecords = res.total;
    this.setPage(1);

    if (res.pages < this.page_no)
      this.router.navigate(["/patients/" + res.pages]);
    this.paitents = this.dbPaitents;
    this.loading = false;
  }
  async searchHandler(e) {
    if (!e || e == "") return (this.paitents = this.dbPaitents);
    this.paitents = [];
    this.loading = true;
    this.paitents = await this.patientService.searchPatient(e);
    this.loading = false;
  }

  ngOnInit(): void {
    let from = new Date();
    let to = new Date();
    let datePipe = new DatePipe("en-US");
    from.setDate(1);
    from.setHours(0, 0, 0, 0);
    to.setMonth(+to.getMonth() + 1);
    to.setHours(0, 0, 0, 0);
    to.setDate(0);
    this.to = datePipe.transform(to, "yyyy-MM-dd");
    this.from = datePipe.transform(from, "yyyy-MM-dd");
    this.route.params.subscribe((params) => {
      if (!params.page_no) this.router.navigate["/transactions/1"];
      this.page_no = Number(params["page_no"]);
      if (!this.page_no) this.location.back();
      this.refreshPatients();
    });
  }

  async setPage(page: number) {
    this.page = this.getPage(this.totalRecords, page);
    let res = await this.patientService.getPatients(this.to, this.from, this.page_no);
    this.paitents = res.patients;
  }

  getPage(totalItems: number, currentPage: number = 1, pageSize: number = 8) {
    // calculate total pages
    if (totalItems<=0 || typeof(totalItems) == 'undefined')
    totalItems=1;
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 8) {
        // less than 8 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 4) {
            startPage = 1;
            endPage = 8;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 7;
            endPage = totalPages;
        } else {
            startPage = currentPage - 3;
            endPage = currentPage + 4;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }
}
