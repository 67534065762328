<div class="header bg-gradient-success pb-8 pt-5 pt-md-8" >
    <div class="container-fluid">
      <div class="header-body">
        <!-- Card stats -->
        <div class="row">
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Amount In <span class="text-nowrap">Cash Register</span></h5>
                    <span *ngIf="cash_in_hand" class="h2 font-weight-bold mb-0">Rs. {{cash_in_hand}}</span>
                    <span *ngIf="!cash_in_hand" class="h2 font-weight-bold mb-0">Loading...</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i class="fas fa-chart-bar"></i>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                  
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Cash Transactions</h5>
                    <span *ngIf="cash_transactions" class="h2 font-weight-bold mb-0">Rs. {{cash_transactions}}</span>
                    <span *ngIf="!cash_transactions" class="h2 font-weight-bold mb-0">Loading...</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                      <i class="fas fa-chart-pie"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>



<div class="container-fluid mt--7">
    <div class="row">
        <div class="col">
            <div class="card shadow border-0">
                <div class="card-header">
                    <div class="row no-gutters align-items-center justify-content-between">
                        <div class="col-auto">
                            <h2>Daily Cash Register</h2>
                    </div>
                        <div class="col-auto">
                            
                        </div>
                    </div>
                    
                </div>
                <div class="card-body">
                    <div class="row mb-4">
                        <div class="col-auto">
                            <button class="btn btn-sm btn-primary" (click)="prev()">Prev</button>
                        </div>
                        <div class="col-auto text-center">
                            <h3 *ngIf="!edit" (click)="edit=true">{{date | date:'fullDate'}}</h3>
                            <input *ngIf="edit" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="setDate($event)" type="date" name="date" class="form-control mx-auto " style="width: 250px !important;">
                        </div>
                        <div class="col-auto">
                            
                            <button class="btn btn-sm btn-primary" (click)="next()" [disabled]="date >= todays_date">Next</button>
                        </div>
                    </div>
                    <hr class="mb-5 mt-0" />
                    <div class="row align-items-center no-gutters">
                      <div class="col">
                        <h5 class="my-2">Transactions on {{date | date:'fullDate'}}</h5>
                      </div>
                      <div *ngIf="transactions.length > 0" class="col-auto">
                        <button (click)="export()" [disabled]="exporting" class="ml-2 mr-0 my-2 btn btn-success btn-sm">
                          <span *ngIf="exporting">
                              <i class="fa fa-spinner fa-spin"></i>&nbsp;Exporting
                          </span>
                          <span *ngIf="!exporting">
                             <i class="fa fa-file-export"></i>&nbsp; Export to CSV
                          </span>
                       </button>
                      </div>
                    </div>

                    <div *ngIf="loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                        <span style="color:#333">Loading...</span>
                    </div>


                    <div *ngIf="transactions.length < 1 && !loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;"><h3 >No Transactions for the {{date|date:'fullDate'}}.</h3></div>
                    
                    <div *ngIf="!loading && transactions.length > 0" class="w-100" style="overflow-x:auto">
                        <table class="table" style="overflow-x: scroll;">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Reason</th>
                                    <th>Patient Name</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>Opening Amount</th>
                                    <th>Closing Amount</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transaction of transactions">
                                    <td>{{transaction.createdAt | date:'fullDate'}}</td>
                                    <td>{{transaction.createdAt | date:'fullTime'}}</td>
                                    <td>{{transaction.name}}</td>
                                    <td>{{transaction.patient_name}}</td>
                                    <td>{{transaction.type}}</td>
                                    <td [class]="(transaction.type=='CR')?'text-success':'text-danger'" style="font-weight: bold;">{{transaction.amount}}</td>
                                    <td>{{transaction.opening_balance}}</td>
                                    <td>{{transaction.cash_in_hand}}</td>
                                    <td><button class="btn btn-sm btn-primary">View</button><button class="btn btn-sm btn-success">Print Receipt</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <!-- <app-patient-list [patients]="paitents" (patientUpdated)="opdModalSuccess()"></app-patient-list> -->
                </div>
            </div>
        </div>
    </div>
</div>

  <!-- <app-create-opd-entry [visible]="opdModalVisible" (onClose)="opdModalClose()"  [patient]="selectedPatient" (onSuccess)="opdModalSuccess()"></app-create-opd-entry> -->