import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import {startCase} from 'lodash'
import { ApprovalsService } from 'src/app/approvals.service';
import { DashboardService } from 'src/app/dashboard.service';
const interval = 5500;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit , OnDestroy {
  public focus;
  public listTitles: any[];
  public location: Location;
  public loading = false;
  public approvalsPollingInterval;
  public pendingApprovalsInterval;
  public approvals;
  public pending_approvals;
  public startCase = startCase;
  public enteringPassword = false;
  public counter = 0;
  public changePasswordVisible = false;

  constructor(location: Location,  private element: ElementRef, public router: Router, public auth:AuthService, public approvalService:ApprovalsService, public dashboardService:DashboardService, public authService:AuthService) {
    this.location = location;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);

    this.approvalsPollingInterval = setInterval(()=>{
      this.getApprovals();
    },interval);

    if(this.auth.getTokens()[1] == "admin") this.pendingApprovalsInterval = setInterval(()=>{
      this.getPendingApproval();
    },interval);



  }

  ngOnDestroy() {
    if(this.approvalsPollingInterval) clearInterval(this.approvalsPollingInterval);
  }


  async getApprovals() {
    this.loading = true;
    let res = await this.approvalService.getSelfApprovals();
    this.approvals = res.approvals.map(approval=>{
      let _approval = {...approval,loading:false,action:undefined, password:undefined}
      return _approval;
    });
    this.loading = false;
  }

  approveClickHandler(approval) {
    approval.action='approve';
    this.enteringPassword=true;
  }

  rejectClickHandler(approval) {
    approval.action='reject';
    this.enteringPassword=true;
  }

  cancelClickHandler(approval) {
    approval.action=undefined;
    this.enteringPassword=true;
  }

  async actionButtonHandle(approval) {
    approval.loading = true;

    try {
      let res = await this.approvalService.proccessApproval(approval.action,approval._id,approval.password);
      this.pending_approvals = this.pending_approvals.filter(x=>x._id!=approval._id);
      await this.getPendingApproval();
      this.dashboardService.refreshAll();
      this.enteringPassword = false;
      approval.action = undefined;
      approval.password = "";
      approval.error = undefined;
    }
    catch (httpErrorRequest) {
      approval.error = "Authentication Failed!";
    }

    approval.loading=false;
  }

  async getPendingApproval() {
    this.loading = true;
    let res = await this.approvalService.getPendingApprovals();
    let flag = false;
    if(this.counter > 10) flag = true;

    if(!this.enteringPassword || flag) {
      this.counter = 0;
      this.pending_approvals = res.approvals.map(approval=>{

        let _approval = {...approval}
        let found = false;
        if(this.pending_approvals)
          for (const my_approval of this.pending_approvals) {
            if(my_approval._id == _approval._id) {
              _approval = {..._approval,loading:my_approval.loading,action:my_approval.action,password:my_approval.password}
              found = true;
            }
          }

        if(!found) _approval = {..._approval,loading:false,action:undefined, password:""}
        return _approval;

      });
    }
    else this.counter++;

    this.loading = false;
  }

  async markSeen(approval) {
    approval.loading = true;
    await this.approvalService.markSeen(approval._id);
    await this.getApprovals();
    approval.loading = false;
    this.approvals = this.approvals.filter(x=>x._id!=approval._id)

  }

  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());

    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
        if(titlee.charAt(0) === "/") titlee = titlee.slice(1);
        titlee = titlee.split('/')[0];
    }
    return startCase(titlee)

    // for(var item = 0; item < this.listTitles.length; item++){
    //     if(this.listTitles[item].path === titlee){
    //         return this.listTitles[item].title;
    //     }
    // }
    // return 'Dashboard';
  }

  doLogout() {
    this.auth.doLogout().then(res=>{
      this.router.navigate(['../login']);
      console.log("Log Out Successful!");
    })
    console.log(this.router);
    this.router.navigate(['../login']);
  }

  supportClick() {
    if(confirm('Incase of any malfunction or error, Kindly contact Healthrun at +91-917814851231\n\nDo you want to use support form?')) {
      window.open("https://mobehealth.com/#elementor-action%3Aaction%3Dpopup%3Aopen%26settings%3DeyJpZCI6IjI4MDUiLCJ0b2dnbGUiOmZhbHNlfQ%3D%3D", '_blank');
    }
  }

}
