import { Component, OnInit } from "@angular/core";
import { Location, DatePipe } from "@angular/common";
import { HdfcTransactionsService } from "src/app/hdfctransactions.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth.service";
import Papa from "papaparse";
import { startCase } from "lodash";

@Component({
  selector: "new-app-bank-response",
  templateUrl: "./new-bank-response.component.html",
  styleUrls: ["./new-bank-response.component.css"],
})
export class NewBankResponseComponent implements OnInit {
  transactions = undefined;
  from = "";
  to = "";
  page_no: Number = 0;
  offset;
  pages;
  total;
  page_numbers = [];
  page: any = {};
  totalRecords: number;
  startCase = startCase;
  exporting = false;
  exportingResponse = false;

  constructor(
    public auth: AuthService,
    public location: Location,
    public router: Router,
    public hdfcTransactionService: HdfcTransactionsService,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let from = new Date();
    let to = new Date();
    let datePipe = new DatePipe("en-US");
    //from.setDate(1);
    from.setHours(0, 0, 0, 0);
    //to.setMonth(+to.getMonth() + 1);
    to.setHours(0, 0, 0, 0);
    //to.setDate(0);
    this.to = datePipe.transform(to, "yyyy-MM-dd");
    this.from = datePipe.transform(from, "yyyy-MM-dd");

    this.route.params.subscribe((params) => {
      if (!params.page_no) this.router.navigate["/new-bank-response/1"];
      this.page_no = Number(params["page_no"]);
      if (!this.page_no) this.location.back();
      this.refreshTransactions();
    });
  }

  refreshTransactions() {
    this.transactions = undefined;

    this.hdfcTransactionService
      .getHdfcResponse(this.to, this.from, this.page_no)
      .then((res) => {
        this.transactions = res.transactions;
        this.page_no = Number(res.page);
        this.totalRecords = res.total;
        this.setPage(1);

        if (res.pages < this.page_no)
          this.router.navigate(["/new-bank-response/" + res.pages]);
      });
  }

  async setPage(page: number) {
    this.transactions = undefined;
    this.page = this.getPage(this.totalRecords, page);
    let res = await this.hdfcTransactionService.getHdfcResponse(
      this.to,
      this.from,
      page
    );
    this.transactions = res.transactions;
  }

  getPage(totalItems: number, currentPage: number = 1, pageSize: number = 15) {
    // calculate total pages
    if (totalItems <= 0 || typeof totalItems == "undefined") totalItems = 1;
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 15) {
      // less than 15 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 4) {
        startPage = 1;
        endPage = 15;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 7;
        endPage = totalPages;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  async export() {
    this.exporting = true;
    let transactions = await this.hdfcTransactionService.getAllHdfcResponse(
      this.to,
      this.from
    );

    console.log('Neeraj', transactions.transactions);

    let datePipe = new DatePipe("en-US");

    let fields = [
      "Date",
      "Time",
      "Patient",
      "Mode of Payment",
      "Order Number",
      "Amount",
      "Appointment Number",
      "Appointment Date",
      "Tracking Id",
      "Bank ref no",
      "card Name",
      "Order Status",
    ];

    let data = transactions.transactions.map((transaction) => {
      let dateTime = new Date(transaction.createdAt);
      let formatedDate = datePipe.transform(dateTime, "mediumDate");
      let formatedTime = datePipe.transform(dateTime, "mediumTime");
      let _for =
        transaction.name == "other" && transaction.items[0]
          ? transaction.items[0].name
          : startCase(transaction.name);
      let name = transaction.patient_id
        ? transaction.patient_id.title +
          " " +
          transaction.patient_id.firstName +
          " " +
          transaction.patient_id.lastName
        : "";

      return [
        formatedDate,
        formatedTime,
        transaction.fullName,
        transaction.payment_mode,
        transaction.orderNumber,
        transaction.amount,
        transaction.appointmentNumber,
        transaction.appointmentDate,
        transaction.tracking_id,
        transaction.bank_ref_no,
        transaction.card_name,
        transaction.order_status,
      ];
    });

    let exportData = {
      fields,
      data,
    };

    let csv = Papa.unparse(exportData, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
      columns: null,
    });
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "data.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.exporting = false;
  }

  // async exportHDFCResponse() {
  //   this.exporting = true;
  //   let transactions = await this.transactionsService.getAllTransactionsByDate(
  //     this.to,
  //     this.from
  //   );
  //   let datePipe = new DatePipe("en-US");

  //   let fields = [
  //     "Order Id",
  //     "Tracking Id",
  //     "Payment Mode",
  //     "Bank ref no",
  //     "Encoded Response",
  //   ];

  //   let data = transactions.map((transaction) => {
  //     return [
  //       transaction.order_id,
  //       transaction.tracking_id,
  //       transaction.payment_mode,
  //       transaction.bank_ref_no,
  //       transaction.enc_response,
  //     ];
  //   });

  //   let exportData = {
  //     fields,
  //     data,
  //   };

  //   let csv = Papa.unparse(exportData, {
  //     quotes: false,
  //     quoteChar: '"',
  //     escapeChar: '"',
  //     delimiter: ",",
  //     header: true,
  //     newline: "\r\n",
  //     skipEmptyLines: false,
  //     columns: null,
  //   });
  //   var downloadLink = document.createElement("a");
  //   var blob = new Blob(["\ufeff", csv]);
  //   var url = URL.createObjectURL(blob);
  //   downloadLink.href = url;
  //   downloadLink.download = "data.csv";
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  //   this.exporting = false;
  // }
}
