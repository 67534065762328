<app-default-header></app-default-header>

<div class="container-fluid mt-4">
    <div class="row">
      <div class="col">
        <div class="card shadow border-0">
          <div class="card-header">
            <div class="row no-gutters align-items-center justify-content-between">
              <div class="col-auto">
                <h2>Update Appointments</h2>
              </div>
              <div class="col-auto"></div>
            </div>
          </div>
          <div class="card-body">
            <h5>Appointments</h5>
  
            <div class="row no-gutters g-0 mb-1 align-items-center">
              <div class="col pb-1">
                <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control"
                  placeholder="Search by patient name, phone number, email or Appointment No.">
              </div>
              <div *ngIf="search" (click)="search = null; searchHandler($event)" class="col-auto">
                <button class="btn btn-warning ml-2 mt--1">Cancel</button>
              </div>
            </div>
  
            <div *ngIf="loading"
              style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
              <span style="color:#333">Loading...</span>
            </div>
  
            <div *ngIf="!loading" class="w-100" style="overflow-x:auto">
              <app-appointments-list [appointments]="appointments" (onSuccess)="refreshAppointments()" [isUpdateAppointment]="true">
              </app-appointments-list>
            </div>
  
            <div class="card-footer py-4">
              <nav aria-label="...">
                <ul *ngIf="page.pages && page.pages.length" class="pagination mb-0" style="float: right;">
                  <li class="page-item {{(page.currentPage===1)?'disabled':''}}">
                    <a class="page-link" (click)="setPage(1)">
                      <i class="fas fa-angle-left"></i>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
  
                  <li *ngFor="let num of page.pages" class="page-item {{page.currentPage == num ? 'active': ''}}">
                    <a class="page-link" (click)="setPage(num)">{{num}}</a>
                  </li>
  
                  <li class="page-item {{(page.currentPage === page.totalPages)?'disabled':''}}">
                    <a class="page-link" (click)="setPage(page.totalPages)">
                      <i class="fas fa-angle-right"></i>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
