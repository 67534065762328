<div class="header bg-gradient-primary pt-3 pb-6 pb-lg-7" style="width:98%">
  <div class="row" style="min-height: 200px;">
    <div class="col-md-4 col-sm-4" *ngIf="showBacktoWebsite">
      <a href="https://rsicmohali.com/" class="h4 mb-0 text-white text-uppercase d-none d-md-inline-block"
        style="cursor: hand;font-size: 16px!important;margin-left:10%">Back to Website</a>
    </div>
    <div class="col-md-5 col-sm-5" *ngIf="showBacktoWebsite"></div>
    <div class="col-md-3 col-sm-3" *ngIf="showBacktoWebsite">
      <a href="javascript:void(0)">Scan me for Live OPD</a>
      <a href="http://his.rsicmohali.com/#/virtual-lounge">
        <img src="../../../assets/img/VirtualLounge.png" class="mx-auto d-block" />
      </a>
    </div>
  </div>
  <div class="container" style="margin-top: -16%;">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div class="col-10">
          <a href="https://rsicmohali.com/" *ngIf="showBacktoWebsite">
            <img src="../../../assets/img/logo/RISC-logo.png" class="mx-auto" style="max-width: 130px;" />
          </a>
          <img src="../../../assets/img/logo/RISC-logo.png" *ngIf="!showBacktoWebsite" class="mx-auto"
            style="max-width: 130px;" />
          <h1 class="text-white">RSIC Appointment Booking Portal</h1>
        </div>
        <div class="col-lg-10 col-md-8">
          <p class="text-lead text-light">Please use the form below to book appointments at Regional Spinal Injury
            Centre.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="separator separator-bottom separator-skew zindex-100">
    <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>

<div class="container mt--9 pb-5">
  <div class="row justify-content-center">
    <div class="col-lg-11 col-md-12">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent">
          <h2 class="m-0 text-center text-primary">RSIC Online Appointment Booking</h2>
        </div>

        <div class="card-body  px-lg-5">
          <div *ngIf="isEnableTab_0">
            <div class="row">
              <div class="col-12">
                <h2 class="text-warning ">Disclamer</h2>
                <ul>
                  <li class="py-1">All Appointments will be booked for physical OPD at RSIC, Sector 70, SAS Nagar.</li>
                  <li class="py-1">Appointments must be booked atleast 2 days prior to the date of consultation.</li>
                  <li class="py-1">Weekday OPD Appointments can only be booked for days through Monday to Friday.</li>
                  <li class="py-1"><b>OPD for Dr. Raj Bahadur is everyday now, All Attendees are requested to get an
                      appointment.</b></li>
                  <li class="py-1">All Patient/Attendies are required to reach before 12PM for physical card
                    registration.</li>
                  <li class="py-1">Hospital holds right to change, re-sechedule or cancel Appointments without any
                    notice.</li>
                  <li class="py-1">Appointments can help ease the proccess but does not guarantee confirmed consultation
                  </li>
                  <li class="py-1">The patient will be notified in case of any cancellation via text message or a phone
                    call.</li>
                  <li class="py-1">You can also Cancel your appointment by clicking on the “Check/Cancel Appointment” on
                    this page.</li>
                  <li class="py-1">Please remember that a total charge of Rs.50 needs to be paid online to book
                    appointment. Please present your Appointment Number at the reception to get your Token Number.</li>
                  <li class="py-1">Additional Terms and Conditions may apply.</li>
                  <li class="py-1">It is mandatory to have a proof of both COVID-Vaccinations (Vaccinations Certificate)
                    to enter the hospital premises for any purpose.</li>
                </ul>
              </div>

              <div style="margin-top:10px;"></div>
              <div class="row" *ngIf="showPaymentMode">
                <div class="col-md-12" style="margin-left: 10%;">
                  <mat-radio-group [(ngModel)]="paymentMode" (ngModelChange)="errorMessage=undefined;"
                    name="paymentMode" aria-label="Select an option" class="mr-3">
                    <mat-radio-button value="cash">Cash</mat-radio-button>
                    <mat-radio-button value="netBanking">Through Net Banking</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-md-12" style="margin-left: 10%;">
                  <div *ngIf="errorMessage" class="mb-3 text-danger w-100 text-left">{{errorMessage}}</div>
                </div>
              </div>
            </div>

            <div class="row no-gutters align-items-end">
              <div class="col pr-4">
                <p class="m-0   mt-4 p-0 text-small">Already Booked an Appointment?</p>
                <button type="button" class="btn btn-secondary" (click)="cancelCheckAppointment()">Check / Cancel
                  Appointment</button>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-primary mt-4"
                  (click)="enableTabs('weekday'); selectedType='general'">Book Weekday
                  OPD Appointment</button>
                <button type="button" class="btn btn-primary mt-4"
                  (click)="enableTabs('weekend'); selectedType='special'">Book Saturday
                  OPD Appointment</button>
                <!-- <button type="button" class="btn btn-primary mt-4" (click)="tab=1; selectedType='general'">Book Weekday
                  OPD Appointment</button>
                <button type="button" class="btn btn-primary mt-4" (click)="tab=1; selectedType='special'">Book Saturday
                  OPD Appointment</button> -->
              </div>
            </div>
          </div>

          <form *ngIf="isEnableTab_1" role="form">
            <h1 class="mb-4 display-4">Book {{(selectedType=="special")?"Special":"General"}} OPD Appointment</h1>
            <h6 class="heading-small text-primary mb-3">Patient Details</h6>
            <div class="row">
              <div class="col-lg-4 col-xl-2 col-md-3">
                <div class="form-group">
                  <label class="form-control-label">Title:* </label>
                  <select name="title" [(ngModel)]="formData['title']" (ngModelChange)="validateField('title')"
                    class="form-control form-control-alternative">
                    <option value="">Please select a title</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Miss.">Miss.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Dr.">Dr.</option>
                  </select>
                  <div *ngIf="error['title']" class="alert text-danger"> {{error['title']}}</div>
                </div>
              </div>
              <div class="col-md-5 col-lg-4 col-xl-5">
                <div class="form-group">
                  <label class="form-control-label" for="firstName">First Name:* </label>
                  <input type="text" name="firstName" [(ngModel)]="formData['firstName']"
                    (ngModelChange)="validateField('firstName')" class="form-control form-control-alternative" />
                  <div *ngIf="error['firstName']" class="alert text-danger"> {{error['firstName']}}</div>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-xl-5">
                <div class="form-group">
                  <label class="form-control-label">Last Name:* </label><input type="text" name="lastName"
                    [(ngModel)]="formData['lastName']" (ngModelChange)="validateField('lastName')"
                    class="form-control form-control-alternative" />
                  <div *ngIf="error['lastName']" class="alert text-danger"> {{error['lastName']}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xl-3">
                <div class="form-group">
                  <label class="form-control-label" for="gender">Gender:* </label><select name="gender"
                    [(ngModel)]="formData['gender']" (ngModelChange)="validateField('gender')"
                    class="form-control form-control-alternative">
                    <option value="">Please select a gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="T">Transexual</option>
                    <option value="O">Other</option>
                  </select>
                  <div *ngIf="error['gender']" class="alert text-danger"> {{error['gender']}}</div>
                </div>
              </div>
              <div class="col-md-6 col-xl-3">
                <div class="form-group">
                  <label class="form-control-label" for="dob">DOB:* </label><input type="date" name="dob"
                    [(ngModel)]="formData['dob']" (ngModelChange)="validateField('dob')"
                    class="form-control form-control-alternative" />
                  <div *ngIf="error['dob']" class="alert text-danger"> {{error['dob']}}</div>
                  <!---->
                </div>
              </div>

              <div class="col-md-12 col-xl-6">
                <div class="form-group">
                  <div class="form-group">
                    <label class="form-control-label" for="reason">Reason for appointment: </label><input type="text"
                      name="reason" class="form-control form-control-alternative" [(ngModel)]="formData['reason']"
                      (ngModelChange)="validateField('reason')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 col-lg-4 col-xl-5">
                <div class="form-group">
                  <label class="form-control-label" for="idProof">Patient Aadhar Card Number:* </label>
                  <input type="text" name="AadharCardNumber" [(ngModel)]="formData['AadharCardNumber']"
                    class="form-control form-control-alternative" placeholder="Aadhar Card Number"
                    (ngModelChange)="validateField('AadharCardNumber')" />
                  <div *ngIf="error['AadharCardNumber']" class="alert text-danger"> {{error['AadharCardNumber']}}</div>
                </div>
              </div>
              <!-- <div class="col-md-5 col-lg-4 col-xl-5">
                <div class="form-group">
                  <label class="form-control-label" for="idProof">CR Number: </label>
                  <input type="text" name="crNumber" class="form-control form-control-alternative"
                    [(ngModel)]="formData['crNumber']" (change)="validateCR()"
                    (ngModelChange)="validateField('crNumber'); this.crExists = false;" />
                  <div *ngIf="error['crNumber']" class="alert text-danger"> {{error['crNumber']}}</div>
                  <div *ngIf="checkingCR" class="alert text-warning">
                    <i class="fa fa-spinner fa-spin"></i> Validating CR Number.
                  </div>
                  <div *ngIf="crExists" class="alert text-danger">
                    CR Number already exists. <a *ngIf="crID" [routerLink]="['/patient/'+crID]">Go To Profile.</a>
                  </div>
                </div>
              </div> -->
            </div>
            <h6 class="heading-small text-primary mb-3">Contact Details</h6>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="phoneNumber">Contact Number:* </label>
                  <div class="row no-gutters">
                    <div class="col-auto align-self-center pr-1">+91 -</div>
                    <div class="col">
                      <input type="number" name="phoneNumber" class="form-control  form-control-alternative"
                        [(ngModel)]="formData['phoneNumber']" (ngModelChange)="validateField('phoneNumber')" />
                    </div>
                  </div>
                  <div *ngIf="error['phoneNumber']" class="alert text-danger"> {{error['phoneNumber']}}</div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label class="form-control-label" for="email">Email: </label><input type="email" name="email"
                    [(ngModel)]="formData['email']" (ngModelChange)="validateField('email')"
                    class="form-control form-control-alternative" />
                  <div *ngIf="error['email']" class="alert text-danger"> {{error['email']}}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="form-control-label" for="language">Correspondence Language:* </label>
                  <select name="language" [(ngModel)]="formData['language']" (ngModelChange)="validateField('language')"
                    class="form-control form-control-alternative">
                    <option value="English">English</option>
                    <option value="Punjabi">Punjabi</option>
                    <option value="Hindi">Hindi</option>
                  </select>
                  <div *ngIf="error['language']" class="alert text-danger"> {{error['language']}}</div>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="line1">Address Line 1*</label><input type="text" name="line1"
                    [(ngModel)]="formData['line1']" (ngModelChange)="validateField('line1')"
                    class="form-control form-control-alternative" />
                  <div *ngIf="error['line1']" class="alert text-danger"> {{error['line1']}}</div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="line2">Address Line 2*</label><input type="text" name="line2"
                    [(ngModel)]="formData['line2']" (ngModelChange)="validateField('line2')"
                    class="form-control form-control-alternative" />
                  <div *ngIf="error['line2']" class="alert text-danger"> {{error['line2']}}</div>
                </div>
              </div>
            </div> -->
            <div>
              <h6 class="heading-small text-primary mb-3">Select Appointment Date:</h6>
              <mat-form-field appearance="outline" (click)="picker.open()" class="w-100">
                <input [disabled]="dateSelectionDisabled" matInput [matDatepicker]="picker"
                  [matDatepickerFilter]="myFilter" [min]="minDate" [max]="maxDate" name="date"
                  [(ngModel)]="formData['date']" (ngModelChange)="validateField('date'); dateChanged()"
                  placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #picker></mat-datepicker>
              </mat-form-field>
              <div *ngIf="error['date']" class="alert text-danger mt--3"> {{error['date']}}</div>
              <div *ngIf="available_slots" class="alert text-primary mt--3"
                [ngClass]="{'red': appointmentCount == 0 && showBacktoWebsite}"> {{available_slots}}</div>
            </div>

            <div class="row" *ngIf="errorMesage">
              <div class="alert alert-danger">
                <p style="padding: 10px;" [innerHTML]="errorMesage"></p>
              </div>
            </div>

            <div>
              <button type="button" class="btn btn-success ml-2 mt-4" (click)="submit()"
                [disabled]='appointmentCount == 0 && showBacktoWebsite'>Book Appointment</button>
              <button type="button" class="btn btn-danger ml-2 mt-4" (click)="close()">Cancel</button>
            </div>

            <div *ngIf="showPaymentMode">
              <form #form ngNoForm id="nonseamless" method="post" name="redirect"
                *ngIf="this.paymentMode == 'netBanking'"
                action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                <input type="hidden" id="encRequest" name="encRequest" value="{{encRequestRes}}">
                <input type="hidden" name="access_code" id="access_code" value="{{accessCode}}">
              </form>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <h6>* Please donot close or r efresh while booking appointment.</h6>
                <h6>* After booking appointment you will be shown Appointment No., Please take note of appointment
                  number as it will be required during consultation.</h6>
                <h6>* All of the details regarding appointment will also be sent to mobile number and email address.
                </h6>
                <h6>* Please read disclamer before booking.</h6>
                <h6>* By Booking Appointment you agree to our terms and conditions.</h6>
              </div>
            </div>
          </form>

          <div *ngIf="isEnableTab_2">
            <h2 class="text-success">✔ Appointment successfully booked for {{this.appointmentDate | date:'fullDate'}}.
            </h2>
            <ul>
              <li class="py-2">Your appointment is for {{selectedType}} OPD and your appointment number is: <span
                  style="font-weight: 900;font-size: 24px;"> {{this.order_id}} </span> </li>
              <li class="py-2">Appointment booked for this Aadhar Card Number <span
                  style="font-weight: 900;font-size: 24px;">{{this.aadharNumber}}</span></li>
              <li class="py-2">Appointment is booked for <span
                  style="font-weight: 900;font-size: 24px;">{{appointment_name}}</span></li>
              <li class="py-2">Your payment of <span style="font-weight: 900;font-size: 24px;">Rs. {{paymentAmount}} is
                  successful</span> having transaction no. <span
                  style="font-weight: 900;font-size: 24px;">{{tracking_id}} </span> </li>
              <li class="py-2">Your transaction date & time: <span
                  style="font-weight: 900;font-size: 24px;">{{transaction_dateTime | date:'dd MMMM, yyyy
                  hh:MM:ss'}}</span></li>
            </ul>
            <div class="text-right">
              <button class="btn btn-lg btn-danger" (click)="close()">Close</button>
            </div>
          </div>

          <div *ngIf="isEnableTab_3">
            <h6 class="heading-small text-primary mb-3">Find Appointment</h6>
            <div *ngIf="!searched">
              <div class="form-group">
                <label for="appointmentNumber" class="form-control-label">Appointment Number</label>
                <input [disabled]="searching" type="number" name="appointmentNumber"
                  [(ngModel)]="searchFormData['appointmentNumber']"
                  (ngModelChange)="validateSearchField('appointmentNumber')"
                  class="form-control form-control-alternative">
                <div *ngIf="searchErrors['appointmentNumber']" class="alert text-danger ">
                  {{searchErrors['appointmentNumber']}}</div>
              </div>
              <div class="form-group">
                <label for="dob" class="form-control-label">Date of Birth</label>
                <input [disabled]="searching" type="date" name="dob" [(ngModel)]="searchFormData['dob']"
                  (ngModelChange)="validateSearchField('dob')" class="form-control form-control-alternative">
                <div *ngIf="searchErrors['dob']" class="alert text-danger "> {{searchErrors['dob']}}</div>
              </div>
              <hr />
              <div *ngIf="searchMainError.visible" class="alert text-danger "> {{searchMainError.message}}</div>
              <div *ngIf="searching" class="alert text-primary ">
                <i class="fa fa-spinner fa-spin"></i>&nbsp;Finding Appointment;
              </div>
              <button [disabled]="searching" class="btn btn-danger" (click)="close()">Go Back</button>
              <button [disabled]="searching" class="btn btn-success" (click)="search()">Find Appointment</button>

            </div>
            <div *ngIf="searched">
              <h2 *ngIf="this.searchedAppointment.status == 'pending'" class="text-success">✔ Appointment confirmed for
                {{this.searchedAppointment.date | date:'fullDate'}}.</h2>
              <h2 *ngIf="this.searchedAppointment.status == 'completed'" class="text-primary">🏥 Appointment completed.
              </h2>
              <h2 *ngIf="this.searchedAppointment.status == 'canceled'" class="text-danger">❌ Appointment at
                {{this.searchedAppointment.date | date:'fullDate'}} for {{this.searchedAppointment.title}}
                {{this.searchedAppointment.firstName}} {{this.searchedAppointment.lastName}} canceled.</h2>
              <ul *ngIf="this.searchedAppointment.status == 'pending'">
                <li class="py-2">Appointment is for OPD and your appointment number is: <span
                    style="font-size: 24px; font-weight: 900;">{{this.searchedAppointment.appointmentNumber}}</span>
                </li>
                <li class="py-2">Appointment is booked for {{this.searchedAppointment.title}}
                  {{this.searchedAppointment.firstName}} {{this.searchedAppointment.lastName}}</li>
              </ul>
              <div *ngIf="canceling" class="alert text-danger ">
                <i class="fa fa-spinner fa-spin"></i>&nbsp;Canceling Appointment;
              </div>
              <div class="text-right">
                <button [disabled]="canceling" class="btn btn-lg btn-warning" (click)="close()">Go Back</button>
                <button *ngIf="this.searchedAppointment.status == 'pending'" [disabled]="canceling"
                  class="btn btn-lg btn-danger" (click)="cancel()">Cancel Appointment</button>
              </div>
            </div>
          </div>

          <div *ngIf="isEnableTab_4">
            <h2 class="text-danger">❌ Your appointment booking is failed.</h2>
            <ul>
              <li class="py-2">Your Transaction No. is <b>{{tracking_id}} </b> </li>
              <li class="py-2">Please try again.</li>
            </ul>
            <div class="text-right">
              <button class="btn btn-lg btn-danger" (click)="close()">Close</button>
            </div>
          </div>

          <div *ngIf="isEnableTab_5">
            <h2 class="text-success">✔ Appointment successfully booked for {{this.appointmentDate | date:'fullDate'}}.
            </h2>
            <ul>
              <li class="py-2">Your appointment is for {{selectedType}} OPD and your appointment number is: <span
                  style="font-weight: 900;font-size: 24px;"> {{this.appointmentNumber}} </span> </li>
              <li class="py-2">Appointment booked for this Aadhar Card Number <span
                  style="font-weight: 900;font-size: 24px;">{{this.aadharNumber}}</span></li>
              <li class="py-2">Appointment is booked for
                {{this.formData.title}} {{this.formData.firstName}} {{this.formData.lastName}}</li>
            </ul>
            <div class="text-right">
              <button class="btn btn-lg btn-danger" (click)="close()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-notification *ngIf="confirmAlert" [visible]="confirmAlert" (onClose)="confirmAlert = false;"
  (onSuccess)="onSuccess()" [appointment]="selectedAppointment">
</app-confirm-notification>
