import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal/modal.component';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { FormsModule } from '@angular/forms';
import { ViewTransactionItemModalComponent } from './view-transaction-item-modal/view-transaction-item-modal.component';
import { AppointmentsListComponent } from './appointments-list/appointments-list.component';
import { DefferTokenComponent } from './deffer-token/deffer-token.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ModalComponent,
    UserChangePasswordComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ModalComponent,
    UserChangePasswordComponent
  ]
})
export class ComponentsModule { }
