<div class="card shadow-0 border" >
    <div class="p-3">
        <div class="row">
            <div class="col">
                <div class="row">
                    <!-- <div class="col-auto">
                        <label for="fromDate" class="form-control-label">From:</label>
                        <input type="date" name="fromDate" [(ngModel)]="from" (ngModelChange)="refreshTransactions()" class="form-control form-control-alternative"/>
                    </div>
                    <div class="col-auto">
                        <label for="toDate" class="form-control-label">To:</label>
                        <input type="date" name="toDate" [(ngModel)]="to" (ngModelChange)="refreshTransactions()" class="form-control form-control-alternative"/>
                    </div> -->
                </div>
            </div>
            <div class="col-auto align-self-center">
                <div *ngIf="patient" class="ml-auto w-auto">
                    <button class="btn btn-sm btn-primary ml-2 mr-0" (click)="refreshTransactions()">
                        <i class="fa fa-sync" aria-hidden="true"></i>&nbsp;&nbsp;Transactions
                    </button>
                    <button (click)="createBillVisible = true;" class="ml-2 mr-0 btn btn-warning btn-sm">
                        <i class="fa fa-cart-plus"></i> Create Bill</button>
                    <button (click)="export()" [disabled]="exporting" class="ml-2 mr-0 btn btn-success btn-sm">
                        <span *ngIf="exporting">
                            <i class="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;Exporting
                        </span>
                        <span *ngIf="!exporting">
                           <i class="fa fa-file-export"></i>&nbsp;&nbsp;Export to CSV
                        </span>
                    </button>
                </div>
                <div *ngIf="!patient">
                    <i class="fa fa-spinner fa-spin"></i>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="transactions && transactions.length > 0">
        <div class="table-responsive">
            <table class="table align-items-center table-flush">
                <thead class="thead-dark">
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Type</th>
                        <th>For</th>
                        <th>Patient</th>
                        <th>Mode of Payment</th>
                        <!-- <th>Initiated By</th> -->
                        <th>Transaction ID</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <!-- <th>Actions</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of transactions" class="{{(transaction.type=='dr')?'bg-warning text-white':''}}">
                        <td>{{transaction.createdAt | date:'mediumDate'}}</td>
                        <td>{{transaction.createdAt | date:'mediumTime'}}</td>
                        <td>{{transaction.type.toUpperCase()}}</td>
                        <td>{{(transaction.name=="other" && transaction.items.length < 2)?transaction.items[0].name:startCase(transaction.name)}}</td>
                        <td>{{transaction.patient_id.title}} {{transaction.patient_id.firstName}} {{transaction.patient_id.lastName}}</td>
                        <td>{{transaction.mode_of_payment.toUpperCase()}}</td>
                        <!-- <td>{{transaction.initiated_by.username}}</td> -->
                        <td>{{transaction.tracking_id}}</td>
                        <td>{{transaction.amount.toFixed("0.00")}}</td>
                        <td>{{transaction.paymentStatus}}</td>
                        <!-- <td>
                            <button *ngIf="transaction.items && transaction.items.length > 0" class="btn-sm btn-info btn" (click)="view(transaction.items)">View Items</button>
                            <button *ngIf="transaction.type=='cr'" class="btn-sm btn-danger btn" (click)="refund(transaction)">Refund</button>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="!transactions" style="height: 300px; display: flex; align-items: center; justify-content: center; background-color: rgb(221, 221, 221);">
        <h3>Loading...</h3>
    </div>
    <div *ngIf="transactions && transactions.length < 1" style="height: 300px; display: flex; align-items: center; justify-content: center; background-color: rgb(221, 221, 221);">
        <h3>No Transactions!</h3>
    </div>

    <div class="card-footer py-4">
        <nav aria-label="...">
          <ul class="pagination justify-content-end mb-0">
            <li class="page-item {{(page_no==1)?'disabled':''}}">
              <a class="page-link" [routerLink]="(page_no!=1)?['/patient/', patient_id,'transactions', (+page_no-1)]:[]" tabindex="-1">
                <i class="fas fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li *ngFor="let page of page_numbers" class="page-item {{(page == page_no)?'active':''}}">
                <a class="page-link" [routerLink]="['/patient/', patient_id, 'transactions', page]">{{page}}</a>
            </li>
            <li class="page-item">
              <a class="page-link" [routerLink]="(page_no<page_numbers.length)?['/patient/', patient_id, 'transactions', (+page_no+1)]:[]">
                <i class="fas fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
    </div>
</div>

<app-request-refund *ngIf="selectedTransaction" [visible]="refundModalOpen" (onClose)="refundModalOpen=false;"  (onSuccess)="refreshTransactions()" [transaction]="selectedTransaction"></app-request-refund>


<app-create-bill *ngIf="createBillVisible && patient" [visible]="createBillVisible" (onClose)="createBillVisible = false;" (onSuccess)="refreshTransactions()" [patient]="patient"></app-create-bill>

<app-view-transaction-item-modal *ngIf="items" [visible]="viewModalOpen" (onClose)="viewModalOpen=false; items=undefined" [items]="items"></app-view-transaction-item-modal>

<app-modal [visible]="showModal" (onClose)="closeHandler()" title="" bodyColor="#EEE">
  <div *ngIf="order_status != 'Success' && order_status != 'Shipped'">
    <h2 class="text-danger">❌ Your transaction has been failed.</h2>
    <ul>
      <li class="py-2">Your Transaction No. is <b>{{tracking_id}}</b></li>
      <li class="py-2">Please try again.</li>
    </ul>
  </div>
  <div *ngIf="order_status == 'Success' || order_status == 'Shipped'">
    <h2 class="text-success">✔ Your transaction has been Successfully Processed.</h2>
    <ul>
      <li class="py-2">Your Transaction No. is <b>{{tracking_id}}</b></li>
    </ul>
  </div>
</app-modal>
