<app-modal [visible]="visible" (onClose)="closeHandler()" title="Registration for MobeHealth">
  <div *ngIf="!transacting">
    <div class="row align-items-center mb-3 no-gutters">
      <div class="col-md-12">
        <label for="firstName">Email Address:* </label>
        <input type="text" name="email" [(ngModel)]="email" class="form-control"
          [disabled]="isEmail" />
      </div>
      <div class="col-md-12">
        <label for="firstName">Aadhar Card Number:* </label>
        <input type="text" name="adhaarNumber" [(ngModel)]="adhaarNumber" class="form-control"
          [disabled]="isDisabled" />
      </div>
      <div class="col-md-12">
        <label for="firstName">State:* </label>
        <select name="state" [(ngModel)]="state" (change)="validateField($event)" class="form-control">
          <option value="">Please select a state</option>
          <option value="Andhra Pradesh">Andhra Pradesh</option>
          <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
          <option value="Assam">Assam</option>
          <option value="Bihar">Bihar</option>
          <option value="Chandigarh">Chandigarh</option>
          <option value="Chhattisgarh">Chhattisgarh</option>
          <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
          <option value="Daman and Diu">Daman and Diu</option>
          <option value="Delhi">Delhi</option>
          <option value="Goa">Goa</option>
          <option value="Gujarat">Gujarat</option>
          <option value="Haryana">Haryana</option>
          <option value="Himachal Pradesh">Himachal Pradesh</option>
          <option value="Jammu and Kashmir">Jammu and Kashmir</option>
          <option value="Jharkhand">Jharkhand</option>
          <option value="Karnataka">Karnataka</option>
          <option value="Kerala">Kerala</option>
          <option value="Lakshadweep">Lakshadweep</option>
          <option value="Madhya Pradesh">Madhya Pradesh</option>
          <option value="Maharashtra">Maharashtra</option>
          <option value="Manipur">Manipur</option>
          <option value="Meghalaya">Meghalaya</option>
          <option value="Mizoram">Mizoram</option>
          <option value="Nagaland">Nagaland</option>
          <option value="Odisha">Odisha</option>
          <option value="Puducherry">Puducherry</option>
          <option value="Punjab">Punjab</option>
          <option value="Rajasthan">Rajasthan</option>
          <option value="Sikkim">Sikkim</option>
          <option value="Tamil Nadu">Tamil Nadu</option>
          <option value="Telangana">Telangana</option>
          <option value="Tripura">Tripura</option>
          <option value="Uttarakhand">Uttarakhand</option>
          <option value="Uttar Pradesh">Uttar Pradesh</option>
          <option value="West Bengal">West Bengal</option>
        </select>
      </div>
      <!-- <div class="col-md-12" style="margin-top: 3%;">
        <input type="checkbox" id="chkPetPay" [(ngModel)]="ispostPay" /> Payment Received
      </div> -->
    </div>
  </div>

  <div *ngIf="transacting" class="d-flex w-100 align-items-center justify-content-center"
    style="height: 200px; background-color: #DEDEDE;">
    <h6><i class="fas fa-spinner fa-spin"></i>&nbsp; Transacting</h6>
  </div>

  <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;" />

  <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div *ngIf="errorMessage" class="mb-3 text-danger w-100 text-right">{{errorMessage}}</div>
    <div class="row no-gutters justify-content-between">
      <div class="col-auto">
      </div>
      <div class="col-auto">
        <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
        <button [disabled]="isDisableButton" class="d-block ml-auto btn btn-success btn-md"
          (click)="mobehealthRegistration()">Mobe Health Registration</button>
        <!-- <button [disabled]="transacting" class="d-block ml-auto btn btn-alert btn-sm" (click)="closeHandler()">No</button> -->
      </div>
    </div>
  </div>
</app-modal>
