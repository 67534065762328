import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TokenService } from 'src/app/token.service';

@Component({
  selector: 'app-deffer-token',
  templateUrl: './deffer-token.component.html',
  styleUrls: ['./deffer-token.component.css']
})
export class DefferTokenComponent implements OnInit {
  
  @Input() visible;
  @Output() onSuccess:EventEmitter<any> = new EventEmitter();
  @Output() onClose:EventEmitter<any> = new EventEmitter();

  minutes; 

  transacted;
  transacting;
  errored;
  validationError;

  constructor(public tokenService:TokenService) { }

  ngOnInit(): void {
  }

  successHandler() {
    this.onSuccess.emit()
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.minutes = undefined;
    this.transacting =false;
    this.onClose.emit();
  }

  validateField() {
    let flag = true;
  

    if(!this.minutes || this.minutes <= 0 ){ 
      flag = false;
    }

    if(!flag) this.validationError = "Please enter a valid time.";
    else this.validationError = undefined;

    return flag;
  }

  changeHandler(e) {
    this.validateField()
  }

  async clickHandler() {
    
    if(!this.validateField()) return;
    console.log("Validated!");
    this.transacting = true;
    
    try {
      await this.tokenService.defferToken(this.minutes);
      this.errored = false;
      this.transacting = false;
      this.successHandler();
    }
    catch(err) {
      console.log(err);
      this.errored = true;
      return;
    }
  
  }

}
