<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main" style="background:#2f4358 !important;">
  <div class="container-fluid">
    <!-- Toggler -->
    <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
       aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <div class="mobeHealth">
      <div class="row no-gutters align-items-center">
        <div class="col-auto">
          <img src="../../../assets/img/brand/mobeHealthPlus.png" class="health-logo"/>
        </div>
        <div class="col-auto">
          <span class="health">Health</span><span class="run">Run</span>
          <div class="mt--3 text-white text-right">
            <span style="font-size: 0.8em; font-weight:700; color:silver;">By <span class="text-white" style="font-weight: 1000; ">MobeHealth</span></span>
          </div>
        </div>
        <div class="col-12">

        </div>
      </div>


    </div>
    <a class="navbar-brand d-none d-md-block pt-0" routerLinkActive="active" [routerLink]="['/dashboard']">
      <img src="../../../assets/img/logo/RISC-logo.png" class="navbar-brand-img" alt="..." style="height: 150px; max-height: 100%; margin-bottom: -20px;">
    </a>
    <!-- User -->
    <ul class="nav align-items-center d-md-none">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link nav-link-icon" role="button" ngbDropdownToggle>
          <i class="ni ni-bell-55"></i>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)">Action</a>
          <a class="dropdown-item" href="javascript:void(0)">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
        </div>
      </li>
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="./assets/img/brand/favicon.png">
            </span>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <!-- <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/user-profile']" >
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a> -->
          <a (click)="changePasswordVisible=true" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>Change Password</span>
          </a>
          <a  (click)="supportClick()" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#!" (click)="doLogout()" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>
    </ul>
    <!-- Collapse -->
    <div class="collapse navbar-collapse"  [ngbCollapse]="isCollapsed" id="sidenav-collapse-main" style="background:#2f4358 !important;">
      <!-- Collapse header -->
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a  routerLinkActive="active" [routerLink]="['/dashboard']">
              <!-- <img src="https://rsicmohali.com/wp-content/uploads/2021/01/RISC-logo.png"> -->
              <span style="font-weight: 1000; font-size: 2em; color:white;">Health</span><span style="font-weight: 400; font-size: 2em; color:rgb(1, 219, 99);">Run</span>
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <!-- Navigation -->
      <ul class="navbar-nav">
          <li *ngFor="let menuItem of menuItems" class="{{(menuItem.class)?menuItem.class:''}} nav-item">
              <div *ngIf="menuItem.authType.includes(this.auth.getTokens()[1])">
                <a routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
                    <i *ngIf="menuItem.icon" class="ni {{menuItem.icon}}"></i>
                    <span style="white-space: nowrap;">{{menuItem.title}}</span>
                    <span *ngIf="menuItem.subPaths" class="caret w-100 text-right">
                      <i class="fas fa-caret-{{(menuItem.opened)?'up':'down'}}"></i>
                    </span>
                </a>

                <div class="sub-menu {{(!menuItem.opened)?'d-none':''}}">
                  <ul *ngIf="menuItem.subPaths"  class="navbar-nav">
                    <li *ngFor="let subItem of menuItem.subPaths" class="{{(subItem.class)?subItem.class:''}}  nav-item">
                      <a class="nav-link" [routerLink]="[subItem.path]" routerLinkActive="active2">
                        <i *ngIf="subItem.icon" class="ni {{subItem.icon}}"></i>
                        {{subItem.title}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
          </li>

      </ul>
      <!-- Divider -->
      <!-- <hr class="my-3"> -->

      <!-- Heading -->
      <!-- <h6 class="navbar-heading text-muted">Documentation</h6> -->

      <!-- Navigation -->
      <!-- <ul class="navbar-nav mb-md-3">
        <li class="nav-item">
          <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/tutorial">
            <i class="ni ni-spaceship"></i> Getting started
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/colors">
            <i class="ni ni-palette"></i> Foundation
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/alerts">
            <i class="ni ni-ui-04"></i> Components
          </a>
        </li>
      </ul> -->
    </div>
  </div>
</nav>
