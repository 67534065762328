import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  loading = false;
  users = undefined;
  username = undefined;
  password = undefined;
  type =  "";
  formError = false;
  constructor(public userService:UserService ) { }

  ngOnInit() {
    this.refreshUserList();
  }

  refreshUserList() {
    this.loading = true;
    this.userService.getUsers().then(users=>{
      this.loading = false;
      this.users = users.map(user=>{return {...user,edit:false,newPassword:undefined}});
      console.log(users);
    }).catch(error=>{
      this.loading = false;
      console.log("Cannot connect to server...")
    });
  }

  passwordInputHandler(e,user) {
    if(!user.newPassword || user.newPassword == "") return user["passwordError"] = true;
    else return user["passwordError"] = false;
  }

  changePassword(user) {
    
    if(!user.newPassword || user.newPassword == "") return user["passwordError"] = true;
    console.log({user});
    this.loading = true;
    this.userService.changePassword(user._id,user.newPassword).then(res=>{
      console.log(res);
      user.newPassword=undefined;
      user.edit=false;
      this.loading = false;
    })
    
  }

  deleteUser(id) {
    if(!confirm("Are you Sure?")) return;
    this.loading = true;
    this.userService.deleteUser(id).then(res=>{
      this.users = this.users.filter(user=>user._id !== id);
      this.loading = false;
    }).catch(error=>{
      this.loading = false;
    })
  }

  addUserInputChanged(e) {
    this.formError = false;
  }

  saveUser() {
    if(!this.username || this.username == "" || !this.password || this.password == "" || !this.type || this.type == "") return this.formError = true;
    this.loading = true;
    this.userService.postUser({username:this.username, password:this.password, type:this.type}).then(user=>{
      if(this.users) this.users.push(user);
      else this.users = [user];
      this.username = undefined;
      this.password = undefined;
      this.type = 'admin';
      this.loading = false;
    })
  };

}
