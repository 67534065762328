<div class="my-3">
  <div *ngIf="loading"
    style=" min-height: 300px;background-color: silver; display: flex; justify-content: center; align-items: center;">
    <span style="color: #333">Loading...</span>
  </div>

  <div *ngIf="!loading" class="row no-gutters">
    <div *ngFor="let entry of entries" class="col-12">
      <div class="row no-gutters mt-2 py-2 px-3 align-items-center" style="background-color: rgb(216, 216, 216)">
        <div class="col-auto" style="font-size: 0.9em; font-weight: 700; color: rgb(37, 37, 37)">
          {{entry.createdAt | date:'mediumDate'}}
        </div>
        <div class="col ml-2" style="font-size: 0.9em; font-weight: 900; color: rgb(37, 37, 37)">
          OPD
        </div>
        <div *ngIf="!entry.purpose" class="col-auto">
          <button class="btn btn-sm btn-secondary">Mark Purpose</button>
        </div>
      </div>
      <div class="mb-2 py-2 px-3" style="background-color: rgb(240, 240, 240)">
        <span class=" pb-2 mb-0 d-block" style="font-size:0.9em" *ngIf="entry.purpose"><span class="text-primary"
            style="font-weight: 700; ">Purpose of Visit -&nbsp;</span><span></span>{{entry.purpose}}</span>
        {{entry.transaction_id.createdAt | date:'mediumTime' }} - Rs. 50 cash transaction (<a href="">Download
          Receipt</a>)<br />
        {{entry.createdAt | date:'mediumTime' }} - Token Generated
      </div>
    </div>
  </div>
</div>