<app-modal [visible]="visible" (onClose)="modalClose()" [closeDisabled]="loading" title="Change User Password" bodyColor="#EEE">
    <div>
        <h6 style="margin-top: -0.75rem; margin-bottom: 0.5rem; margin-left: -0.5rem;" class="heading-small">Change Password</h6>
        <div class="form-group">
            <label for="password" class="form-control-label">Enter New Password</label>
            <input class="form-control form-control-alternative" type="password" [(ngModel)]="fields.password" (ngModelChange)="validateField('password')"  name="password" />
            <div *ngIf="errors['password']" class="alert text-danger"> {{errors['password']}}</div>
        </div>
        <div class="form-group">
            <label for="confirmPassword" class="form-control-label">Confirm New Password</label>
            <input class="form-control form-control-alternative" type="password" [(ngModel)]="fields.confirmPassword" (ngModelChange)="validateField('confirmPassword')"  name="password" />
            <div *ngIf="errors['confirmPassword']" class="alert text-danger"> {{errors['confirmPassword']}}</div>
        </div>
    </div>
    <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;"/>
    <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
        <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
        <button [disabled]="loading" class="d-block ml-auto btn btn-success btn-sm" (click)="changePassword()">Change Password</button>
    </div>
</app-modal>
