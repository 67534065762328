import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PatientService } from "../../patient.service";

@Component({
  selector: "app-imaging-confirm",
  templateUrl: "./imaging-confirm.component.html",
  styleUrls: ["./imaging-confirm.component.css"],
})
export class ImagingConfirmComponent implements OnInit {
  @Input() visible;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @Input() pData;

  errorMessage;
  transacting;
  errored;
  entry;

  adhaarNumber: string;
  email: string;
  pState: string;
  ispostPay: number;
  isDisableButton: boolean = true;
  isShowRegistration: boolean;
  isDisabled: boolean = false;
  isState: boolean = false;
  isEmail: boolean = false;

  constructor(private appService: PatientService) {}

  ngOnInit(): void {
    if (this.pData !== undefined && this.pData !== null && this.pData !== "") {
      if (
        (this.pData.AadharCardNumber !== undefined && this.pData.AadharCardNumber !== null) ||
        (this.pData.email !== undefined && this.pData.email !== null) ||
        (this.pData.state !== undefined && this.pData.state !== null) ||
        (this.pData.isPatPay !== undefined && this.pData.isPatPay !== null)
      ) {
        this.adhaarNumber = this.pData.AadharCardNumber;
        this.email = this.pData.email;
        this.pState = this.pData.state;
        this.ispostPay = 0;//this.pData.isPatPay;

        this.isDisabled =
          this.pData.AadharCardNumber !== undefined &&
          this.pData.AadharCardNumber !== null &&
          this.pData.AadharCardNumber !== "";

        this.isState =
          this.pData.state !== undefined &&
          this.pData.state !== null &&
          this.pData.state !== "";

        this.isEmail =
          this.pData.email !== undefined &&
          this.pData.email !== null &&
          this.pData.email !== "";

      } else {
        this.mobehealthRegistration();
      }
    }
  }

  successHandler() {
    this.onSuccess.emit();
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.onClose.emit();
  }

  validateField(evt) {
    if (evt.target.value !== "") {
      this.pState = evt.target.value;
      this.isDisableButton = false;
    } else this.isDisableButton = true;
  }

  async mobehealthRegistration() {
    if (
      this.email !== undefined ||
      this.adhaarNumber !== undefined ||
      this.pState !== undefined //||
      //this.ispostPay !== undefined
    ) {
      this.pData.email = this.email;
      this.pData.AadharCardNumber = this.adhaarNumber;
      this.pData.state = this.pState;
      this.pData.isPatPay = 0

      await this.appService
        .updatePatient(this.pData, this.pData._id)
        .then((res: any) => {
          if (res !== null && res !== undefined && res !== "") {
            console.log("Update Patient", res);
            this.onSuccess.emit(res);
            this.closeHandler();
          }
        });
    } else if (
      this.pData.email == undefined ||
      this.pData.AadharCardNumber == undefined ||
      this.pData.state == undefined ||
      this.pData.isPatPay == undefined
    ) {
      this.isShowRegistration = true;
    }
    //   this.pData.email = this.email;
    // }
    // if (this.pData.AadharCardNumber == undefined) {
    //   this.pData.AadharCardNumber = this.adhaarNumber;
    // }
    // if (this.pData.state == undefined) {
    //   this.pData.state = this.pState;
    // }
    // if (this.pData.isPatPay == undefined) {
    //   this.pData.isPatPay = this.ispostPay;
    // }
    // else {
    //   this.isShowRegistration = false;
    //   if((this.adhaarNumber!==undefined && this.adhaarNumber!==null) || (this.email!==undefined && this.email!==null)
    //   || (this.pState !==) )

    // }
  }
}
