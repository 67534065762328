import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-notification',
  templateUrl: './confirm-notification.component.html',
  styleUrls: ['./confirm-notification.component.css']
})
export class ConfirmNotificationComponent implements OnInit {
  @Input() visible;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @Input() appointment;

  notificationMessageEng = '<p>It is mandatory to ensure that the Patient Name, Aadhaar Card, Phone number, Confirmation SMS and appointment number be presented at reception to confirm your appointment.<p> <p>All information has to match to get the token number. Failure to do so will lead to cancellation of the appointment and no refund will be made.</p><p>Anyone found indulged in unfair practices such as bribery, involving a 3rd party or an agent to book an appointment on the behalf of the patient will not be tolerated.  A legal action will be taken against them.</p><p>Click on "I agree to make payment and book your appointment"</p>';
  notificationMessageHin = '<p>यह सुनिश्चित करना अनिवार्य है कि आपकी अपॉइंटमेंट की पुष्टि के लिए रोगी का नाम, आधार कार्ड, फोन नंबर, पुष्टिकरण एसएमएस और अपॉइंटमेंट संख्या रिसेप्शन पर प्रस्तुत की जाए। टोकन नंबर प्राप्त करने के लिए सभी सूचनाओं का मिलान करना होगा। ऐसा नहीं करने पर अपॉइंटमेंट रद्द कर दी जाएगी और कोई धनवापसी नहीं की जाएगी।<p> <p> टोकन नंबर प्राप्त करने के लिए सभी सूचनाओं का मिलान करना होगा। ऐसा नहीं करने पर अपॉइंटमेंट रद्द कर दी जाएगी और कोई धनवापसी नहीं की जाएगी।</p><p>यदि किसी को भी रिश्वतखोरी जैसी अनुचित प्रथाओं में लिप्त पाया जाता है, जैसे रोगी की ओर से अपॉइंटमेंट बुक करने के लिए किसी तीसरे पक्ष या एजेंट को शामिल करना, स्टाफ को रिश्वत देना, उनके खिलाफ कानूनी कार्रवाई की जाएगी।</p>';
  notificationMessagePun = '<p>ਇਹ ਯਕੀਨੀ ਬਣਾਉਣਾ ਲਾਜ਼ਮੀ ਹੈ ਕਿ ਤੁਹਾਡੀ ਮੁਲਾਕਾਤ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਰਿਸੈਪਸ਼ਨ \'ਤੇ ਮਰੀਜ਼ ਦਾ ਨਾਮ, ਆਧਾਰ ਕਾਰਡ, ਫ਼ੋਨ ਨੰਬਰ, ਪੁਸ਼ਟੀਕਰਨ SMS ਅਤੇ ਅਪਾਇੰਟਮੈਂਟ ਨੰਬਰ ਪੇਸ਼ ਕੀਤਾ ਜਾਏ ।</p><p>ਟੋਕਨ ਨੰਬਰ ਲੈਣ ਲਈ ਸਾਰੀ ਜਾਣਕਾਰੀ ਦਾ ਮੇਲ ਕਰਨਾ ਹੋਵੇਗਾ। ਅਜਿਹਾ ਕਰਨ ਵਿੱਚ ਅਸਫਲ ਰਹਿਣ ਦੇ ਨਤੀਜੇ ਵਜੋਂ ਅਪਾਇੰਟਮੈਂਟ ਨੂੰ ਰੱਦ ਕਰ ਦਿੱਤਾ ਜਾਵੇਗਾ ਅਤੇ ਕੋਈ ਰਿਫੰਡ ਨਹੀਂ ਕੀਤਾ ਜਾਵੇਗਾ।</p><p>ਜੇਕਰ ਕੋਈ ਵਿਅਕਤੀ ਰਿਸ਼ਵਤਖੋਰੀ, ਮਰੀਜ਼ ਦੀ ਤਰਫੋਂ ਅਪਾਇੰਟਮੈਂਟ ਬੁੱਕ ਕਰਨ ਲਈ ਕਿਸੇ ਤੀਜੀ ਧਿਰ ਜਾਂ ਏਜੰਟ ਨੂੰ ਸ਼ਾਮਲ ਕਰਨ, ਸਟਾਫ ਨੂੰ ਰਿਸ਼ਵਤ ਦੇਣ ਵਰਗੇ ਅਣਚਿਤ ਅਭਿਆਸਾਂ ਵਿੱਚ ਸ਼ਾਮਲ ਪਾਇਆ ਜਾਂਦਾ ਹੈ, ਤਾਂ ਉਸ ਵਿਰੁੱਧ ਕਨੂਨੀ ਕਾਰਵਾਈ ਕੀਤੀ ਜਾਵੇਗੀ।</p><p>ਅੱਗੇ ਵਧਣ ਲਈ "ਮੈਂ ਨਿਯਮਾਂ ਅਤੇ ਸ਼ਰਤਾਂ ਨਾਲ ਸਹਿਮਤ ਹਾਂ" ਤੇ ਕਲਿੱਕ ਕਰੋ</p><p>ਮੈਂ ਸਹਿਮਤ ਹਾਂ</p>';

  notificationMessage;
  errored;
  entry;
  chkAcceptTerms : boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(this.appointment.language.toLowerCase() == "english"){
      this.notificationMessage = this.notificationMessageEng;
    }else if(this.appointment.language.toLowerCase() == "hindi"){
      this.notificationMessage = this.notificationMessageHin;
    }else{
      this.notificationMessage = this.notificationMessagePun;
    }
  }

  successHandler() {
    this.onSuccess.emit();
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.onClose.emit();
  }

}
