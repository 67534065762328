import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { TokenService } from "src/app/token.service";

@Component({
  selector: "app-live-opd",
  templateUrl: "./live-opd.component.html",
  styleUrls: ["./live-opd.component.css"],
})
export class LiveOpdComponent implements OnInit, OnDestroy {
  patient_id;
  current_token_loading;
  current_token;
  next_tokens = [];
  next_token_loading = false;
  transacting = false;
  loading = false;
  interval;
  defferOpen = false;
  isPendingButton = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id) this.patient_id = params.id;
    });

    this.refreshCurrentToken();
    this.refreshNextTokens();
    this.interval = setInterval(() => {
      this.silentRefreshNextTokens();
    }, 10000);
  }

  ngOnDestroy() {
    if (this.interval) clearInterval(this.interval);
  }

  async refreshCurrentToken() {
    this.current_token_loading = true;
    this.current_token = await this.tokenService.getActiveToken();

    if (this.current_token && this.current_token.patient._id) {
      this.patient_id = this.current_token.patient._id;
      this.router.navigate([`/live-opd/${this.patient_id}/visits`], {
        relativeTo: this.route,
      });
    }

    this.current_token_loading = false;
  }

  async silentRefreshNextTokens() {
    this.next_tokens = await this.tokenService.getNextTokens(8);
  }

  async refreshNextTokens() {
    this.next_token_loading = true;
    this.next_tokens = await this.tokenService.getNextTokens(8);
    this.next_token_loading = false;
  }

  async startOPD() {
    this.transacting = true;
    let token = await this.tokenService.startOPD();
    if (token) this.router.navigate["/live-opd/" + token.patient_id];

    this.refreshCurrentToken();
    this.refreshNextTokens();
    this.transacting = false;
  }

  async completeVisit() {
    this.transacting = true;
    let token = await this.tokenService.completeVisit();
    this.refreshCurrentToken();
    //this.refreshNextTokens();
    const audio = new Audio(
      "../../../assets/opd-ringtone/Beep-Censor-Sound.mp3"
    );
    audio.play();

    this.transacting = false;
  }

  async stopOPD() {
    this.transacting = true;
    let token = await this.tokenService.stopOPD();

    if (token) {
      this.router.navigate([`/live-opd/`], {
        relativeTo: this.route,
      });
    }

    // this.refreshCurrentToken();
    // this.refreshNextTokens();
    this.transacting = false;
  }

  async pendingOPD() {
    this.transacting = true;
    let token = await this.tokenService.pendingOPD();
    this.refreshCurrentToken();
    this.refreshNextTokens();
    this.transacting = false;
    if (this.refreshNextTokens.length == 1) this.isPendingButton = true;
    else this.isPendingButton = false;
  }
}
