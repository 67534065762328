<div class="header pb-5 pt-5 pt-lg-7 d-flex align-items-center">
  <!-- Mask -->
  <span class="mask bg-gradient-success opacity-8"></span>
  <!-- Header container -->
  <div class="container-fluid d-flex align-items-center">
    <div class="row">
      <div class=" col-12">
        <button class="btn btn-sm btn-primary mb-2" (click)="location.back()">Go Back</button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--7">
  <div class="row">
    <div class="col-12" style="margin-top: 60px;">
      <div class="card shadow-0 border">
        <div class="card-header">
          <h3>Edit Appointments</h3>
        </div>
        <div class="p-3 row align-items-center">
          <div class="col">
            <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control"
              placeholder="Search by patient name, phone number, email or Appointment No.">
          </div>
          <div *ngIf="search" (click)="search = undefined" class="col-auto">
            <button class="btn btn-warning ml-2 mt--1">Cancel</button>
          </div>
        </div>
        <div class="p-3">
          <div class="row">
            <div class="col-auto">
              <label for="fromDate" class="form-control-label">From:</label>
              <input type="date" name="fromDate" [(ngModel)]="from" (ngModelChange)="refreshAppointments()"
                class="form-control form-control-alternative" />
            </div>
            <div class="col-auto">
              <label for="toDate" class="form-control-label">To:</label>
              <input type="date" name="toDate" [(ngModel)]="to" (ngModelChange)="refreshAppointments()"
                class="form-control form-control-alternative" />
            </div>
          </div>
        </div>
        <div class="card-footer py-4">
          <div *ngIf="loading"
            style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
            <span style="color:#333">Loading...</span>
          </div>
          <div class="alert alert-success" *ngIf="successMessage">
            {{message}}
          </div>
          <div *ngIf="!loading" class="w-100" style="overflow-x:auto">
            <table class="table" style="overflow-x: scroll;">
              <thead class="thead-dark">
                <tr>
                  <th class="sticky-col second-col">Date</th>
                  <th class="sticky-col third-col">App. Number</th>
                  <th>Patient Name</th>
                  <!-- <th>Date of Birth</th> -->
                  <th>Mobile Number</th>
                  <th>Transaction ID</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let appointment of appointments">
                  <td class="sticky-col second-col">{{appointment.date | date:'mediumDate'}}</td>
                  <td class="sticky-col third-col">{{appointment.appointmentNumber}}</td>
                  <td>{{appointment.title}} {{appointment.firstName}} {{appointment.lastName}}</td>
                  <!-- <td>{{appointment.dob | date:'mediumDate'}}</td> -->
                  <td>{{appointment.phoneNumber}}</td>
                  <td>{{appointment.trackingId}}</td>
                  <td>
                    <select class="form-control form-control-alternative" [(ngModel)]="appointment.status"
                      style="width:120px;" (ngModelChange)="onChange($event)">
                      <option *ngFor="let c of ddStatus" [ngValue]="c.value">{{c.name}}</option>
                    </select>
                  </td>
                  <td>
                    <!-- [disabled]="!buttonDisabled" -->
                    <button id='{{appointment._id}}' class="ml-2 mr-0 btn btn-success btn-sm" (click)="onClick($event)">
                      Update Status
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="appointments.length < 1"
            style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
            No Appointments
          </div>

          <nav aria-label="...">
            <ul *ngIf="page.pages && page.pages.length" class="pagination mb-0" style="float: right;">
              <li class="page-item {{(page.currentPage===1)?'disabled':''}}">
                <a class="page-link" (click)="setPage(1)">
                  <i class="fas fa-angle-left"></i>
                  <span class="sr-only">Previous</span>
                </a>
              </li>

              <li *ngFor="let num of page.pages" class="page-item {{page.currentPage == num ? 'active': ''}}">
                <a class="page-link" (click)="setPage(num)">{{num}}</a>
              </li>

              <li class="page-item {{(page.currentPage === page.totalPages)?'disabled':''}}">
                <a class="page-link" (click)="setPage(page.totalPages)">
                  <i class="fas fa-angle-right"></i>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
