import { Component, OnInit } from '@angular/core';
import { AppointmentsService } from 'src/app/appointments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-all-appointments',
  templateUrl: './all-appointments.component.html',
  styleUrls: ['./all-appointments.component.css']
})
export class AllAppointmentsComponent implements OnInit {

  dbAppointments = undefined;
  appointments = undefined;
  page_no:Number = 0;
  offset;
  pages;
  total;
  loading = false;
  page_numbers = [];
  visible = false;
  selectedAppointment;
  selectedPatient;
  opd_entry_visible = false;
  search;
  page: any = {};
  totalRecords: number

  constructor(public appointmentService:AppointmentsService, public route:ActivatedRoute, public router:Router, public location:Location) { }

  async refreshAppointments() {
    this.loading = true;
    console.log({page_no:this.page_no});
    let res = await this.appointmentService.getAppointments(this.page_no)
    this.dbAppointments = res.appointments;

    this.page_no = Number(res.page);
    // this.pages = res.pages;
    // this.page_numbers = new Array(this.pages).fill(1).map((v,i)=>(i+1));
    // this.total = res;
    // this.offset = res.offset;

    this.totalRecords = res.total;
    this.setPage(1);

    if(res.pages < this.page_no)
      this.router.navigate(['/all-appointments/'+ res.pages]);

      this.appointments = this.dbAppointments;
      this.loading =false;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      if(!params.page_no) this.router.navigate['/transactions/1']
      this.page_no = Number(params["page_no"]);
      if(!this.page_no) this.location.back();
      this.refreshAppointments();
    })
  }

  linkPatient(app) {
    this.selectedAppointment = app;
    this.visible =true;
  }

  onSuccess() {
    this.refreshAppointments();
  }

  openOpdEntry(app,patient) {
    this.selectedAppointment = app
    this.selectedPatient = app.patient;
    this.opd_entry_visible = true;
  }
  onEntryClose() {
    this.selectedAppointment = undefined
    this.selectedPatient = undefined;
    this.opd_entry_visible = false;
  }

  async searchHandler(e) {
    console.log(e);
    if(!e || e=="" ) {
       this.appointments = this.dbAppointments;
       this.page_numbers = new Array(this.pages).fill(1).map((v,i)=>(i+1));
       return;
    }
    this.page_numbers = []
    this.appointments = [];
    this.page_no;
    this.loading = true;
    this.appointments = await this.appointmentService.searchAppointment(e);
    this.loading = false;
  }

  async setPage(page: number) {
    this.page = this.getPage(this.totalRecords, page);
    let res = await this.appointmentService.getAppointments(page);
    this.appointments = res.appointments;
  }

  getPage(totalItems: number, currentPage: number = 1, pageSize: number = 8) {
    // calculate total pages
    if (totalItems<=0 || typeof(totalItems) == 'undefined')
    totalItems=1;
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 8) {
        // less than 8 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 4) {
            startPage = 1;
            endPage = 8;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 7;
            endPage = totalPages;
        } else {
            startPage = currentPage - 3;
            endPage = currentPage + 4;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }

}
