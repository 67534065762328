import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AuthService } from "src/app/auth.service";
import { Location, DatePipe } from "@angular/common";
import { TransactionsService } from "src/app/transactions.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientService } from "src/app/patient.service";
import Papa from "papaparse";
import { startCase } from "lodash";

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"],
})
export class TransactionsComponent implements OnInit {
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  transactions = undefined;
  objectKeys = Object.keys;
  from = "";
  to = "";
  page_no: Number = 0;
  offset;
  pages;
  total;
  page_numbers = [];
  patient_id;
  patient;
  refundModalOpen = false;
  selectedTransaction;
  createBillVisible;
  exporting = false;
  showModal = false;
  order_status: any;

  startCase = startCase;
  viewModalOpen = false;
  items;
  tracking_id:any;

  constructor(
    public auth: AuthService,
    public location: Location,
    public router: Router,
    public transactionsService: TransactionsService,
    public route: ActivatedRoute,
    public patientService: PatientService
  ) {
    this.route.params.subscribe((params) => {
      if (params.order_no) {
        let currentURL = window.atob(params.order_no);
        this.order_status = currentURL.split('/')[1];
        this.tracking_id = currentURL.split('/')[2];
        this.showModal = true;
      }
    });
  }

  view(items) {
    this.items = items;
    this.viewModalOpen = true;
  }

  refund(transaction) {
    this.selectedTransaction = transaction;
    this.refundModalOpen = true;
  }

  ngOnInit(): void {
    let from = new Date();
    let to = new Date();
    let datePipe = new DatePipe("en-US");
    from.setDate(1);
    from.setHours(0, 0, 0, 0);
    to.setMonth(+to.getMonth() + 1);
    to.setHours(0, 0, 0, 0);
    to.setDate(0);
    this.to = datePipe.transform(to, "yyyy-MM-dd");
    this.from = datePipe.transform(from, "yyyy-MM-dd");

    this.route.parent.params.subscribe((params) => {
      this.patient_id = params.id;
      this.refreshTransactions();
    });

    this.route.params.subscribe((params) => {
      if (!params.page_no)
        this.router.navigate["/patient/" + this.patient_id + "/transactions/1"];
      this.page_no = Number(params["page_no"]);
      if (!this.page_no) this.location.back();
      this.refreshTransactions();
    });
  }

  refreshTransactions() {
    this.transactions = undefined;

    this.patientService.getPatient(this.patient_id).then((res) => {
      this.patient = res;
    });

    this.patientService
      .getAllTransactionsByPatientID(this.patient_id, this.page_no)
      .then((res) => {
        this.transactions = res.transactions;
        this.page_no = Number(res.page);
        this.pages = res.pages;
        this.page_numbers = new Array(this.pages).fill(1).map((v, i) => i + 1);
        this.total = res;
        this.offset = res.offset;

        if (res.pages < this.page_no)
          this.router.navigate(["/transactions/" + res.pages]);
      });
  }

  async export() {
    this.exporting = true;
    let transactions = await this.transactionsService.getAllTransactionsByDate(
      this.to,
      this.from
    );
    let datePipe = new DatePipe("en-US");

    let fields = [
      "Date",
      "Time",
      "Type",
      "For",
      "Patient",
      "Mode of Payment",
      "Initiated By",
      "Amount",
      "Opening Balance",
      "Closing Balance",
    ];

    let data = transactions.map((transaction) => {
      let dateTime = new Date(transaction.createdAt);
      let formatedDate = datePipe.transform(dateTime, "mediumDate");
      let formatedTime = datePipe.transform(dateTime, "mediumTime");
      let _for =
        transaction.name == "other" && transaction.items[0]
          ? transaction.items[0].name
          : startCase(transaction.name);
      let name =
        transaction.patient_id.title +
        " " +
        transaction.patient_id.firstName +
        " " +
        transaction.patient_id.lastName;

      return [
        formatedDate,
        formatedTime,
        transaction.type.toUpperCase(),
        _for,
        name,
        transaction.mode_of_payment.toUpperCase(),
        transaction.initiated_by.username,
        transaction.amount == 0 ? "Poor Free" : transaction.amount,
        transaction.openingBalance,
        transaction.closingBalance,
      ];
    });

    let exportData = {
      fields,
      data,
    };

    let csv = Papa.unparse(exportData, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
      columns: null,
    });
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "data.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.exporting = false;
  }

  closeHandler() {
    this.showModal = false;
    this.onClose.emit();
  }
}
