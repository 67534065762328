import { Component, OnInit, OnDestroy, Input, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientService } from "src/app/patient.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-imaging",
  templateUrl: "./imaging.component.html",
  styleUrls: ["./imaging.component.css"],
})
export class ImagingComponent implements OnInit, OnDestroy {
  @Input() pData;

  patient_crNumber;
  patient_id;
  loading;
  patient: any = [];
  isPatPay: boolean;
  href: string;
  btnUpload: boolean = false;
  refData: any;
  stopListening: Function;
  patRefId: any;
  reports: [];
  hospitalRefId: string = "272020118";
  visible: boolean = false;
  transacting: boolean = false;
  errorMessage;
  patPay: boolean;
  errored: boolean;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public patientService: PatientService,
    public http: HttpClient,
    private renderer: Renderer2
  ) {
    this.stopListening = renderer.listen(
      "window",
      "message",
      this.handleMessage.bind(this)
    );
  }

  handleMessage(event: Event) {
    //debugger;
    const message = event as MessageEvent;
    if (message.data) {
      let d = JSON.parse(message.data);
      if (d && d.reports && d.patRefId) {
        this.patRefId = d.patRefId;
        this.reports = d.reports;
      }
    }
  }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => {
      this.patient_id = params.id;
      this.refreshVisits();
    });
  }

  ngOnDestroy(): void {
    this.stopListening();
  }

  patientFName: string;
  patientLName: string;
  patientDOB: string;
  async refreshVisits() {
    //debugger;
    this.loading = true;
    this.patientService.getPatient(this.patient_id).then(async (res) => {
      this.patient_crNumber = res.crNumber;
      this.patPay = res.isPatPay;
      this.patientFName = res.firstName;
      this.patientLName = res.lastName;
      this.patientDOB = res.dob;

      this.patient = res;
      this.mriRegistration(res);
    });
    this.loading = false;
  }

  phpRegisterForMRI(patientData) {
    let genVal;
    if (patientData.gender == "M") {
      genVal = "Male";
    } else if (patientData.gender == "F") {
      genVal = "Female";
    } else if (patientData.gender == "T") {
      genVal = "Transexual";
    } else {
      genVal = "Other";
    }

    var mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST";
    mapForm.action = "https://app.mobehealth.com/register/index.php";

    this.formObj("firstName", patientData.firstName, mapForm);
    this.formObj("lastName", patientData.lastName, mapForm);
    this.formObj("eMail", patientData.email, mapForm);
    this.formObj("isoDOB", patientData.dob, mapForm);
    this.formObj("phone", patientData.phoneNumber, mapForm);
    this.formObj(
      "bloodGroup",
      patientData.bloodGroup == "" ? "Not Known" : patientData.bloodGroup,
      mapForm
    );
    this.formObj(
      "address",
      patientData.line1 + " " + patientData.line2,
      mapForm
    );
    this.formObj("refId", this.hospitalRefId, mapForm);
    this.formObj("crNumber", patientData.crNumber, mapForm);
    this.formObj("gender", genVal, mapForm);
    this.formObj("password", this.generatePassword(8), mapForm);
    this.formObj("state", patientData.state, mapForm); //this.patient.state
    this.formObj("patpay", patientData.isPatPay == true ? 1 : 0, mapForm);
    this.formObj("aadharCardNumber", patientData.AadharCardNumber, mapForm);
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  uploadFile() {
    var mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST";
    mapForm.action =
      "https://app.mobehealth.com/hospitaladmin/dashboard/upload.php";

    this.patRefId =
      this.patRefId == undefined ? this.patient._id : this.patRefId;

    this.formObj("patrefid", this.patRefId, mapForm);
    this.formObj("uploadedby", "RSIC Mohali", mapForm);
    this.formObj("firstname", this.patientFName, mapForm);
    this.formObj("lastname", this.patientLName, mapForm);
    this.formObj("dob", this.patientDOB, mapForm);
    this.formObj("uploadbyrefid", this.hospitalRefId, mapForm);

    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  formObj(param, value, mapForm) {
    var mapInput = document.createElement("input");
    mapInput.type = "hidden";
    mapInput.name = param;
    mapInput.setAttribute("value", value);
    mapForm.appendChild(mapInput);
  }

  generatePassword(passwordLength) {
    var numberChars = "0123456789";
    var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowerChars = "abcdefghijklmnopqrstuvwxyz";
    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return this.shuffleArray(
      randPasswordArray.map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
    ).join("");
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  isShowRegistration: boolean;
  mriRegistration(pData) {
    if (
      pData.email == undefined ||
      pData.AadharCardNumber == undefined ||
      pData.state == undefined ||
      pData.isPatPay == undefined
    ) {
      this.isShowRegistration = true;
    } else {
      this.isShowRegistration = false;
      this.phpRegisterForMRI(pData);
    }
  }

  closeHandler() {
    this.isShowRegistration = false;
  }

  viewData(data) {
    var mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST";
    mapForm.action = "https://app.mobehealth.com/patient/share.php";

    this.formObj("id", data.id, mapForm);
    this.formObj("code", data.security, mapForm);
    this.formObj("uploadByRefId", this.hospitalRefId, mapForm);
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  patPayPopup() {
    this.visible = true;
  }

  showShareReportwithPatient: boolean;
  itemData: any;
  shareVisible: boolean;
  isShareWithPatient: boolean;

  sharedwithPatient(item) {
    this.isShareWithPatient = false;
    if (item.ShareWithPatient == "0") {
      this.showShareReportwithPatient = true;
    } else {
      this.showShareReportwithPatient = false;
    }

    this.shareVisible = true;
    this.itemData = item;
  }

  updatePhpPatientReport() {
    if (this.isShareWithPatient) {
      this.itemData.ShareWithPatient = "1";
    } else {
      this.itemData.ShareWithPatient = "0";
    }

    var mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST";
    mapForm.action =
      "https://app.mobehealth.com/hospitaladmin/ShareReportWithPatient.php";
    this.formObj("ReportId", this.itemData.id, mapForm);
    this.formObj("PatientRefId", this.patRefId, mapForm);
    this.formObj("RefId", this.hospitalRefId, mapForm);
    this.formObj("Status", this.itemData.ShareWithPatient, mapForm);
    document.body.appendChild(mapForm);
    mapForm.submit();
    this.shareVisible = false;
  }

  closePatPay() {
    this.visible = false;
  }

  closeSharePopUp() {
    this.shareVisible = false;
  }

  async savePatPay() {
    this.transacting = true;
    var data = {
      isPatPay: this.isPatPay,
    };
    await this.patientService.updatePatPay(data, this.patient_id);
    this.transacting = false;
    this.visible = false;

    this.patientService.getPatient(this.patient_id).then(async (res) => {
      if (res.isPatPay) {
        this.patPay = res.isPatPay;
        this.phpRegisterForMRI(res);
      }
    });
  }
}
