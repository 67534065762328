import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {baseUrl} from './config';

@Injectable({
  providedIn: 'root'
})
export class ApprovalsService {

  
  constructor(private httpClient: HttpClient) { }
  
  getHeaders() {
    return {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}};
  }

  async requestClearTill(amount) {
    let res:any = await this.httpClient.post(baseUrl + "approvals/me",{amount,type:'clear_till'},{...this.getHeaders()}).toPromise()
    return res;
  }

  async requestRefund(amount,patient_id) {
    let res:any = await this.httpClient.post(baseUrl + "approvals/me",{amount,type:'refund',patient:patient_id},{...this.getHeaders()}).toPromise()
    return res;
  }

  async getSelfApprovals() {
    let res:any = await this.httpClient.get(baseUrl + "approvals/me",{...this.getHeaders()}).toPromise()
    return res;
  }

  async getPendingApprovals() {
    let res:any = await this.httpClient.get(baseUrl + "pending-approvals",{...this.getHeaders()}).toPromise()
    return res;
  }

  async proccessApproval(action,approval_id,password) {
    let res:any = await this.httpClient.post(baseUrl + `pending-approvals/${approval_id}/${action}`,{password},this.getHeaders()).toPromise();
    return res;
  }

  async markSeen(approval_id) {
    let res:any = await this.httpClient.post(baseUrl + `approvals/me/${approval_id}/seen`,{},this.getHeaders()).toPromise();
    return res;
    
  }

}
