<app-default-header></app-default-header>
<div class="container-fluid mt-4">
  <div class="row">
    <div class="col">
      <div class="card shadow border-0">
        <div class="card-header">
          <div class="row no-gutters align-items-center justify-content-between">
            <div class="col-auto">
              <h2>HealthRun Settings</h2>
            </div>
            <div class="col-auto"></div>
          </div>

        </div>
        <div class="card-body" style="background-color: rgb(253, 253, 253);">
          <h4 class="mb-3">Token Settings</h4>
          <div class="row mb-3 align-items-center no-gutters">
            <div class="col-4 col-xl-3 p-2 setting-text">
              Walkin Token After
            </div>
            <div class="col p-2">
              <input [disabled]="loading" type="text" [(ngModel)]="settings.general.appointment_token_after"
                class="form-control form-control-sm form-control-alternative" />
              <span class="text-muted text-sm">Number of other tokens after which appointment token will be
                called.</span>
            </div>
          </div>
          <hr />
          <h4 class="mt-3 mb-3">Appointment Settings</h4>
          <div class="row mb-3 align-items-center no-gutters">
            <div class="col-4 col-xl-3  p-2 setting-text">
              Weekday Appointments per Day:
            </div>
            <div class="col p-2">
              <input [disabled]="loading" type="text" [(ngModel)]="settings.appointment.weekdayMaximumPerDay"
                class="form-control form-control-sm form-control-alternative" />
              <span class="text-muted text-sm">Number of Appointment Slots for weekday OPD.</span>
            </div>
          </div>
          <div class="row mb-3 align-items-center no-gutters">
            <div class="col-4 col-xl-3  p-2  setting-text">
              Weekend Appointments per Day:
            </div>
            <div class="col p-2">
              <input type="text" [disabled]="loading" [(ngModel)]="settings.appointment.weekendMaximumPerDay"
                class="form-control form-control-sm form-control-alternative" />
              <span class="text-muted text-sm">Number of Appointment Slots for weekend OPD.</span>
            </div>
          </div>
          <hr />
          <h4 class="mt-3 mb-3">Excluded Appointment Days / Non OPD Days</h4>
          <div class="row mb-3 no-gutters ">
            <div class="col-4 col-xl-3  p-2 setting-text">
              Weekday OPD excluded dates:
            </div>
            <div class="col p-2">
              <div *ngIf="!loading">
                <span *ngFor="let i of settings.appointment.weekdayHolidays"
                  class="badge badge-pill badge-primary ml-1 mb-1">
                  {{i | date:'mediumDate'}} <a href="!" (click)="$event.preventDefault(); deleteExcludedWeekDay(i)"><i
                      class="fa fa-times ml-2 text-danger"></i></a>
                </span>
                <span *ngIf="settings.appointment.weekdayHolidays && settings.appointment.weekdayHolidays.length < 1">
                  No Dates Excluded. Please exclude a date.
                </span>
              </div>
              <div *ngIf="loading" class="setting-text">
                <i class="fa fa-spinner fa-spin"></i> Loading....
              </div>
            </div>

          </div>
          <div class="row mb-3 no-gutters">
            <div class="col-4 col-xl-3  p-2">
              Exclude Date from Weekday OPD:
            </div>
            <div class="col p-2">
              <mat-form-field appearance="outline" (click)="picker1.open()" class="w-100">
                <input matInput [matDatepicker]="picker1" [matDatepickerFilter]="weekdayFilter" name="weekdayDate"
                  [(ngModel)]="excludedWeekdayDate" placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-auto p-2">
              <button [disabled]="loading" class="btn-sm btn-danger btn " (click)="excludeWeekdayDate()"><i
                  class="fa fa-plus mr-2"></i> Exclude</button>
            </div>
          </div>
          <div class="row mb-3 no-gutters ">
            <div class="col-4 col-xl-3  p-2 setting-text">
              Weekend OPD excluded dates:
            </div>
            <div class="col p-2">
              <div *ngIf="!loading">
                <span *ngFor="let i of settings.appointment.weekendHolidays"
                  class="badge badge-pill badge-primary ml-1 mb-1">
                  {{i | date:'mediumDate'}} <a href="!" (click)="$event.preventDefault(); deleteExcludedWeekEnd(i)"><i
                      class="fa fa-times ml-2 text-danger"></i></a>
                </span>
                <span *ngIf="settings.appointment.weekendHolidays && settings.appointment.weekendHolidays.length < 1">
                  No Dates Excluded. Please exclude a date.
                </span>
              </div>
              <div *ngIf="loading" class="setting-text">
                <i class="fa fa-spinner fa-spin"></i> Loading....
              </div>
            </div>
          </div>
          <div class="row mb-3 no-gutters">
            <div class="col-4 col-xl-3  p-2">
              Exclude Date from Weekend OPD:
            </div>
            <div class="col p-2">
              <mat-form-field appearance="outline" (click)="picker.open()" class="w-100">
                <input matInput [matDatepicker]="picker" [matDatepickerFilter]="weekendFilter" name="date"
                  [(ngModel)]="excludedWeekendDate" placeholder="Choose a date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-auto p-2">
              <button [disabled]="loading" class="btn-sm btn-danger btn " (click)="excludeWeekendDate()"><i
                  class="fa fa-plus mr-2"></i> Exclude</button>
            </div>
          </div>

          <div class="row mb-3 align-items-center no-gutters">
            <div class="col-4 col-xl-3  p-2 setting-text">
              Live Message:
            </div>
            <div class="col p-2">
              <input [disabled]="loading" type="text" [(ngModel)]="settings.liveMsg"
                class="form-control form-control-sm form-control-alternative"  />
            </div>
          </div>
          <hr />

          <h4 class="mb-3">Cost of OPD</h4>
          <div class="row mb-3 align-items-center no-gutters">
            <div class="col-4 col-xl-3 p-2 setting-text">
              Online OPD Charges:
            </div>
            <div class="col p-2">
              <input [disabled]="loading" type="number" [(ngModel)]="settings.opdCharges"
                  class="form-control form-control-sm form-control-alternative"  />
            </div>
          </div>
        </div>
        <div class="card-footer text-right">
          <button class="btn btn-danger" (click)="getSettings()"><i class="fa fa-times mr-2"></i>Cancel</button>
          <button class="btn btn-success" (click)="submit()"><i class="fa fa-save mr-2"></i>Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
