import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ComponentsModule } from '../components.module';

@Component({
  selector: 'app-view-transaction-item-modal',
  templateUrl: './view-transaction-item-modal.component.html',
  styleUrls: ['./view-transaction-item-modal.component.css']
})
export class ViewTransactionItemModalComponent implements OnInit,OnChanges {

  @Input() visible = false;
  @Output() onClose:EventEmitter<any> = new EventEmitter();
  @Input() items;
  total;
  closeHandler() {
    this.visible = false;
    
    this.onClose.emit();
  }

  ngOnChanges(changes:SimpleChanges) {
    if(!changes.item) return;
    this.total = changes.items.currentValue.reduce((acc,item)=>acc+item.amount,0)
  }

  constructor() { }

  ngOnInit(): void {
    this.total = this.items.reduce((acc,item)=>acc+item.amount,0)
  }

}
