import { Component, OnInit } from '@angular/core';
import { AppointmentsService } from 'src/app/appointments.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-today-appointments',
  templateUrl: './today-appointments.component.html',
  styleUrls: ['./today-appointments.component.css']
})
export class TodayAppointmentsComponent implements OnInit {
  
  dbAppointments = undefined;
  appointments;
  search;
  loading = false;
  _dp = new DatePipe('en-US');
  constructor(public appointmentsService:AppointmentsService) { }

  async refreshAppointments() {
    this.loading = true;
    let startDate,endDate;
    startDate = endDate = this._dp.transform(new Date(),'yyyy-MM-dd');
    this.dbAppointments = await this.appointmentsService.getByDate(startDate,endDate);
    this.appointments = this.dbAppointments;
    this.loading = false;
  }

  ngOnInit(): void {
    this.refreshAppointments();
  }

  async searchHandler(e) {
    this.appointments = [];

    if(!e || e=="" ) {
      return this.appointments = this.dbAppointments;
    }
    
    this.appointments = this.dbAppointments.filter(app=>{
      console.log(app);
      
      return app.appointmentNumber.toString().includes(e) || (app.firstName + "  " + app.lastName).includes(e)
    })
    
  
  }

}
