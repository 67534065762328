import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { startCase } from "lodash";
import { AppointmentsService } from "src/app/appointments.service";
import { PatientService } from "src/app/patient.service";
import { DatePipe } from "@angular/common";
import { SettingsService } from "src/app/settings.service";
import { Router, ActivatedRoute } from "@angular/router";
import { baseUrl } from "../../config";

const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const aadharRegEx = /^\+?[0-9](?:[- ]?[0-9]){9,11}$/;

@Component({
  selector: "app-book-appointment",
  templateUrl: "./book-appointment.component.html",
  styleUrls: ["./book-appointment.component.css"],
})
export class BookAppointmentComponent implements OnInit {
  @ViewChild("form") form: ElementRef;
  accessCode: any;
  encRequestRes: any;
  order_id: any;
  paymentAmount: any = "0";
  order_status: any;
  tracking_id: any;
  appointment_date: any;
  appointment_name: any;
  transaction_dateTime: any;
  paymentMode;
  errorMessage;
  aadharNumber: any;
  selectedAppointment: undefined;
  confirmAlert = false;
  orderNumber: string;

  //CR NUMBER VALIDATION
  edit: boolean;
  patientRealCR = undefined;
  checkingCR = false;
  crExists = false;
  crID;

  selectedAddress: any = {
    name: "test",
    address: "test address",
    city: "test city",
    pincode: "23456",
    state: "state test",
    phone: "1234567890",
  };

  tab = 0;
  bokkingType = "";
  isEnableTab_0 = true;
  isEnableTab_1 = false;
  isEnableTab_2 = false;
  isEnableTab_3 = false;
  isEnableTab_4 = false;
  isEnableTab_5 = false;
  selectedType = undefined;

  formData: any = { title: "", gender: "", bloodGroup: "", date: undefined };
  error = {};
  loading = false;
  main_error = { visible: false, message: "" };
  age;
  minDate: Date;
  maxDate: Date;

  fields = [
    "title",
    "firstName",
    "lastName",
    "gender",
    "dob",
    "phoneNumber",
    "date",
    "email",
    "language",
    "AadharCardNumber" /*,"line1","line2"*/,
  ];
  requiredFields = [
    "title",
    "firstName",
    "lastName",
    "gender",
    "dob",
    "phoneNumber",
    "date",
    "language",
    "AadharCardNumber" /*,"line1","line2"*/,
  ];

  appointmentNumber = "340993284423";
  excludedDates = [];
  dateSelectionDisabled = false;
  disabledField = true;
  available_slots;
  appointmentCount: number = 0;
  searchFields = ["appointmentNumber", "dob"];
  searchErrors = {};
  searchFormData = {};
  searchMainError = { visible: false, message: "" };
  searching = false;
  canceling = false;
  searched = false;
  searchedAppointment;
  settings;
  showBacktoWebsite = false;
  showPaymentMode = false;
  currentURL: string = "";
  appointmentDate;

  validateSearchField(selector) {
    if (
      this.searchFields.includes(selector) &&
      (!this.searchFormData[selector] || this.searchFormData[selector] == "")
    ) {
      this.searchErrors[selector] = startCase(selector) + " is Required!";
      return false;
    }

    this.searchMainError.visible = false;
    this.searchErrors[selector] = undefined;
    this.searchMainError.message = "";
    return true;
  }

  validateSearchForm() {
    let flag = true;
    for (let field of this.searchFields) {
      if (!this.validateSearchField(field)) flag = false;
    }
    return flag;
  }

  async search() {
    if (!this.validateSearchForm()) return false;

    this.searching = true;
    let result = await this.appointmentService.findAppointment(
      this.searchFormData["appointmentNumber"],
      this.searchFormData["dob"]
    );
    this.searching = false;

    if (!result) {
      this.searchMainError = {
        visible: true,
        message: "⚠ Appointment Not Found!",
      };
      return;
    }

    this.searched = true;
    this.searchedAppointment = result;
  }

  async cancel() {
    if (!confirm("Are you sure you want to cancel your OPD appointment?"))
      return;

    if (!this.searchedAppointment) {
      alert("Some Error occured!");
      this.close();
    }

    this.canceling = true;
    this.searchedAppointment = await this.appointmentService.cancelAppointment(
      this.searchedAppointment._id,
      this.searchedAppointment
    );
    this.canceling = false;
  }

  myFilter = (d: Date | null): boolean => {
    let date = new Date();
    const day = (d || date).getDay();

    for (let date1 of this.excludedDates) {
      if (date1.getTime() == (d || date).getTime()) return false;
    }

    if (this.selectedType == "general") return day !== 0 && day !== 6;
    if (this.selectedType == "special") return day == 6;
  };

  close() {
    if (this.router.url.replace("/", "") == "appointment") {
      this.router.navigate(["/appointment"]);
      this.showPaymentMode = false;
    } else {
      this.router.navigate(["/book-appointment"]);
      this.showPaymentMode = true;
    }

    this.formData = {
      title: "",
      gender: "",
      bloodGroup: "",
      date: undefined,
    };
    this.error = {};
    this.loading = false;
    this.main_error = {
      visible: false,
      message: "",
    };

    this.available_slots = undefined;
    this.age;
    //this.tab = 0;
    this.isEnableTab_0 = true;
    this.isEnableTab_1 = false;
    this.isEnableTab_2 = false;
    this.isEnableTab_3 = false;
    this.isEnableTab_4 = false;
    this.isEnableTab_5 = false;

    this.selectedType = undefined;
    this.searched = false;
    this.searching = false;
    this.searchFormData = {};
    this.searchErrors = {};
    this.searchMainError = {
      visible: false,
      message: "",
    };
  }

  public options = [
    { value: 1, id: "Through Cash" },
    { value: 2, id: "Through Net Banking" },
  ];

  constructor(
    public appointmentService: AppointmentsService,
    public settingService: SettingsService,
    public patientService: PatientService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      if (params.orderNumber) {
        this.currentURL = window.atob(params.orderNumber);
        this.order_id = this.currentURL.split("/")[0];
        this.order_status = this.currentURL.split("/")[1];
        this.tracking_id = this.currentURL.split("/")[2];
        this.appointmentDate = JSON.parse(
          localStorage.getItem("APPOINTMENT DATA")
        ).date.split("T")[0];
      }
    });

    this.myFilter = this.myFilter.bind(this);
    let _date = new Date();
    const currentYear = _date.getFullYear();
    const currentMonth = _date.getMonth();
    const currentDate = _date.getDate();
    this.minDate = new Date(currentYear, currentMonth, currentDate + 1);
    this.maxDate = new Date(currentYear + 1, currentMonth, currentDate);
  }

  validateField(selector) {
    if (
      this.requiredFields.includes(selector) &&
      (!this.formData[selector] || this.formData[selector] == "")
    ) {
      this.error[selector] = startCase(selector) + " is Required!";
      return false;
    }
    if (!this.customValidations(selector)) {
      this.error[selector] = `Please enter a valid ${startCase(selector)}`;
      return false;
    }
    this.main_error.visible = false;
    this.error[selector] = undefined;
    this.main_error.message = "";
    return true;
  }

  customValidations(selector) {
    if (selector == "dob") {
      let formDate = new Date(this.formData["dob"]);
      formDate.setHours(0, 0, 0, 0);
      let todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      let lowerLimt = new Date(1900, 0);
      if (todayDate < formDate || formDate < lowerLimt) return false;
      let b = new Date(todayDate.getTime() - formDate.getTime());
      let age = {
        years: b.getFullYear() - 1970,
        months: b.getMonth(),
      };

      this.age = `${age.years} Year${age.years > 1 ? "s" : ""}, ${
        age.months
      } Month${age.months > 1 ? "s" : ""}.`;
    }

    if (selector == "phoneNumber") {
      if (
        this.formData["phoneNumber"] < 4999999999 ||
        this.formData["phoneNumber"] > 9999999999
      )
        return false;
    }

    if (selector == "email") {
      let email = this.formData["email"];
      if (!email || email == "") return true;
      this.formData["email"] = email.toLowerCase();
      return emailRegEx.test(email);
    }

    if (selector == "AadharCardNumber") {
      let aadhar = this.formData["AadharCardNumber"];
      var adharcardTwelveDigit = /^\d{12}$/;
      var adharSixteenDigit = /^\d{16}$/;

      if (aadhar != "") {
        if (aadhar.match(adharcardTwelveDigit)) {
          return true;
        } else if (aadhar.match(adharSixteenDigit)) {
          return true;
        } else {
          return false;
        }
      }
    }

    return true;
  }

  validateForm() {
    let flag = true;
    for (let field of this.fields) {
      if (!this.validateField(field)) flag = false;
    }
    return flag;
  }

  ngOnInit(): void {
    this.accessCode = "AVEY20II54AN98YENA";
    //this.orderNumber = Math.random().toString().substr(2, 12);
    this.getSettings();
    this.getPaymentResponse();

    if (this.router.url.replace("/", "") == "appointment") {
      this.showBacktoWebsite = true;
      this.showPaymentMode = false;
    } else {
      this.showPaymentMode = true;
    }
  }

  async getSettings() {
    this.loading = true;
    try {
      this.settings = await this.settingService.getLiveMessage();
      if (
        this.settings &&
        this.settings.appointment &&
        this.settings.appointment.weekdayHolidays
      ) {
        var excluded_weekday_OPDS =
          this.settings.appointment.weekdayHolidays.map((x) => new Date(x));
        this.excludedDates.push(...excluded_weekday_OPDS);
      }

      if (
        this.settings &&
        this.settings.appointment &&
        this.settings.appointment.weekendHolidays
      ) {
        var excluded_weekend_OPDS =
          this.settings.appointment.weekendHolidays.map((x) => new Date(x));
        this.excludedDates.push(...excluded_weekend_OPDS);
      }

      //get the payment amount from db
      if (this.settings && this.settings.opdCharges) {
        this.paymentAmount = this.settings.opdCharges;
      }
    } catch (e) {
      alert("Some Error Occured!");
    }

    this.loading = false;
  }

  async getExcludedDates(bookingType) {
    this.dateSelectionDisabled = true;
    this.excludedDates = await this.appointmentService.getClosedDates(
      bookingType
    );
    this.excludedDates = this.excludedDates.map((x) => new Date(x));
    for (let dates of this.excludedDates) {
      dates.setHours(0, 0, 0, 0);
    }
    this.dateSelectionDisabled = false;
  }

  async getPaymentResponse() {
    if (this.order_status == "Success" || this.order_status == "Shipped") {
      let result = await this.appointmentService.findAppointmentByNo(
        this.order_id
      );
      if (result) {
        this.selectedType = result.selectedType;
        this.appointment_date = result.date;
        this.appointment_name =
          result.title + " " + result.firstName + " " + result.lastName;
        this.transaction_dateTime = result.createdAt;
        this.aadharNumber = result.AadharCardNumber;

        this.main_error.visible = false;
        this.main_error.message = "";
        this.isEnableTab_2 = true;
        this.isEnableTab_0 = false;
        this.isEnableTab_1 = false;
        this.isEnableTab_3 = false;
        this.isEnableTab_4 = false;
        this.isEnableTab_5 = false;
        //this.tab = 2;
      }
    } else if (
      this.order_status === "Failure" ||
      this.order_status === "Aborted" ||
      this.order_status === "Invalid" ||
      this.order_status === "Timeout"
    ) {
      //this.tab = 4;
      this.isEnableTab_0 = false;
      this.isEnableTab_1 = false;
      this.isEnableTab_2 = false;
      this.isEnableTab_3 = false;
      this.isEnableTab_4 = true;
      this.isEnableTab_5 = false;
    }
  }

  async dateChanged() {
    this.dateSelectionDisabled = true;
    this.errorMesage = "";

    let check = await this.appointmentService.checkDate(
      this.formData["date"].getFullYear(),
      ("0" + (this.formData["date"].getMonth() + 1)).slice(-2),
      ("0" + this.formData["date"].getDate()).slice(-2),
      this.bokkingType
    );
    if (!check.check) {
      this.error["date"] = "Sorry, No slots available for selected date!";
      this.dateSelectionDisabled = false;
      this.available_slots = undefined;
      this.appointmentCount = 0;
      return;
    }

    this.error["date"] = undefined;

    let weekdayMaximumPerDay_count =
      this.settings.appointment.weekdayMaximumPerDay - check.count <= 0
        ? 0
        : this.settings.appointment.weekdayMaximumPerDay - check.count;

    let weekendMaximumPerDay_count =
      this.settings.appointment.weekendMaximumPerDay - check.count <= 0
        ? 0
        : this.settings.appointment.weekendMaximumPerDay - check.count;

    if (this.selectedType == "special") {
      this.available_slots = `Appointment Slots Available: ${weekendMaximumPerDay_count}`;
      this.appointmentCount = weekendMaximumPerDay_count;
    } else {
      this.available_slots = `Appointment Slots Available: ${weekdayMaximumPerDay_count}`;
      this.appointmentCount = weekdayMaximumPerDay_count;
    }
    this.dateSelectionDisabled = false;
  }

  async submit() {
    if (!this.validateForm()) {
      this.main_error.visible = true;
      this.main_error.message =
        "All Fields marked with asterisk(*) are required!";
      this.loading = false;
      return;
    }

    let datePipe = new DatePipe("en-US");
    this.formData["date"] = datePipe.transform(
      this.formData["date"],
      "yyyy-MM-dd"
    );

    let fullYear = this.formData["date"].slice(0, 4);
    let month = this.formData["date"].slice(5, 7);
    let date = this.formData["date"].slice(8, 10);

    let day = new Date(this.formData["date"]).getDay();
    if (this.selectedType == "general" && day == (0 || 6)) {
      this.error["date"] = "Invalid Date!";
      this.dateSelectionDisabled = false;
      this.available_slots = undefined;
      return;
    }

    if (this.selectedType == "special" && day != 6) {
      this.error["date"] = "Invalid Date!";
      this.dateSelectionDisabled = false;
      this.available_slots = undefined;
      return;
    }

    let check = await this.appointmentService.checkDate(
      fullYear,
      month,
      date,
      this.bokkingType
    );

    if (!check.check) return (this.formData["error"] = "Invalid Date!");
    this.loading = true;

    this.confirmAlert = true;
    this.selectedAppointment = this.formData;
  }

  errorMesage: string = "";
  async onSuccess() {
    this.formData.orderNumber = Math.random().toString().substr(2, 12);
    this.formData.paymentMode = this.paymentMode;

    // let response = await this.appointmentService.alreadyExistsBookingDate(
    //   this.formData.phoneNumber,
    //   this.formData.AadharCardNumber,
    //   this.formData.date
    // );

    // if (response.isExists && response.data.length > 0) {
    //   this.errorMesage = `The appointment with this <b>Phone Number -
    //   ${response.data[0].phoneNumber} </b> and
    //   <b>Adhaar Card - ${response.data[0].AadharCardNumber}</b>
    //   has already been booked for
    //   <b>Date - ${response.data[0].date.toString().split("T")[0]}</b> .
    //   Kindly use an alternative Phone number or Aadhaar Card to book an appointment on this date to avoid duplication.`;
    // } else {
    //this.errorMesage = "";
    let res: any = await this.appointmentService.bookAppointment({
      ...this.formData,
      selectedType: this.selectedType,
    });

    localStorage.setItem(
      "APPOINTMENT DATA",
      JSON.stringify(res.toSendAppointment)
    );

    this.appointmentDate = res.toSendAppointment.date;
    this.appointmentNumber = res.toSendAppointment.appointmentNumber;
    this.aadharNumber = res.toSendAppointment.AadharCardNumber;

    if (this.paymentMode === "netBanking") {
      //payment gateway
      let redirect_url = encodeURI(`${baseUrl}appointments/response`);
      let useremail = "support@his.rsicmohali.com";
      let orderNumber = res.toSendAppointment.orderNumber;

      this.selectedAddress.name =
        res.toSendAppointment.firstName + " " + res.toSendAppointment;
      let request = `merchant_id=488512&order_id=${orderNumber}&currency=INR&amount=${this.paymentAmount}&redirect_url=${redirect_url}&cancel_url=${redirect_url}&language=EN&billing_name=${this.selectedAddress.name}&billing_address=${this.selectedAddress.address}&billing_city=${this.selectedAddress.city}&billing_state=MH&billing_zip=${this.selectedAddress.pincode}&billing_country=India&billing_tel=${this.selectedAddress.phone}&delivery_name=${this.selectedAddress.name}&delivery_address=${this.selectedAddress.address}&delivery_city=${this.selectedAddress.city}&delivery_state=${this.selectedAddress.state}&delivery_zip=${this.selectedAddress.pincode}&delivery_country=India&delivery_tel=${this.selectedAddress.phone}&billing_email=${useremail}`;

      this.appointmentService.encryptdata(request).subscribe(
        (data) => {
          this.encRequestRes = data;
          setTimeout(() => {
            this.form.nativeElement.submit();
          }, 1000);
        },
        (error) => console.log(error)
      );
    } else {
      this.main_error.visible = false;
      this.main_error.message = "";
      this.isEnableTab_0 = false;
      this.isEnableTab_1 = false;
      this.isEnableTab_5 = true;
    }
    //}
  }

  enableTabs(bType) {
    this.isEnableTab_2 = false;
    this.isEnableTab_3 = false;
    this.isEnableTab_4 = false;
    this.isEnableTab_5 = false;

    if (!this.showPaymentMode) {
      this.showPaymentMode = true;
      this.paymentMode = "netBanking";
      this.bokkingType = bType;
      this.isEnableTab_0 = false;
      this.isEnableTab_1 = true;
    } else {
      if (!this.paymentMode) {
        this.isEnableTab_0 = true;
        this.errorMessage = "Please select mode of payment";
        return false;
      } else {
        this.bokkingType = bType;
        this.isEnableTab_0 = false;
        this.isEnableTab_1 = true;
      }
    }

    this.getExcludedDates(bType);
  }

  cancelCheckAppointment() {
    this.isEnableTab_0 = false;
    this.isEnableTab_1 = false;
    this.isEnableTab_2 = false;
    this.isEnableTab_3 = true;
    this.isEnableTab_4 = false;
    this.isEnableTab_5 = false;
    this.showPaymentMode = false;
  }

  async validateCR() {
    if (this.edit && this.formData["crNumber"] == this.patientRealCR) {
      this.crExists = false;
      this.crID = undefined;
      return true;
    }

    this.checkingCR = true;
    let res = await this.patientService.checkCRNumber(
      this.formData["crNumber"]
    );
    let checkCrNumber = res.available;
    this.checkingCR = false;
    if (checkCrNumber) {
      this.crExists = true;
      this.crID = res.id;
    }
    return !this.crExists;
  }
}
