import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { DashboardService } from "src/app/dashboard.service";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { TransactionsService } from "src/app/transactions.service";
import { AppointmentsService } from 'src/app/appointments.service';
import { cloneDeep } from "lodash";
import { Router, ActivatedRoute } from '@angular/router';
import { baseUrl } from '../../config';

let price_list = [
  {
    name: "MRI",
    amount: 2500,
  },
  {
    name: "MRI Report",
    amount: 350,
  },
  {
    name: "Injection Caudal Epidural",
    amount: 500,
  },
  {
    name: "Pharmacy",
    amount: 0,
  },
  {
    name: "IPD",
    amount: 0,
  },
  {
    name: "CBC",
    amount: 150,
  },
  {
    name: "ESR",
    amount: 120,
  },
  {
    name: "Glucose",
    amount: 50,
  },
  {
    name: "Creatinine",
    amount: 60,
  },
  {
    name: "PT, PTI, INR",
    amount: 220,
  },
  {
    name: "Urine Routine",
    amount: 90,
  },
  {
    name: "Blood Urea",
    amount: 60,
  },
  {
    name: "XRay Charges",
    amount: 150,
  },
  {
    name: "HHH",
    amount: 300,
  },
  {
    name: "ECG",
    amount: 150,
  },
  {
    name: "Potassium Sodium",
    amount: 270,
  },
  {
    name: "Diet Charges",
    amount: 50,
  },
  {
    name: "Yoga",
    amount: 500,
  },
  {
    name: "GYM",
    amount: 500,
  },
  {
    name: "Ultrasonic Therapy",
    amount: 150,
  },
  {
    name: "IFT",
    amount: 150,
  },
  {
    name: "SWD",
    amount: 300,
  },
  {
    name: "Hydro Therapy",
    amount: 0,
  },
  {
    name: "Other",
    amount: 0,
  },
];

@Component({
  selector: "app-create-bill",
  templateUrl: "./create-bill.component.html",
  styleUrls: ["./create-bill.component.css"],
})
export class CreateBillComponent implements OnInit {
  @ViewChild('form') form: ElementRef;

  @Input() visible;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() patient;
  amount = 0;
  transacted;
  transacting;
  errored;
  validationError;
  selectedValue;
  myControl = new FormControl();
  options: string[] = price_list.map((x) => x.name);
  items = [];
  total;
  filteredOptions: Observable<string[]>;
  newInput;
  mode_of_payment;
  errorMessage;
  master_list = cloneDeep(price_list);
  //redirect_url;

  accessCode: any;
  encRequestRes: any;
  selectedAddress: any = {
    name: 'test',
    address: 'test address',
    city: 'test city',
    pincode: '23456',
    state: 'state test',
    phone: '1234567890',
  };

  constructor(
    public dashboardService: DashboardService,
    public transactionService: TransactionsService,
    public appointmentService: AppointmentsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.accessCode = 'AVEY20II54AN98YENA';

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  successHandler() {
    this.onSuccess.emit();
    this.dashboardService.refreshAll();
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.items = [];
    price_list = cloneDeep(this.master_list);
    this.total = undefined;
    this.onClose.emit();
  }

  add() {
    if (!this.selectedValue || this.selectedValue == "") return;
    this.errorMessage = undefined;
    let item = price_list.filter((item) => item.name == this.selectedValue)[0];
    if (!item) item = { name: this.selectedValue, amount: 0 };
    this.items.push(item);
    this.updateTotal();
    this.selectedValue = "";
  }

  async transact() {
    if (this.items.length < 1)
      return (this.errorMessage = "Please select Items");
    if (!this.mode_of_payment)
      return (this.errorMessage = "Please select mode of payment");

    if (this.mode_of_payment == "digital") {
      //add transaction info to session
      this.transacting = true;
      this.items = this.items.map((x) => {
        if (!x.amount) x.amount = 0;
        return x;
      });

     let dumData= await this.transactionService.dummyTransaction({
        items: this.items,
        mode_of_payment: this.mode_of_payment,
        patient_id: this.patient._id,
        redirect_url: this.router.url.includes("patients") ? "patients/1" : this.router.url.includes(`patient/${this.patient._id}/transactions/1`) ? `patient/${this.patient._id}/transactions/1` : "reception"
      });

      //payment gateway
      let redirect_url = encodeURI(`${baseUrl}transactions/response`);
      let useremail = "support@his.rsicmohali.com";
      let order_no = this.orderNumber();
      this.selectedAddress.name = this.patient.firstName + ' ' + this.patient.lastName;
      let request = `merchant_id=488512&order_id=${order_no}&currency=INR&amount=${this.total}&redirect_url=${redirect_url}&cancel_url=${redirect_url}&language=EN&billing_name=${this.selectedAddress.name}&billing_address=${this.selectedAddress.address}&billing_city=${this.selectedAddress.city}&billing_state=MH&billing_zip=${this.selectedAddress.pincode}&billing_country=India&billing_tel=${this.selectedAddress.phone}&delivery_name=${this.selectedAddress.name}&delivery_address=${this.selectedAddress.address}&delivery_city=${this.selectedAddress.city}&delivery_state=${this.selectedAddress.state}&delivery_zip=${this.selectedAddress.pincode}&delivery_country=India&delivery_tel=${this.selectedAddress.phone}&billing_email=${useremail}`;

      this.appointmentService.encryptdata(request).subscribe(
        (data) => {
          this.transacting = false;
          this.encRequestRes = data;
          setTimeout(() => {
            this.form.nativeElement.submit();
          }, 1000);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.transacting = true;
      this.items = this.items.map((x) => {
        if (!x.amount) x.amount = 0;
        return x;
      });
      await this.transactionService.postTransaction({
        items: this.items,
        mode_of_payment: this.mode_of_payment,
        patient_id: this.patient._id,
      });
      this.transacting = false;

      this.successHandler();
    }
    // this.transacting = false;
  }

  updateTotal() {
    this.total = 0;
    this.items.map((x) => {
      this.total += x.amount ? x.amount : 0;
    });
  }

  remove(i) {
    this.items.splice(i, 1);
    this.updateTotal();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  orderNumber() {
    let now = Date.now().toString() // '1492341545873'
    // pad with extra random digit
    now += now + Math.floor(Math.random() * 10)
    // format
    return  [now.slice(0, 9)]
    //return  [now.slice(0, 6), now.slice(4, 10), now.slice(10, 14)]
  }
}
