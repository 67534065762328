<app-modal [visible]="visible" (onClose)="closeHandler()" title="View Items">
    <div class="table-responsive">
        <table class="table table-sm">
            <thead class="thead thead-dark text-white">
                <th>
                    Sr. No.
                </th>
                <th>
                    Item{{(items.length>1)?"s":""}}
                </th>
                <th>
                    Amount
                </th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let item of items; let i = index">
                    <td style="width: 10%;">
                        {{i+1}}
                    </td>
                    <td style="width: 60%;">
                        {{item.name}} <br/>
                        
                    </td>
                    <td style="width: 30%;">
                        {{item.amount}}
                    </td>
                </tr>
            </tbody>
        </table>
    
    </div>
    <div *ngIf="items.length < 1" class="d-flex w-100 align-items-center justify-content-center" style="height: 100px; background-color: #DEDEDE;">
        <h6>No Items!</h6>
    </div>

    <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;"/>

    <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
        <div class="row no-gutters justify-content-between">
            <div class="col-auto">
                <h3 class="m-0 text-success" style="text-transform: uppercase; font-weight: 1000;">Total: Rs. {{total}}</h3>
            </div>
            <div class="col-auto">
                <button class="btn-sm btn btn-danger" (click)="closeHandler()">Close</button>
            </div>
        </div>
    </div>
</app-modal>
