<div class="header pb-5 pt-5 pt-lg-7 d-flex align-items-center">
  <!-- Mask -->
  <span class="mask bg-gradient-success opacity-8"></span>
  <!-- Header container -->
  <div class="container-fluid d-flex align-items-center">
    <div class="row">
      <div class=" col-12">
        <button class="btn btn-sm btn-primary mb-2" (click)="location.back()">Go Back</button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--7">
  <div class="row">
    <div class="col-12">
      <div class="card shadow-0 border">
        <div class="card-header">
          <h3>HDFC Bank Response</h3>
        </div>
        <div class="p-3">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-auto">
                  <label for="fromDate" class="form-control-label">From:</label>
                  <input type="date" name="fromDate" [(ngModel)]="from" (ngModelChange)="refreshTransactions()"
                    class="form-control form-control-alternative" />
                </div>
                <div class="col-auto">
                  <label for="toDate" class="form-control-label">To:</label>
                  <input type="date" name="toDate" [(ngModel)]="to" (ngModelChange)="refreshTransactions()"
                    class="form-control form-control-alternative" />
                </div>
              </div>
            </div>
            <div class="col-auto align-self-center">
              <div *ngIf="transactions" class="ml-auto w-auto">
                <button (click)="export()" [disabled]="exporting" class="ml-2 mr-0 btn btn-success btn-sm">
                  <span *ngIf="exporting">
                    <i class="fa fa-spinner fa-spin"></i>&nbsp;Exporting
                  </span>
                  <span *ngIf="!exporting">
                    <i class="fa fa-file-export"></i>&nbsp; Export to CSV
                  </span>
                </button>
              </div>
              <div *ngIf="!transactions">
                <i class="fa fa-spinner fa-spin"></i>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="transactions && transactions.length > 0">
          <div class="table-responsive">
            <table class="table align-items-center table-flush">
              <thead class="thead-dark">
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Patient</th>
                  <th>Mode of Payment</th>
                  <th>Order Number</th>
                  <th>Amount</th>
                  <th>Appointment ID</th>
                  <th>Appointment Date</th>
                  <th>Tracking Id</th>
                  <th>Bank ref no</th>
                  <th>Order Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of transactions">
                  <td>{{transaction.createdAt | date:'mediumDate'}}</td>
                  <td>{{transaction.createdAt | date:'mediumTime'}}</td>
                  <td>{{transaction.fullName}}</td>
                  <td>{{transaction.payment_mode}}</td>
                  <td>{{transaction?.orderNumber}}</td>
                  <td>{{(transaction.amount==0)?"Poor Free":transaction.amount | number : '1.2-2'}}</td>
                  <td>{{transaction.appointmentNumber}}</td>
                  <td>{{transaction.appointmentDate | date:'mediumDate'}}</td>
                  <td>{{transaction?.tracking_id}}</td>
                  <td>{{transaction?.bank_ref_no}}</td>
                  <td><span
                      [ngClass]="transaction?.order_status == 'Success' ? 'btn-sm btn-success btn': 'btn-sm btn-danger btn'">{{transaction?.order_status}}</span>
                  </td>
                  <!-- <td></td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="!transactions"
          style="height: 300px; display: flex; align-items: center; justify-content: center; background-color: rgb(221, 221, 221);">
          <h3>Loading...</h3>
        </div>
        <div *ngIf="transactions && transactions.length == 0"
          style="height: 300px; display: flex; align-items: center; justify-content: center; background-color: rgb(221, 221, 221);">
          <h3>No Transactions!</h3>
        </div>

        <div class="card-footer py-4">
          <nav aria-label="...">
            <ul *ngIf="page.pages && page.pages.length" class="pagination mb-0" style="float: right;">
              <li class="page-item {{(page.currentPage===1)?'disabled':''}}">
                <a class="page-link" (click)="setPage(1)">
                  <i class="fas fa-angle-left"></i>
                  <span class="sr-only">Previous</span>
                </a>
              </li>

              <li *ngFor="let num of page.pages" class="page-item {{page.currentPage == num ? 'active': ''}}">
                <a class="page-link" (click)="setPage(num)">{{num}}</a>
              </li>

              <li class="page-item {{(page.currentPage === page.totalPages)?'disabled':''}}">
                <a class="page-link" (click)="setPage(page.totalPages)">
                  <i class="fas fa-angle-right"></i>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
