import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {baseUrl} from './config';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private httpClient: HttpClient) { }

  getHeaders() {
    return {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}};
  }
  async getSettings() {
    let res:any = await this.httpClient.get(baseUrl + 'settings',this.getHeaders()).toPromise();
    return res.settings;
  }

  async postSettings(body) {
    let res:any = await this.httpClient.post(baseUrl + 'settings', body,this.getHeaders()).toPromise();
    return res.settings;
  }

  async getLiveMessage() {
    let res:any = await this.httpClient.get(baseUrl + 'virtuallounge',this.getHeaders()).toPromise();
    return res.settings;
  }
}
