<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8" style="background:linear-gradient(40deg, #B060FF 30%, #FD6EC8 100%) !important;">
    <div class="container-fluid">
      <div class="header-body">
        <!-- Card stats -->
        <div class="row">
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Amount In <span class="text-nowrap">Cash Register</span></h5>
                    <span *ngIf="cash_in_hand" class="h2 font-weight-bold mb-0">Rs. {{cash_in_hand}}</span>
                    <span *ngIf="!cash_in_hand" class="h2 font-weight-bold mb-0">Loading...</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i class="fas fa-chart-bar"></i>
                    </div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <!-- <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span> -->

                  <span class="text-nowrap text-primary">Since last clear </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Cash Transactions</h5>
                    <span *ngIf="cash_transactions" class="h2 font-weight-bold mb-0">Rs. {{cash_transactions}}</span>
                    <span *ngIf="!cash_transactions" class="h2 font-weight-bold mb-0">Loading...</span>
                  </div>
                  <div class="col-auto">
                    <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                      <i class="fas fa-chart-pie"></i>
                    </div>
                  </div>
                </div>
                <p class="mt-3 mb-0 text-muted text-sm">
                  <span class="text-nowrap text-primary">Since last clear</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="container-fluid mt--7">
    <div class="row">
        <div class="col">
            <div class="card shadow border-0">
                <div class="card-header">
                    <div class="row no-gutters align-items-center justify-content-between">
                        <div class="col-auto">
                            <h2>Cash Register</h2>
                    </div>
                        <div class="col-auto">
                            <button class="btn btn-danger mx-1 mx-sm-0" (click)="clear_till_visible=true">Clear Register</button>
                        </div>
                    </div>

                </div>
                <div class="card-body">
                    <h5>Transactions since last clear</h5>

                    <div *ngIf="loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                        <span style="color:#333">Loading...</span>
                    </div>

                    <div *ngIf="!loading" class="w-100" style="overflow-x:auto">
                        <table class="table" style="overflow-x: scroll;">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Reason</th>
                                    <!-- <th>Patient Name</th> -->
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <Th>Opening Amount</Th>
                                    <th>Closing Amount</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transaction of transactions">
                                    <td>{{transaction.createdAt | date:'fullDate'}}</td>
                                    <td>{{transaction.createdAt | date:'fullTime'}}</td>
                                    <td>{{transaction.name}}</td>
                                    <!-- <td>{{transaction.patient_name}}</td> -->
                                    <td>{{transaction.type}}</td>
                                    <td [class]="(transaction.type=='CR')?'text-success':'text-danger'" style="font-weight: bold;">{{transaction.amount}}</td>
                                    <td>{{transaction.opening_balance}}</td>
                                    <td>{{transaction.cash_in_hand}}</td>
                                    <td><button class="btn btn-sm btn-primary">View</button><button class="btn btn-sm btn-success">Print Receipt</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- <app-patient-list [patients]="paitents" (patientUpdated)="opdModalSuccess()"></app-patient-list> -->
                </div>
            </div>
        </div>
    </div>
</div>

<app-clear-till *ngIf="cash_in_hand" [visible]="clear_till_visible" (onClose)="clear_till_visible=false;"  (onSuccess)="refreshTransactions()" [maxCash]="cash_in_hand"></app-clear-till>
