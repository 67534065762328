<div class="main-container" [ngClass]="{'d-none':!visible}" style="z-index: 1000;">
    <div class="backdrop" (click)="close()" ></div>
    <div class="card shadow border-0 margin-left-custom" style="width:{{width?width:30}}vw; min-width: 300px; position: relative;">
        <div *ngIf="!noHeader" class="card-header p-1">
            <div class="row no-gutters align-items-center">
                <div class="col">
                    <h4 *ngIf="title" class="m-0 p-2">{{title}}</h4>
                </div>
                <div class="col-auto">
                    <button [disabled]="closeDisabled" class="btn btn-sm btn-danger" (click)="close()">Close</button>
                </div>
            </div>
            
        </div>
        <div class="card-body">
            <ng-content></ng-content>
        </div>
    </div>
</div>