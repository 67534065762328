<app-modal [visible]="visible" (onClose)="closeHandler()" title="Reschedule Appointment" width="70">
  <div *ngIf="!transacting">
    <h3 *ngIf="isShowRemaining" class="alert" [ngClass]="{'alert-success': remaingSlots > 0, 'alert-danger': remaingSlots == 0}"><b style="font-size: 25px;">{{remaingSlots}}</b> slot(s) left for selected Date.</h3>
    <h6 class="heading-small font-weight-bold">Reschedule Date:</h6>
    <div class="row align-items-center mb-3 no-gutters">
      <div class="col">
        <mat-form-field appearance="outline" (click)="picker1.open()" class="w-100">
          <input matInput [matDatepicker]="picker1" [min]="minDate" name="date"
            [(ngModel)]="rescheduleDate" placeholder="Choose a date" readonly="true" (ngModelChange)="remainingSlots($event)">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker touchUi="true" #picker1></mat-datepicker>
        </mat-form-field>
        <div class="alert alert-danger" *ngIf="isShowErrorMsg">{{error}}</div>
      </div>
    </div>
  </div>

  <div *ngIf="!transacting && isUpdateAppointment">
    <h6 class="heading-small font-weight-bold">Apointment Status:</h6>
    <div class="row align-items-center mb-3 no-gutters">
      <div class="col">
        <select name="title" [(ngModel)]="rescheduleStatus"
        class="form-control form-control-alternative">
        <option value="">Please select</option>
        <option value="pending">Pending</option>
        <option value="completed">Completed</option>
        <option value="canceled">Canceled</option>
      </select>

      </div>
    </div>
  </div>

  <div *ngIf="transacting" class="d-flex w-100 align-items-center justify-content-center"
    style="height: 200px; background-color: #DEDEDE;">
    <h6><i class="fas fa-spinner fa-spin"></i>&nbsp; Transacting</h6>
  </div>

  <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;" />

  <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div *ngIf="errorMessage" class="mb-3 text-danger w-100 text-right">{{errorMessage}}</div>
    <div class="row no-gutters justify-content-between">
      <div class="col-auto">
      </div>
      <div class="col-auto">
        <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
        <button [disabled]="(remaingSlots == 0) || transacting" class="d-block ml-auto btn btn-success btn-sm" (click)="RescheduleDate()">Reschedule</button>
      </div>
    </div>
  </div>

</app-modal>
