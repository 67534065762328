<nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
  <div class="container-fluid">
    <!-- Brand -->
    <a class="h4 mb-0 text-white text-uppercase d-none d-md-inline-block" routerLinkActive="active" [routerLink]="['/dashboard']">{{getTitle()}}</a>
    <!-- Form -->
    <!-- <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
      <div class="form-group mb-0" [ngClass]="{ 'focused': focus === true }">
        <div class="input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Search" type="text" (focus)="focus = true"
          (blur)="focus = false">
        </div>
      </div>
    </form> -->
    <!-- User -->
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li *ngIf="['admin','superadmin'].includes(authService.getTokens()[1])" class="nav-item"  ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media-body ml-2 mr-2 d-none d-md-block" style="position: relative;">
              <span class="mb-0 text-sm  font-weight-bold">Pending Approvals</span>
              <div class="d-flex justify-content-center align-items-center bg-primary font-weight-bold" style="position: absolute; border-radius: 10px; padding-left:5px; padding-right: 5px; padding-top:1px; padding-bottom:1px; top:0; right:0; text-align:center; font-size: 0.65em; transform: translate(58%,-35%);">
                <span *ngIf="this.pending_approvals">{{this.pending_approvals.length}}</span>
                <span *ngIf="!this.pending_approvals" style="margin-left:-2px; margin-right:-2px;"><i class="fas fa-spinner fa-spin"></i></span>
              </div>
            </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" style="width: 15rem;" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">pending_approvals <span *ngIf="this.pending_approvals">({{this.pending_approvals.length}})</span>
              <span *ngIf="this.loading" style="margin-left:-2px; margin-right:-2px;"><i class="fas fa-spinner fa-spin"></i></span></h6>
          </div>
          <div *ngFor="let approval of this.pending_approvals" class="approval-item  dropdown-header py-2" style="color:black; border-top: 1px solid rgba(0, 0, 0, 0.05);">

            <div *ngIf="approval.type == 'clear_till'">
              <div class="text-primary" style="margin-bottom: 2px;">Clear Till Request</div>
              Amount: {{approval.amount}} <br/>
            </div>
            <div *ngIf="approval.type == 'refund'">
              <div class="text-primary" style="margin-bottom: 2px;">Refund Request</div>
              Amount: {{approval.amount}} <br/>
              Patient: {{approval.patient.title}} {{approval.patient.firstName}} {{approval.patient.lastName}} <br/>
            </div>
            Requested By: {{approval.requestedBy.username}}
            <div class="mt-1 text-right text-warning">
              <div *ngIf="!approval.action">
                <button style="font-size: 0.65rem;" [disabled]="approval.loading" class="btn-sm btn btn-success" (click)="approveClickHandler(approval)">Approve</button>
                <button style="font-size: 0.65rem;" [disabled]="approval.loading" class="btn-sm btn btn-danger"  (click)="rejectClickHandler(approval)">Reject</button>
              </div>
              <div *ngIf="approval.action" class="text-left">
                <label class="form-control-label" style="font-size: 0.8em; text-transform: uppercase;">Enter Password to {{startCase(approval.action)}}</label>
                <input type="password" name="password" [(ngModel)]="approval.password"   class="form-control form-control-alternative" />
                <div *ngIf="approval.error" class="alert text-danger">⚠ {{approval.error}}</div>
                <div class="text-right mt-3">
                  <button style="font-size: 0.65rem;" [disabled]="approval.loading" class="btn-sm btn btn-danger" (click)="cancelClickHandler(approval)">Cancel</button>
                  <button style="font-size: 0.65rem;" [disabled]="approval.loading"[disabled]="approval.loading" class="btn-sm btn btn-success" (click)="actionButtonHandle(approval)">{{startCase(approval.action)}}</button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </li>
      <li class="nav-item"  ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media-body ml-2 mr-2 d-none d-lg-block" style="position: relative;">
              <span class="mb-0 text-sm  font-weight-bold">Requested Approvals</span>
              <div class="d-flex justify-content-center align-items-center bg-primary font-weight-bold" style="position: absolute; border-radius: 10px; padding-left:5px; padding-right: 5px; padding-top:1px; padding-bottom:1px; top:0; right:0; text-align:center; font-size: 0.65em; transform: translate(58%,-35%);">
                <span *ngIf="this.approvals">{{this.approvals.length}}</span>
                <span *ngIf="!this.approvals" style="margin-left:-2px; margin-right:-2px;"><i class="fas fa-spinner fa-spin"></i></span>
              </div>
            </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" style="width: 15rem;" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Approvals <span *ngIf="this.approvals">({{this.approvals.length}})</span>
              <!-- <span *ngIf="!this.approvals" style="margin-left:-2px; margin-right:-2px;"><i class="fas fa-spinner fa-spin"></i></span> -->
            </h6>
          </div>
          <div *ngFor="let approval of this.approvals" class="approval-item  dropdown-header py-2" style="color:black; border-top: 1px solid rgba(0, 0, 0, 0.05);">

            <div *ngIf="approval.type == 'clear_till'">
              <div class="text-primary" style="margin-bottom: 2px;">Clear Till Request</div>
              Amount: {{approval.amount}} <br/>
            </div>

            <div *ngIf="approval.type == 'refund'">
              <div class="text-primary" style="margin-bottom: 2px;">Refund Request</div>
              Amount: {{approval.amount}} <br/>
              Patient: {{approval.patient.title}} {{approval.patient.firstName}} {{approval.patient.lastName}} <br/>
            </div>

            <div *ngIf="approval.status == 'pending' " class="text-right text-warning">
              Awaiting approval
            </div>
            <div *ngIf="approval.status == 'rejected' " class="text-right text-danger">
              Rejected
              <a [routerLink]="" (click)="markSeen(approval)" class="m-1 d-block" style="font-size: 0.95em;"><i class="far fa-eye"></i> Seen.</a>
            </div>
            <div *ngIf="approval.status == 'completed' " class="text-right text-success">
              ✔ Approved  <br/> <a [routerLink]="" (click)="markSeen(approval)"> Cash Removed.</a>
            </div>
          </div>


        </div>
      </li>
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="assets/img/brand/favicon.png">
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{this.auth.getTokens()[2]}}</span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <!-- <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a> -->
          <a (click)="changePasswordVisible=true" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>Change Password</span>
          </a>
          <!-- <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Activity</span>
          </a> -->
          <a (click)="supportClick()" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#!" (click)="doLogout()" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<app-user-change-password [visible]="changePasswordVisible" (onClose)="changePasswordVisible=false">

</app-user-change-password>
