<app-default-header></app-default-header>

<div class="container-fluid mt-3">
    <div class="row">
        <div class="col">
            <div class="card shadow border-0">
                <div class="card-header">
                    <div class="row no-gutters align-items-center justify-content-between">
                        <div class="col-auto">
                            <h2>Daily Tokens</h2>
                    </div>
                        <div class="col-auto">
                            
                        </div>
                    </div>
                    
                </div>
                <div class="card-body">
                    <div class="row mb-4">
                        <div class="col-auto">
                            <button class="btn btn-sm btn-primary" (click)="prev()">Prev</button>
                        </div>
                        <div class="col-auto text-center">
                            <h3 *ngIf="!edit" (click)="edit=true">{{date | date:'fullDate'}}</h3>
                            <input *ngIf="edit" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="setDate($event)" type="date" name="date" class="form-control mx-auto " style="width: 250px !important;">
                        </div>
                        <div class="col-auto">
                            
                            <button class="btn btn-sm btn-primary" (click)="next()" [disabled]="date >= todays_date">Next</button>
                        </div>
                    </div>
                    <hr class="mb-5 mt-0" />

                    <div *ngIf="loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                        <span style="color:#333">Loading...</span>
                    </div>


                    <div *ngIf="tokens.length < 1 && !loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;"><h3 >No tokens for the {{date|date:'fullDate'}}.</h3></div>
                    
                    <div *ngIf="!loading && tokens.length > 0" class="w-100" style="overflow-x:auto">
                        <table class="table" style="overflow-x: scroll;">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Token Number</th>
                                    <th>Entry Time</th>
                                    <th>Status</th>
                                    <th>Patient Name</th>
                                    <th>Type</th>
                                    <th *ngIf="date.getTime() == todays_date.getTime()">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let token of tokens">
                                    <td>{{token.type}}-{{token.number}}</td>
                                    <td>{{token.createdAt | date:'mediumTime'}}</td>
                                    <td>
                                        <span *ngIf="token.active" class="m-1 badge badge-success">Active</span>
                                        <span *ngIf="token.completed" class="m-1 badge badge-success">Completed</span>
                                        <span *ngIf="token.deffered && !token.completed" class="m-1 badge badge-info">Deffered till {{token.deffered | date:'shortTime'}}</span>
                                        <span *ngIf="!token.completed" class="m-1 badge badge-warning">Pending</span>
                                        <!-- {{token.active && "Active"}}{{token.completed && "Completed"}}{{(token.defered && !token.completed) && "Deffered"}} -->
                                    </td>
                                    <td>{{token.patient.firstName}} {{token.patient.middleName && token.patient.middleName+" "}}{{token.patient.lastName}}</td>
                                    <td>
                                        <span *ngIf="token.type == 'E'" class="m-1 badge badge-danger">Emergency</span>
                                        <span *ngIf="token.type == 'A'" class="m-1 badge badge-info">Appointment</span>
                                        <span *ngIf="token.type == 'W'" class="m-1 badge badge-primary">Walk In</span>
                                    </td>
                                    <td *ngIf="date.getTime() == todays_date.getTime()">
                                        <button *ngIf="token.type != 'E' && !token.completed" (click)="convertToEmergency(token._id)" class="ml-2 mr-0 btn btn-danger btn-sm">Convert to Emergency</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <!-- <app-patient-list [patients]="paitents" (patientUpdated)="opdModalSuccess()"></app-patient-list> -->
                </div>
            </div>
        </div>
    </div>
</div>