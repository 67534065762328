import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AppointmentsService } from "src/app/appointments.service";

@Component({
  selector: "app-alert-confirm",
  templateUrl: "./alert-confirm.component.html",
  styleUrls: ["./alert-confirm.component.css"],
})
export class AlertConfirmComponent implements OnInit {
  @Input() visible;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @Input() patient;

  errorMessage;
  transacting;
  errored;
  entry;

  constructor(private appService: AppointmentsService) {}

  ngOnInit(): void {}

  successHandler() {
    this.onSuccess.emit();
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.onClose.emit();
  }

  cancelAppointment() {
    this.appService
      .cancelAppointment(this.patient._id, this.patient)
      .then((res: any) => {
        this.entry = res;
        this.transacting = false;
        this.errored = false;
        this.onSuccess.emit();
        this.closeHandler();
      })
      .catch((err) => {
        this.transacting = false;
        this.errored = true;
      });
  }
}
