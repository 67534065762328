<app-modal [visible]="visible" (onClose)="modalClose()" width="70" [closeDisabled]="closeDisabled" title="Link Patient">
    <h4>Appointment Details</h4>
    <span *ngIf="appointment" class="badge badge-pill badge-light ml--1">Appointment Date: {{appointment.date | date:'shortDate'}}</span>
    <span *ngIf="appointment" class="badge badge-pill badge-light ml-2">Appointment Number: {{appointment.appointmentNumber}} </span>
    <span *ngIf="appointment" class="badge badge-pill badge-light ml-2">Patient name: {{appointment.title}} {{appointment.firstName}} {{appointment.lastName}}</span>
    <span *ngIf="appointment" class="badge badge-pill badge-light ml-2">Patient DOB: {{appointment.dob | date:'mediumDate'}}</span>

    <h4 class="mt-4"> Search And Link Patient to Appointment</h4>
    <div *ngIf="!linking" class="row no-gutters g-0 mb-1 align-items-center">
        <div class="col pb-1">
            <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control" placeholder="Search by name or CR No.">
        </div>
        <div *ngIf="search" (click)="search = undefined; patients = []" class="col-auto">
            <button  class="btn btn-warning ml-2 mt--1">Cancel</button>
        </div>
    </div>
    <div *ngIf="linking" style="min-height: 300px; background-color: rgb(223, 223, 223); display: flex; justify-content: center; align-items: center;"><h3 >Linking Patient....</h3></div>
    <div *ngIf="!linking && !search" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;"><h3 >Search and Link Patient to Appointment.</h3></div>
    <div *ngIf="loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;"><h3 >Loading...</h3></div>
    <div *ngIf="!linking && search && !loading && patients.length < 1" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;"><h3 >No Patient Found</h3></div>
    <app-patient-list *ngIf="!linking" [patients]="patients" mode="link" (linkClicked)="linkClickHandler($event)"></app-patient-list>
</app-modal>
