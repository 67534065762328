<div class="card shadow-0 border">
  <div class="p-3">
    <div class="row">
      <div class="col-md-12" style="margin-bottom: 10px;">
        <button class="ml-2 mr-0 btn btn-primary btn-sm" (click)="uploadFile()" style=" float:right">Upload</button>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div id="content">
            <ul class="timeline">
              <li *ngFor="let item of reports" class="event"
                [attr.data-date]="item.uploaddate | date:'EEEE, MMM dd yyyy'">
                <div class="row no-gutters">
                  <div class="col">
                    <h3>Diagnosis: {{item.diagnosis || 'NA' }}</h3>
                    <p>Type: {{item.type}}
                      <span>
                        <br>Provider: {{item.provider }}
                      </span>
                      <!-- <span>
                        <br>Access Code: {{item.security }}
                      </span> -->
                      <span *ngIf="item.success == '0'" class="failed">
                        <br>Status: Failed
                      </span>
                      <span *ngIf="item.success == '1'" class="success">
                        <br>Status: Completed
                      </span>
                    </p>
                  </div>
                  <div class="col-auto" *ngIf="item.success=='1'">
                    <button class="btn btn-sm btn-primary" (click)="viewData(item)">
                      <i class="fa fa-file-medical"></i> View Report Data
                    </button>
                    <button class="btn btn-sm btn-primary" (click)="patPayPopup()" *ngIf="!patPay">Pay</button>
                    <button class="btn btn-sm btn-primary" (click)="sharedwithPatient(item)" *ngIf="patPay">
                      <i class="fa fa-file-medical"></i> {{item.ShareWithPatient == '0'? "Share Report with Patient" :
                      "Unshare Report with Patient"}}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-imaging-confirm *ngIf="isShowRegistration" [visible]="isShowRegistration" (onClose)="isShowRegistration = false;"
  [pData]="patient" (onSuccess)="phpRegisterForMRI($event)"></app-imaging-confirm>

<app-modal [visible]="visible" (onClose)="closePatPay()" title="Patient Pat Pay" width="50">
  <div *ngIf="!transacting">
    <label class="heading-small font-weight-bold">IsPatPay:</label>
    <input type="checkbox" id="chkPatPay" class="ml-2" [(ngModel)]="isPatPay">
  </div>
  <div *ngIf="transacting" class="d-flex w-100 align-items-center justify-content-center"
    style="height: 200px; background-color: #DEDEDE;">
    <h6><i class="fas fa-spinner fa-spin"></i>&nbsp; Transacting</h6>
  </div>
  <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;" />
  <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div *ngIf="errorMessage" class="mb-3 text-danger w-100 text-right">{{errorMessage}}</div>
    <div class="row no-gutters justify-content-between">
      <div class="col-auto">
      </div>
      <div class="col-auto">
        <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
        <button [disabled]="transacting" class="d-block ml-auto btn btn-success btn-sm"
          (click)="savePatPay()">Save</button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal [visible]="shareVisible" (onClose)="closeSharePopUp()" title="Share & Unshare Report with Patient"
  width="50">
  <div *ngIf="showShareReportwithPatient">
    <div class="mb-3 w-100 text-center">
      <p>Has the patient paid for this report?</p>
    </div>
    <div>
      <label class="heading-small font-weight-bold">Paid Report:</label>
      <input type="checkbox" id="chkPatPay" class="ml-2" [(ngModel)]="isShareWithPatient"
        style="width: 18px;height: 18px;">
    </div>
  </div>
  <div *ngIf="!showShareReportwithPatient">
    <div class="mb-3 w-100 text-center">
      <p>The report is shared with the patient?</p>
      <p>Are you sure you want to un-share this report? </p>
    </div>
  </div>

  <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;" />
  <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div class="row no-gutters justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="d-block ml-auto btn btn-success btn-sm" (click)="updatePhpPatientReport()">Yes</button>
      </div>
    </div>
  </div>
</app-modal>
