import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CashService } from './cash.service';
import { TransactionsService } from './transactions.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private cashInHand = new BehaviorSubject("");
  private transactions = new BehaviorSubject({card_transactions:'',cash_transactions:'',total_transactions:''});
  private loading = new BehaviorSubject(false);

  public sharedCashInHand = this.cashInHand.asObservable();
  public sharedTransactions = this.transactions.asObservable();
  public sharedLoading = this.loading.asObservable();

  private interval; 


  constructor(private cashService:CashService, private transactionService:TransactionsService) {
    this.refreshAll();
    this.interval = setInterval(()=>{
      this.refreshAll({silent:true});
    },5000)
  }

  async refreshAll(options:any={}) {
    if(!options.silent) this.loading.next(true);
    let [register,transactions] = await Promise.all([this.cashService.getRegister(),this.transactionService.getTodaysOverview()]);
    if(!options.silent) this.loading.next(false);
    if(register.cash_in_hand === 0) register.cash_in_hand = "Zero";
    this.cashInHand.next(register.cash_in_hand);
    this.transactions.next(transactions);
  }



}
