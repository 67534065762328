import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() visible:boolean;
  @Input() closeDisabled:boolean;
  @Input() title:string;
  @Input() width:Number;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() noHeader:boolean;

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    if(!this.closeDisabled) this.onClose.emit();
  }

}
