import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-appointments-list",
  templateUrl: "./appointments-list.component.html",
  styleUrls: ["./appointments-list.component.css"],
})
export class AppointmentsListComponent implements OnInit {
  @Input() appointments: any = [];
  date = new Date();
  _dp = new DatePipe("en-US");
  selectedAppointment = undefined;
  visible = false;
  selectedPatient;
  opd_entry_visible = false;
  reschedule = false;
  recheduleAppointmentList: any =[];
  toDayDate;
  @Input() isUpdateAppointment: boolean = false;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();

  constructor() {
    let _d = this._dp.transform(this.date, "yyyy-MM-dd");
    this.toDayDate = _d;
    this.date = new Date(_d + "T00:00:00.000+00:00");
  }
  confirmAlert;

  ngOnInit(): void {
    this.date = this.toDayDate;
  }

  linkPatient(app) {
    this.selectedAppointment = app;
    this.visible = true;
  }

  openOpdEntry(app) {
    this.selectedAppointment = app;
    console.log(app);
    this.selectedPatient = app.patient;
    this.opd_entry_visible = true;
  }

  onEntryClose() {
    this.selectedAppointment = undefined;
    this.selectedPatient = undefined;
    this.opd_entry_visible = false;
  }

  refreshAppointments() {
    this.recheduleAppointmentList = [];
    this.onSuccess.emit();
  }

  confirmBox(patient) {
    this.selectedPatient = Object.assign({}, patient);
    this.confirmAlert = true;
  }

  linkRescheduleAppointment() {
    if(this.recheduleAppointmentList.length>0){
      this.reschedule = true;
    }
  }

  selectAppointmentChange(id, e){
    if (e.target.checked) {
      this.recheduleAppointmentList.push(id);
    } else {
       const index = this.recheduleAppointmentList.findIndex(x => x == id);
       this.recheduleAppointmentList.splice(index,1);
    }
  }
}
