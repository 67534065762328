import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "./config";

@Injectable({
  providedIn: "root",
})
export class AppointmentsService {
  constructor(private httpClient: HttpClient) {}

  getHeaders() {
    return {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
  }

  async getAppointments(page_no) {
    let res: any = await this.httpClient
      .get(baseUrl + "appointments?page=" + page_no, this.getHeaders())
      .toPromise();
    return res;
  }

  async searchAppointment(query) {
    let res: any = await this.httpClient
      .post(baseUrl + "appointments/search", { query }, this.getHeaders())
      .toPromise();
    return res.appointments;
  }

  async searchAllAppointment(query) {
    let res: any = await this.httpClient
      .post(baseUrl + "appointments/searchAll", { query }, this.getHeaders())
      .toPromise();
    return res.appointments;
  }

  async linkAppointment(appointment_id, patient_id) {
    let res: any = await this.httpClient
      .post(
        baseUrl + `appointment/${appointment_id}/link`,
        { patient_id },
        this.getHeaders()
      )
      .toPromise();
    console.log(res);
    return res.appointment;
  }

  async checkDate(fullYear, month, date, bType) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `appointments/checkDate?fullYear=${fullYear}&month=${month}&date=${date}&bookingType=${bType}`,
        this.getHeaders()
      )
      .toPromise();
    return res;
  }

  async getClosedDates(bType) {
    let res: any = await this.httpClient
      .get(
        baseUrl + `appointments/closedDates?bookingType=${bType}`,
        this.getHeaders()
      )
      .toPromise();
    return res.dates;
  }

  async findAppointment(appointmentNumber, dob) {
    let res: any = await this.httpClient
      .post(baseUrl + `appointment/find`, { appointmentNumber, dob })
      .toPromise();

    return res.appointment;
  }

  async cancelAppointment(_id, pData) {
    let res: any = await this.httpClient
      .post(baseUrl + `appointment/${_id}/cancel`, { pData })
      .toPromise();
    return res.appointment;
  }

  async getByDate(startDate, endDate) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `appointments/getByDate?startDate=${startDate}&endDate=${endDate}`,
        this.getHeaders()
      )
      .toPromise();
    return res.appointments;
  }

  //HAPPY
  async getByTodayDate(startDate,endDate, page_no) {
    let res:any = await this.httpClient.get(baseUrl+`appointments/todayDate?startDate=${startDate}&endDate=${endDate}&page=${page_no}`, this.getHeaders()).toPromise();
    return res;
  }


  async getByDateWithPaging(startDate, endDate, page_no) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `appointments/getByDateWithPaging?startDate=${startDate}&endDate=${endDate}&page_no=${page_no}`,
        this.getHeaders()
      )
      .toPromise();
    return res;
  }

  async rescheduleAppointment(_id, rescheduleDate) {
    let res: any = await this.httpClient
      .post(baseUrl + `appointment/${_id}/updateappointment`, {
        rescheduleDate,
      })
      .toPromise();
    return res.appointment;
  }

  async rescheduleAppointments(data) {
    let res: any = await this.httpClient
      .post(baseUrl + `appointment/rescheduleappointment`, { data })
      .toPromise();
    return res.appointment;
  }

  async findAppointmentByNo(appointmentNumber) {
    let res: any = await this.httpClient
      .post(baseUrl + `appointment/findAppointment`, { appointmentNumber })
      .toPromise();
    return res.appointment;
  }

  async getByPatientId(patient_id, startDate, endDate) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `appointments/getByPatientId?startDate=${startDate}&endDate=${endDate}&patient_id=${patient_id}`,
        this.getHeaders()
      )
      .toPromise();
    return res.appointments;
  }

  async getAllAppointmentByPatientId(patient_id) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `appointments/getAllAppointmentByPatientId?patient_id=${patient_id}`,
        this.getHeaders()
      )
      .toPromise();
    return res.appointments;
  }

  //Book Appointment Call Change Dated On 31/04.2022 BY Neeraj
  public bookAppointment(data) {
    return this.httpClient.post(baseUrl + "bookAppointment", data).toPromise();
  }

  encryptdata(request) {
    let url = `${baseUrl}appointments/encryptFormData`;
    let data = { request: request };
    return this.httpClient.get(url, { params: data, responseType: "text" });
  }

  async getCancelledAppointment(startDate, endDate) {
    let url = `${baseUrl}appointments/getCancelledAppointments?startDate=${startDate}&endDate=${endDate}`;
    let res: any = await this.httpClient
      .get(url, this.getHeaders())
      .toPromise();
    return res;
  }

  async updateAppointmentStatus(data) {
    let url = `${baseUrl}appointments/updateappointmentstatus`;
    let res: any = await this.httpClient
      .post(url, data, this.getHeaders())
      .toPromise();
    return res;
  }

  async alreadyExistsBookingDate(phoneNo, aadharNo, bookingDate) {
    let url = `${baseUrl}appointments/alreadyExists?phoneNo=${phoneNo}&aadharNo=${aadharNo}&bookingDate=${bookingDate}`;
    let res: any = await this.httpClient
      .get(url, this.getHeaders())
      .toPromise();
    return res;
  }
}
