<div class="header pt-3 pb-md-6 pb-lg-3"
  style="background: linear-gradient(87deg, #3639bf 0, #c03066 100%) !important;">
  <!-- <div class="header pt-3 pb-md-6 pb-lg-3" style="background-color: #94bf5e"> -->
  <div class="container-fluid">
    <div class="header-body">
      <!-- <div class="col-12 col-md-auto" style="border: 5px solid #cc0000;">
        <a href="http://his.rsicmohali.com/#/appointment"
          class="elementor-button-link elementor-button elementor-size-sm" role="button">
          <span class="elementor-button-content-wrapper">
            <span class="elementor-button-text">Book Appointments</span>
          </span>
        </a>
      </div> -->
      <div class="row align-items-center">
        <div class="col-12 col-md-auto">
          <img src="../../../assets/img/logo/RISC-logo.png" class="mx-auto d-block" style="max-width: 160px;" />
        </div>
        <div class="col-12 col-md align-self-center text-center text-md-left">
          <h1 class="text-white" style="font-size: 2.5em; text-align: center;">RSIC Virtual OPD Lounge</h1>
          <!-- <p class="text-lead text-light d-none d-md-block">Please use the form below to below appointments at Regional Spinal Injury Centre.</p> -->
        </div>
        <div class="col-12 col-md-auto d-none d-md-block clsblnkimg">
          <a href="javascript:void(0)">Scan me for Live OPD</a>
          <!-- <span class="blinking">Scan for Virtual Lounge/Live OPD</span> -->
          <a href="http://his.rsicmohali.com/#/virtual-lounge">
            <img src="../../../assets/img/VirtualLounge.png" class="mx-auto d-block" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div> -->
</div>
<div class="container-fluid" style="background-color: #fff;">
  <div class="row align-items-center">
    <div class="col-md-6 col-sm-12 justify-content-center align-items-center d-flex padding-md-90">
      <div>
        <h1 class="text-center pt-5 heading-small heading pb-3" style="color: #7a3494;">Currently Serving</h1>
        <div class="token-wrapper mx-auto py-2" style="border: 2px dashed #003d7d;">
          <h5 class="m-0 heading-small text-center mx-4">Token Number</h5>
          <div class="token-number ">
            <i *ngIf="token_loading" class="fa fa-spinner fa-spin"></i>
            <div *ngIf="current_token"> {{current_token.type}}-{{current_token.number}}</div>
            <div *ngIf="!current_token && !token_loading">-</div>
          </div>
          <!-- <h1 class="m-0 mx-2">Currently Serving</h1> -->
        </div>
      </div>
    </div>
    <div class="col-md-6 padding-md-90" style="padding-bottom: 90px !important;">
      <h1 class="text-center pt-5 heading-small heading pb-3" style="color: #003d7d;">Next Tokens</h1>
      <div class="card border-0 ml-xl-3 mt-5" style="background-color: white; border-radius: 10px;min-height: 350px;">
        <!-- <div class="card-header">
                    <h1 class="m-0 heading-small" style="font-size:2.3em">Next Tokens</h1>
                </div> -->
        <div class="card-body">
          <div class="row no-gutters">
            <div *ngFor="let token of next_tokens" class="col-auto">
              <div class="text-danger m-2  py-2 px-3  d-flex align-items-center justify-content-center"
                style="min-width: 60px; width: fit-content; flex-direction: column; border:1px solid black">
                <p class="m-0" style="font-weight: 1000; font-size:3.8em">
                  {{token.type}}-{{token.number}}
                  <!-- W-100 -->
                </p>
                <!-- <small class="text-small text-primary text-center">Deferred Until<br/> {{token.deffered | date:'shortTime'}}</small> -->
              </div>
            </div>
          </div>
          <div *ngIf="!next_tokens_loading && next_tokens && next_tokens.length < 1" style="height: 200px;"
            class="d-flex justify-content-center align-items-center">
            <h4>No Next Tokens!</h4>
          </div>
          <div *ngIf="next_tokens_loading" style="height: 200px;"
            class="d-flex justify-content-center align-items-center">
            <h4><i class="fa fa-spinner fa-spin"></i> Loading..</h4>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-3" style="padding-bottom: 90px;">
            <div class="card border-0 ml-xl-3 mt-5" style="background-color: white; border-radius: 10px;">
                <div class="card-header">
                    <h1 class="m-0 heading-small" style="font-size:2.3em">Deferred Tokens</h1>
                </div>
                <div class="card-body" style=" max-height: 550px; overflow-y: scroll;">
                    <div class="row no-gutters" style="justify-content: space-evenly;">
                        <div *ngFor="let token of deffered_tokens" class="col-auto">
                            <div class="text-danger m-2  py-2 px-4  d-flex align-items-center justify-content-center" style="min-width: 60px; width: fit-content; flex-direction: column; border:1px solid black">
                                <p class="m-0" style="font-weight: 1000; font-size:1.8em">
                                    {{token.type}}-{{token.number}}
                                </p>
                                <small class="text-small text-primary text-center">Deferred Until<br/> {{token.deffered | date:'shortTime'}}</small>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="deffered_tokens && !deffered_tokens_loading && deffered_tokens.length < 1" style="height: 200px;" class="d-flex justify-content-center align-items-center">
                        <h4>No Deffered Tokens!</h4>
                    </div>
                    <div *ngIf="deffered_tokens_loading" style="height: 200px;" class="d-flex justify-content-center align-items-center">
                        <h4>
                            <i class="fa fa-spinner fa-spin"></i> Loading..
                        </h4>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</div>

<div class="w-100 " style="position: fixed; top:100vh; background-color: black;  transform: translateY(-100%);">
  <div class="row no-gutters">
    <div class="col align-self-center" style="margin-top: 6px; margin-left: 20px; margin-right: 20px;">
      <marquee scrollamount="13">
        <h1 class="m-0" style="color:red; font-size:2.5em">{{settings.liveMsg}}</h1>
      </marquee>
    </div>
    <div class="col-auto d-none d-lg-block text-white align-self-center px-3 text-center"
      style="font-size: 1.8em; border-left: 1px solid white; "> {{date | date:'shortTime'}}<br />{{date |
      date:'mediumDate'}}</div>
  </div>
</div>
