<app-modal [visible]="visible" (onClose)="closeHandler()" title="Create Note" width="70">

  <div *ngIf="!transacting">
    <h6 class="heading-small font-weight-bold">Note:</h6>
    <div class="row align-items-center mb-3 no-gutters">
      <div class="col">
        <textarea type="text" [(ngModel)]="note" class="form-control" style="min-height: 200px;"></textarea>
      </div>
    </div>
  </div>

  <div *ngIf="transacting" class="d-flex w-100 align-items-center justify-content-center"
    style="height: 200px; background-color: #DEDEDE;">
    <h6><i class="fas fa-spinner fa-spin"></i>&nbsp; Transacting</h6>
  </div>

  <hr style="margin-top:1.5rem; margin-left:-1.5rem; margin-right: -1.5em; margin-bottom: 0;" />

  <div class="p-3" style="margin-bottom: -1.5rem;  margin-left:-1.5rem; margin-right: -1.5em;">
    <div *ngIf="errorMessage" class="mb-3 text-danger w-100 text-right">{{errorMessage}}</div>
    <div class="row no-gutters justify-content-between">
      <div class="col-auto">
      </div>
      <div class="col-auto">
        <p *ngIf="errored" class="mb-3 text-danger">⚠ Some Error Occured!</p>
        <button [disabled]="transacting" class="d-block ml-auto btn btn-success btn-sm" (click)="createNote()">Create
          Note</button>
      </div>
    </div>
  </div>

</app-modal>
