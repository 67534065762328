import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/settings.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  array = Array(7).fill(1)
  loading = false;
  settings:any = {
    general:{},
    appointment:{},
    liveMsg : ''
  };
  excludedWeekendDate;
  excludedWeekdayDate;
  constructor(public settingService:SettingsService) { }

  ngOnInit(): void {
      this.getSettings();
  }

  weekendFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day == 6;
  }

  weekdayFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day != 0 && day != 6;
  }

  async getSettings() {
    this.loading = true;

    try {
      this.settings =  await this.settingService.getSettings()
    }
    catch(e) {
      alert('Some Error Occured!');
    }

    this.loading = false;
  }

  deleteExcludedWeekDay(date) {
    if(!confirm(`Are you sure you want to delete ${date} from excluded weekday opd days list?`)) return;
    let indexOfDate = this.settings.appointment.weekdayHolidays.indexOf(date);
    if(indexOfDate < 0) return;
    this.settings.appointment.weekdayHolidays.splice(indexOfDate,1);
  }

  deleteExcludedWeekEnd(date) {
    if(!confirm(`Are you sure you want to delete ${date} from excluded weekend opd days list?`)) return;
    let indexOfDate = this.settings.appointment.weekendHolidays.indexOf(date);
    if(indexOfDate < 0) return;
    this.settings.appointment.weekendHolidays.splice(indexOfDate,1);
  }

  excludeWeekendDate()  {
    if(!this.settings.appointment || !this.settings.appointment.weekendHolidays || !this.excludedWeekendDate) return;
    let datePipe = new DatePipe('en-US');
    let date = datePipe.transform(this.excludedWeekendDate,'shortDate')
    if(this.settings.appointment.weekendHolidays.includes(date)) return alert('Date already Excluded');
    this.settings.appointment.weekendHolidays.push(date);
    this.excludedWeekendDate = undefined;
  }

  excludeWeekdayDate() {
    if(!this.settings.appointment || !this.settings.appointment.weekdayHolidays || !this.excludedWeekdayDate) return;
    let datePipe = new DatePipe('en-US');
    let date = datePipe.transform(this.excludedWeekdayDate,'shortDate')
    if(this.settings.appointment.weekdayHolidays.includes(date)) return alert('Date already Excluded');
    this.settings.appointment.weekdayHolidays.push(date);
    this.excludedWeekdayDate = undefined;
  }

  async submit() {
    if(this.settings.general.appointment_token_after < 1){
      alert('Invalid Appointments Token After!');
    }else{
      this.loading = true;
      this.settings = await this.settingService.postSettings(this.settings);
      this.loading = false;
      alert('Settings saved successfully!');
    }
  }
}
