import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { PatientService } from "src/app/patient.service";

@Component({
  selector: "app-create-opd-entry",
  templateUrl: "./create-opd-entry.component.html",
  styleUrls: ["./create-opd-entry.component.css"],
})
export class CreateOpdEntryComponent implements OnInit {
  @Input() visible: boolean;
  closeDisabled: boolean = false;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Input() patient;
  @Input() appointment;
  mode_of_payment = "";
  purpose = "";
  transacting = false;
  transacted = false;
  errored = false;
  entry;
  constructor(public patientService: PatientService) {}

  ngOnInit(): void {
    if (this.appointment !== undefined) {
      this.modalPatientDetails(this.patient);
    }
  }

  async modalPatientDetails(patientId) {
    let patientRes = await this.patientService.getPatient(patientId);
    this.patient = patientRes;
  }

  modalClose() {
    this.mode_of_payment = "";
    this.transacted = false;
    this.errored = false;
    this.purpose = "";
    this.onClose.emit();
  }

  print() {}

  markEntry() {
    //debugger
    let patientId;
    if (!this.mode_of_payment || this.mode_of_payment == "") return;

    this.transacting = true;
    this.closeDisabled = true;
    let _id = this.appointment ? this.appointment._id : undefined;

    if (this.appointment) {
      if (typeof(this.appointment.patient) === "object") {
        patientId = this.appointment.patient._id;
      }else{
        patientId = this.appointment.patient;
      }
    } else {
      patientId = this.patient._id;
    }

    this.patientService
      .markOPDEntry(patientId, this.mode_of_payment, this.purpose, _id)
      .then((res: any) => {
        this.entry = res.entry;
        this.closeDisabled = false;
        this.transacting = false;
        this.transacted = true;
        this.errored = false;
        this.onSuccess.emit();
      })
      .catch((err) => {
        this.closeDisabled = false;
        this.transacting = false;
        this.errored = true;
      });
  }
}
