import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/patient.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  entries:[];
  patient_id;
  loading;
  constructor(public route:ActivatedRoute, public router:Router, public patientService:PatientService) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => { 
      this.patient_id = params.id;
      this.refreshHistory();
    });
  }

  async refreshHistory() {
    this.loading = true;
    this.entries = await this.patientService.getOPDEntry(this.patient_id);
    console.log(this.entries);
    this.loading = false;
  }

}
