import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth.service";
import { Location, DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentsService } from "src/app/appointments.service";

@Component({
  selector: "app-appointments",
  templateUrl: "./appointments.component.html",
  styleUrls: ["./appointments.component.css"],
})
export class AppointmentsComponent implements OnInit {
  loading = false;
  appointmentsData: any = [];
  startDate: any;
  endDate: any;
  patient_id;
  order_status: any;
  //showModal = false;
  page_no: Number = 0;
  offset;
  pages;
  total;
  page_numbers = [];

  constructor(
    public auth: AuthService,
    public location: Location,
    public router: Router,
    public appointmentService: AppointmentsService,
    public route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      if (params.order_no) {
        let currentURL = window.atob(params.order_no);
        this.order_status = currentURL.split("/")[1];
        this.patient_id = currentURL.split("/")[2];
        // this.showModal = true;
      }
    });
  }

  ngOnInit(): void {
    let from = new Date();
    let to = new Date();
    let datePipe = new DatePipe("en-US");
    from.setDate(1);
    from.setHours(0, 0, 0, 0);
    to.setMonth(+to.getMonth() + 1);
    to.setHours(0, 0, 0, 0);
    to.setDate(0);
    this.endDate = datePipe.transform(to, "yyyy-MM-dd");
    this.startDate = datePipe.transform(from, "yyyy-MM-dd");

    this.route.parent.params.subscribe((params) => {
      this.patient_id = params.id;
      //this.refreshTransactions();
    });

    this.route.params.subscribe((params) => {
      if (!params.page_no)
        this.router.navigate["/patient/" + this.patient_id + "/appointments/1"];
      this.page_no = Number(params["page_no"]);
      if (!this.page_no) this.location.back();
      this.refreshAppointments();
    });
  }

  refreshAppointments() {
    this.appointmentService
      .getAllAppointmentByPatientId(this.patient_id /*, this.page_no*/)
      .then((res) => {
        this.appointmentsData = res;
        //sort by date desc
        this.appointmentsData.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
        this.page_no = Number(res.page);
        this.pages = res.pages;
        this.page_numbers = new Array(this.pages).fill(1).map((v, i) => i + 1);
        this.total = res;
        this.offset = res.offset;

        if (res.pages < this.page_no)
          this.router.navigate(["/transactions/" + res.pages]);
      });
  }
}
