import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {baseUrl} from './config';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {


  private activeToken = new BehaviorSubject(undefined);
  private loading = new BehaviorSubject(undefined);
  private nextTokens = new BehaviorSubject(undefined);



  constructor( private httpClient: HttpClient) {

  }

  getHeaders() {
    return {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}};
  }

  async convertToEmergency(token_id) {
    let res:any = await this.httpClient.post(baseUrl + `token/${token_id}/convertToEmergency`,{},this.getHeaders()).toPromise();
    return res;
  }

  async getActiveToken() {
    let res:any = await this.httpClient.get(baseUrl+'active-token').toPromise();
    return res.token;
  }

  async getNextTokens(number?:Number) {
    let url = baseUrl+`next-tokens?number=${(number)?number:8}`
    // console.log(url);
    let res:any = await this.httpClient.get(url).toPromise();
    return res.tokens;
  }

  async getByDate(date?:Number,month?:Number,year?:Number) {
    let res:any = await this.httpClient.get(baseUrl + `tokens/byDate?date=${date}&month=${month}&year=${year}`,this.getHeaders()).toPromise()
    return res.tokens;
  }

  async startOPD() {
    let res:any = await this.httpClient.post(baseUrl+'start-opd',{},this.getHeaders()).toPromise();
    return res.token;
  }

  async stopOPD() {
    let res:any = await this.httpClient.post(baseUrl+ "stop-opd",{},this.getHeaders()).toPromise();
    return res.token;
  }

  async pendingOPD() {
    let res:any = await this.httpClient.post(baseUrl+ "pending-opd",{},this.getHeaders()).toPromise();
    return res.token;
  }

  async completeVisit() {
    let res:any = await this.httpClient.post(baseUrl+ "complete-visit",{},this.getHeaders()).toPromise();
    return res.token;
  }

  async defferToken(minutes:number) {
    let res:any = await this.httpClient.post(baseUrl+ "deffer-token",{minutes},this.getHeaders()).toPromise();
    return res.token;
  }

  async getDefferedToken(limit:Number) {
    let url = baseUrl+`deffered-tokens?limit=${(limit)?limit:8}`
    // console.log(url);
    let res:any = await this.httpClient.get(url).toPromise();
    return res.tokens;
  }
}
