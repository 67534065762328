import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CashService } from 'src/app/cash.service';
import { ApprovalsService } from 'src/app/approvals.service';

@Component({
  selector: 'app-clear-till',
  templateUrl: './clear-till.component.html',
  styleUrls: ['./clear-till.component.css']
})
export class ClearTillComponent implements OnInit {

  @Input() visible;
  @Output() onSuccess:EventEmitter<any> = new EventEmitter();
  @Output() onClose:EventEmitter<any> = new EventEmitter();
  @Input() maxCash;
  amount = 0;
  transacted;
  transacting;
  errored;
  validationError;
  constructor(public approvalService:ApprovalsService) { }

  async clickHandler() {
    
    if(!this.validateField()) return;
    this.transacting = true;
    
    try {
      await this.approvalService.requestClearTill(this.amount)
      this.errored = false;
      this.transacting = false;
      this.successHandler();
    }
    catch(err) {
      console.log(err);
      this.errored = true;
      return;
    }
  
  }

  successHandler() {
    this.onSuccess.emit()
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.onClose.emit();
  }

  validateField() {
    let flag = true;
    console.log(this.amount,this.maxCash)
    if(this.amount > this.maxCash) {
      this.amount = Math.abs(this.maxCash);
      flag = false;
    }

    if(this.amount <= 0 ){ 
      flag = false;
    }

    if(!flag) this.validationError = "Please enter a valid Amount.";
    else this.validationError = undefined;

    return flag;
  }

  changeHandler(e) {
      this.validateField()
  }

  ngOnInit(): void {
    
  }

}
