import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { TokenService } from 'src/app/token.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit, OnChanges {

  visible = false;
  createBillVisible = undefined;
  selectedPatient = undefined;
  confirmAlert;
  showModal = false;
  order_status: any;
  tracking_id:any;

  @Input() patients;
  @Input() mode;
  @Input() showToken = true;
  @Output() patientUpdated:EventEmitter<any> = new EventEmitter();
  @Output() linkClicked:EventEmitter<any> = new EventEmitter();
  @Output() onClose:EventEmitter<any> = new EventEmitter();
  constructor(public router:Router, public authService:AuthService, public tokenService:TokenService,  private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      if (params.order_no) {
        let currentURL = window.atob(params.order_no);
        //this.order_id = currentURL.split('/')[0];
        this.order_status = currentURL.split('/')[1];
        this.tracking_id = currentURL.split('/')[2];
        this.showModal = true;
      }
    });
  }

  opd_entry_click(t) {
    this.visible = true,
    this.selectedPatient = Object.assign({}, t)
  }

  create_bill_click(p) {
    this.selectedPatient = Object.assign({}, p)
    this.createBillVisible = true;
  }

  onSuccess() {
    this.patientUpdated.emit();
  }

  onClose1() {
      this.visible = false;
  }

  onLink(patient_id) {
    this.linkClicked.emit(patient_id);
  }

  ngOnInit(): void {
    console.log(this.patients);
  }

  closeHandler() {
    this.showModal = false;
    this.onClose.emit();
  }

  ngOnChanges(simpleChanges:SimpleChanges) {
    if(simpleChanges.patients) this.patients =simpleChanges.patients.currentValue;
  }

  formatAge(dob) {
    var n = new Date(dob);
    n.setHours(0, 0, 0, 0);
    var e = new Date;
    e.setHours(0, 0, 0, 0);
    var r = new Date(1900,0);
    if (e < n || n < r)
        return !1;
    var i = new Date(e.getTime() - n.getTime())
      , o = {
        years: i.getFullYear() - 1970,
        months: i.getMonth()
    };
    let age = o.years + " Year" + (o.years > 1 ? "s" : "") + ", " + o.months + " Month" + (o.months > 1 ? "s" : "") + ".";

    return age;
  }

  async convertToEmergency(token_id) {
    if(!confirm("Are you sure you want to convert token to E type?\n\nThis action is irreversible.")) return;
    await this.tokenService.convertToEmergency(token_id);
    this.patientUpdated.emit();
  }
}
