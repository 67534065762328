import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reception-appointments',
  templateUrl: './reception-appointments.component.html',
  styleUrls: ['./reception-appointments.component.css']
})
export class ReceptionAppointmentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
