<app-default-header></app-default-header>

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-xl-4 mt-5 order-1 order-lg-2">
        <div class="card shadow border-0">
          <div class="card-header">
            <h3>Add Accounts</h3>
          </div>
          <div class="card-body">
            <div class="mb-3 mt-3">
              <label>Username</label>
              <input [disabled]="loading" class="form-control" [(ngModel)]="username" (input)="addUserInputChanged($event)"/>
            </div>
            <div class="mb-3">
              <label>Password</label>
              <input [disabled]="loading" type="password" class="form-control" [(ngModel)]="password"  (input)="addUserInputChanged($event)"/>
            </div>
            <div class="mb-3">
              <label>Type</label>
              <select [disabled]="loading" class="form-control"  [(ngModel)]="type">
                <option value="">Please select account type.</option>
                <option value="superadmin">Super Admin</option>
                <option value="admin">Admin</option>
                <option value="doctor">Doctor</option>
                <option value="receptionist">Receptionist</option>
              </select>
            </div>
            <div class="alert alert-danger mb-3" *ngIf="formError">All fields are required!</div>
            <button [disabled]="loading" class="btn btn-success d-inline" (click)="saveUser()">Add</button>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <mat-progress-bar *ngIf="loading" class="mt-3"  mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="col-12 col-xl col-lg-12 mt-3 order-2 order-xl-1">
        <mat-card>
          <div class="row  mb-3 no-gutters align-items-center">
            <div class="col my-0">
              <h3 class="mat-heading-3 m-0">User Accounts</h3>
            </div>
            <div *ngIf="users" class="mt-3" style="overflow-x:scroll;">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Username</th>
                    <th>User Type</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>

              </table>
            </div>

          </div>
        </mat-card>
      </div>
      <div class="col-12 col-xl-3 mt-3 order-1 order-xl-2">
        <mat-card>
          <div class="row  mb-3 no-gutters align-items-center">
            <div class="col my-0">
              <h3 class="mat-heading-3 m-0">Add User</h3>
            </div>


        </mat-card>
      </div>
    </div>
  </div>
   -->
