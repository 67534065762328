import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PatientService } from 'src/app/patient.service';
import { CashService } from 'src/app/cash.service';
import { TransactionsService } from 'src/app/transactions.service';
import { DashboardService } from 'src/app/dashboard.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-reception',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.css']
})

export class ReceptionComponent implements OnInit {
  dbPaitents:any[] = undefined;
  paitents:any[] = undefined;
  loading = false;
  todayPatients = [];

  search = undefined;

  dashLoading = false;
  cash_in_hand = undefined;
  total_transactions;
  card_transactions;
  cash_transactions;

  constructor(public router:Router, public patientService:PatientService, public cashService:CashService, public transactionService:TransactionsService, public dashboardService:DashboardService) { }

  ngOnInit() {
    this.refreshPatients();
    // this.refreshDashboard();
    this.dashboardService.sharedCashInHand.subscribe(cash_in_hand=>{
      this.cash_in_hand = cash_in_hand;
      console.log(cash_in_hand);
    })

    this.dashboardService.sharedTransactions.subscribe(transactions=>{
      this.total_transactions = transactions.total_transactions;
      this.cash_transactions = transactions.cash_transactions;
      this.card_transactions = transactions.card_transactions;
    })

    this.dashboardService.sharedLoading.subscribe(loading =>{
      this.dashLoading = loading;
    })
  }


  opdModalSuccess() {
    // this.refreshDashboard();
    this.dashboardService.refreshAll();
    this.refreshPatients();
  }

  async refreshDashboard() {
    this.dashLoading = true;
    this.search = undefined;
    let [register,transactions] = await Promise.all([this.cashService.getRegister(),this.transactionService.getTodaysOverview()]);
    this.cash_in_hand = register.cash_in_hand;
    this.total_transactions = transactions.total_transactions;
    this.cash_transactions = transactions.cash_transactions;
    this.card_transactions = transactions.card_transactions;
    this.dashLoading = false;
  }

  async refreshPatients() {
    this.loading = true;
    this.paitents = undefined;
    this.todayPatients = [];
    this.dbPaitents = await this.patientService.getTodaysPatients();
    this.paitents = this.dbPaitents;
    this.loading = false;


  }

  async searchHandler(e) {
    console.log(e);
    if(!e || e=="" ) return this.paitents = this.dbPaitents;
    this.paitents = [];

    this.loading = true;
    this.paitents = await this.patientService.searchPatient(e);
    this.loading = false;

  }

}
