import { Component, Input, Output, EventEmitter,OnInit, ViewEncapsulation } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
} from 'date-fns';
import { DatePipe } from '@angular/common';
import { AppointmentsService } from 'src/app/appointments.service';

@Component({
  selector: 'app-appointment-calendar',
  templateUrl: './appointment-calendar.component.html',
  styleUrls: ['./appointment-calendar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentCalendarComponent implements OnInit {
  loading = false;
  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Month;
  console = console;
  viewDate: Date = new Date();
  appointments:any  = [];
  events: CalendarEvent[] = [];
  startDate: any;
  endDate: any;

  locale: string = 'en';
  // @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  //For Pagination
  page_no: Number = 1;
  offset;
  search;
  pages;
  total;
  page_numbers = [];
  page: any = {};
  totalRecords: number

  
  viewChange(calendarView:CalendarView) {
    this.view = calendarView;
    this.getAppointments();
    // console.log("View Changed!" + this.viewDate);
  }

  dayClicked(e) {
    // console.log(e);
    this.viewDate = e.date;
    this.view = CalendarView.Day;
    this.getAppointments();
  }

  dateChange(e) {
    console.log(e);
  }

  async getAppointments() {    
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    let datePipe = new DatePipe('en-US');
    this.loading = true;
    this.events = [];
    this.startDate = datePipe.transform(getStart(this.viewDate),'yyyy-MM-dd');
    this.endDate = datePipe.transform(getEnd(this.viewDate),'yyyy-MM-dd');

    if(this.view == 'month'){
      this.appointments = await this.appointmentService.getByDate(this.startDate, this.endDate);
    }
    else{
      let res = await this.appointmentService.getByDateWithPaging(this.startDate, this.endDate, this.page_no);
      this.page_no = Number(res.page);
      this.totalRecords = res.total;
      this.page = this.getPage(this.totalRecords, 1);
      this.appointments =  res.appointments;
    }

    if(this.appointments) this.events = this.appointments.map(app=>{
      return {
        title: app.firstName,
        start: new Date(datePipe.transform(app.date,'yyyy-MM-dd')),
        allDay:true,
        meta:{
          app
        }
      }
    })

    this.loading = false;
  }

  async setPage(page: number) {
    this.page = this.getPage(this.totalRecords, page);
    let res = await this.appointmentService.getByDateWithPaging(this.startDate, this.endDate, page);
    this.appointments = res.appointments;
  }

  getPage(totalItems: number, currentPage: number = 1, pageSize: number = 8) {
    // calculate total pages
    if (totalItems<=0 || typeof(totalItems) == 'undefined')
    totalItems=1;
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 8) {
        // less than 8 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 4) {
            startPage = 1;
            endPage = 8;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 7;
            endPage = totalPages;
        } else {
            startPage = currentPage - 3;
            endPage = currentPage + 4;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }

  constructor(public appointmentService:AppointmentsService) { }

  ngOnInit(): void {
    this.getAppointments();
  }

  async cancelSearch() {
    this.search = undefined;
    this.appointments = this.getAppointments();
    this.page_numbers = new Array(this.pages).fill(1).map((v,i)=>(i+1));
    return;
  }

  async searchHandler(e) {
    console.log(e);
    if(!e || e=="" ) {
       this.appointments = this.getAppointments();
       this.page_numbers = new Array(this.pages).fill(1).map((v,i)=>(i+1));
       return;
    }
    this.page_numbers = []
    this.appointments = [];
    this.page_no;
    //this.loading = true;
    let searchResult = await this.appointmentService.searchAllAppointment(e);

    let startDate = new Date(this.startDate);
    let endDate = new Date(this.endDate);;

    this.appointments = searchResult.filter(a => {
      var date = new Date(a.date);
       return (date >= startDate && date <= endDate);
    });

    //this.loading = false;
  }

}
