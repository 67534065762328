<app-default-header></app-default-header>
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col">
          <div class="card shadow border-0">
            <div class="card-header">
                <div class="row no-gutters align-items-center justify-content-between">
                  <div class="col-auto">
                      <h2>All Patients</h2>
                </div>
                  <div class="col-auto">
                      <button class="btn btn-success mx-1 mx-sm-0" (click)="this.router.navigate(['/create-patient'])">Create Patient</button>
                  </div>
                </div>

            </div>
            <div class="card-body">
              <!-- <div class="row mb-3">
                <div class="col-auto">
                  <label for="fromDate" class="form-control-label">From:</label>
                  <input type="date" name="fromDate" [(ngModel)]="from" (ngModelChange)="refreshPatients()"
                    class="form-control form-control-alternative" />
                </div>
                <div class="col-auto">
                  <label for="toDate" class="form-control-label">To:</label>
                  <input type="date" name="toDate" [(ngModel)]="to" (ngModelChange)="refreshPatients()"
                    class="form-control form-control-alternative" />
                </div>
              </div> -->
              <div class="row no-gutters g-0 mb-1 align-items-center">
                  <div class="col pb-1">
                      <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control" placeholder="Search by name or CR No.">
                  </div>
                  <div *ngIf="search" (click)="search = undefined" class="col-auto">
                      <button  class="btn btn-warning ml-2 mt--1">Cancel</button>
                  </div>
              </div>

              <div *ngIf="loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                  <span style="color:#333">Loading...</span>
              </div>
              <div *ngIf="paitents && paitents.length == 0 && !loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                <h3 *ngIf="search" style="color:#333">No Search Items</h3>
                <h3 *ngIf="!search">No OPD Visits or Patients created today. Please create a new paitent / mark an opd visit.</h3>
              </div>

              <app-patient-list [patients]="paitents" (patientUpdated)="opdModalSuccess()" [showToken]="false"></app-patient-list>

            </div>

              <div class="card-footer py-4">
                <nav aria-label="...">
                  <ul *ngIf="page.pages && page.pages.length" class="pagination mb-0" style="float: right;">
                    <li class="page-item {{(page.currentPage===1)?'disabled':''}}">
                      <a class="page-link" (click)="setPage(1)">
                        <i class="fas fa-angle-left"></i>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>

                    <li *ngFor="let num of page.pages" class="page-item {{page.currentPage == num ? 'active': ''}}">
                      <a class="page-link" (click)="setPage(num)">{{num}}</a>
                    </li>

                    <li class="page-item {{(page.currentPage === page.totalPages)?'disabled':''}}">
                      <a class="page-link" (click)="setPage(page.totalPages)">
                        <i class="fas fa-angle-right"></i>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>

                  <!-- <ul class="pagination justify-content-end mb-0">
                    <li class="page-item {{(page_no==1)?'disabled':''}}">
                      <a class="page-link" [routerLink]="(page_no!=1)?['/patients/'+(+page_no-1)]:[]" tabindex="-1">
                        <i class="fas fa-angle-left"></i>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li *ngFor="let page of page_numbers" class="page-item {{(page == page_no)? 'active':''}}">
                        <a class="page-link" [routerLink]="['/patients/'+page]">{{page}}</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" [routerLink]="(page_no<page_numbers.length)?['/patients/'+(+page_no+1)]:[]">
                        <i class="fas fa-angle-right"></i>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul> -->
                </nav>
            </div>
          </div>
        </div>
      </div>
</div>
