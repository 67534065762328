<app-default-header></app-default-header>
<div class="container-fluid mt-4 mb-7">
  <div class="row">
    <div class="col-12 col-xl-12">
      <div class="card shadow border-0">
        <div class="card-header">
          <h2 *ngIf="!edit">Create New Patient </h2>
          <h2 *ngIf="edit">Edit Patient Information</h2>
        </div>
        <div class="card-body">
          <form name="create_patient" >
              <fieldset [disabled]="loading">
            <div
              class="row no-gutters"
              style="
                margin-left: -1.5rem;
                margin-right: -1.5rem;
                margin-top: -1.5rem;
                border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
              "
            >
              <div
                class="col-12 px-4 py-2 mb-3"
                style="
                  border: 1px;
                  border-color: rgba(0, 0, 0, 0.1);
                  border-style: solid;
                  background-color: rgba(0, 0, 0, 0.02);
                "
              >
                <h2 class="p-0 m-0">Personal Details</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3 col-xl-2">
                <div class="form-group">
                  <label>Title:* </label>
                  <select name="title" [(ngModel)]="formData['title']" (ngModelChange)="validateField('title')" class="form-control">
                    <option value="">Please select a title</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Miss.">Miss.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Dr.">Dr.</option>
                  </select>
                  <div *ngIf="error['title']" class="alert text-danger"> {{error['title']}}</div>
                </div>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-3">
                <div class="form-group">
                  <label for="firstName">First Name:* </label
                  ><input type="text" name="firstName" [(ngModel)]="formData['firstName']" (ngModelChange)="validateField('firstName')" class="form-control" />
                  <div *ngIf="error['firstName']" class="alert text-danger"> {{error['firstName']}}</div>
                </div>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-3">
                <div class="form-group">
                  <label>Middle Name: </label
                  ><input type="text" name="middleName"  [(ngModel)]="formData['middleName']" (ngModelChange)="validateField('middleName')" class="form-control" />
                </div>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-4">
                <div class="form-group">
                  <label>Last Name:* </label
                  ><input type="text" name="lastName" [(ngModel)]="formData['lastName']" (ngModelChange)="validateField('lastName')" class="form-control" />
                  <div *ngIf="error['lastName']" class="alert text-danger"> {{error['lastName']}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xl-3">
                <div class="form-group">
                  <label for="gender">Gender:* </label
                  ><select name="gender" [(ngModel)]="formData['gender']" (ngModelChange)="validateField('gender')" class="form-control">
                    <option value="">Please select a gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="T">Transexual</option>
                    <option value="O">Other</option>
                  </select>
                  <div *ngIf="error['gender']" class="alert text-danger"> {{error['gender']}}</div>
                </div>
              </div>
              <div class="col-md-6 col-xl-3">
                <div class="form-group">
                  <label for="dob">DOB:* </label
                  ><input type="date" name="dob" [(ngModel)]="formData['dob']" (ngModelChange)="validateField('dob')" class="form-control" />
                  <div *ngIf="error['dob']" class="alert text-danger"> {{error['dob']}}</div>
                </div>
              </div>
              <div class="col-md-6 col-xl-3">

                <div class="form-group">
                  <label for="crNumber">CR Number: </label
                  ><input type="text" name="crNumber" class="form-control" [(ngModel)]="formData['crNumber']" (change)="validateCR()" (ngModelChange)="validateField('crNumber'); this.crExists = false;"/>
                  <div *ngIf="error['crNumber']" class="alert text-danger"> {{error['crNumber']}}</div>
                  <div *ngIf="checkingCR" class="alert text-warning">
                    <i class="fa fa-spinner fa-spin"></i> Validating CR Number.
                  </div>
                  <div *ngIf="crExists" class="alert text-danger">
                    CR Number already exists. <a *ngIf="crID" [routerLink]="['/patient/'+crID]">Go To Profile.</a>
                  </div>
                </div>

              </div>
              <div class="col-md-6 col-xl-3">
                <div class="form-group">
                  <div class="form-group">
                    <label for="placeOfbirth">Place of Birth: </label
                    ><input
                      type="text"
                      name="placeOfBirth"
                      class="form-control"
                      [(ngModel)]="formData['placeOfBirth']" (ngModelChange)="validateField('placeOfBirth')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-2">
                <div class="form-group">
                  <div class="form-group">
                    <label for="religion">Religion: </label
                    ><input type="text" name="religion" class="form-control" [(ngModel)]="formData['religion']" (ngModelChange)="validateField('religion')"/>
                  </div>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <label for="occupation">Occupation: </label
                  ><input type="text" name="occupation" class="form-control" [(ngModel)]="formData['occupation']" (ngModelChange)="validateField('occupation')" />
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <label for="allergies">Allergies: </label
                  ><input type="text" name="allergies" class="form-control" [(ngModel)]="formData['allergies']" (ngModelChange)="validateField('allergies')"/>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="form-group">
                  <label for="bloodGroup">Blood Group: </label
                  ><select name="bloodGroup" [(ngModel)]="formData['bloodGroup']" (ngModelChange)="validateField('bloodGroup')" class="form-control">
                    <option value="">Please select a Blood Group</option>
                    <option value="A+">A RhD positive (A+)</option>
                    <option value="A-">A RhD negative (A-)</option>
                    <option value="B+">B RhD positive (B+)</option>
                    <option value="B-">B RhD negative (B-)</option>
                    <option value="O+">O RhD positive (O+)</option>
                    <option value="O-">O RhD negative (O-)</option>
                    <option value="AB+">AB RhD positive (AB+)</option>
                    <option value="AB-">AB RhD negative (AB-)</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-4">
                <div class="form-group">
                  <div class="form-group">
                    <label for="religion">Patient Aadhar Card Number:* </label>
                    <input type="text" name="religion" class="form-control" [(ngModel)]="formData['AadharCardNumber']" placeholder="Aadhar Card Number"/>
                    <div *ngIf="error['AadharCardNumber']" class="alert text-danger"> {{error['AadharCardNumber']}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row no-gutters cntHeaders">
              <div class="col-12 px-4 py-2 mb-3 h2Div">
                <h2 class="p-0 m-0">Contact Details</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="phoneNumber">Contact Number:* </label>
                  <div class="row no-gutters">
                    <div class="col-auto align-self-center pr-1">+91 -</div>
                    <div class="col">
                      <input
                        type="number"
                        name="phoneNumber"
                        class="form-control"
                        [(ngModel)]="formData['phoneNumber']" (ngModelChange)="validateField('phoneNumber')"
                      />
                    </div>
                  </div>
                  <div *ngIf="error['phoneNumber']" class="alert text-danger"> {{error['phoneNumber']}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email: </label
                  ><input type="email" name="email" [(ngModel)]="formData['email']" (ngModelChange)="validateField('email')" class="form-control" />
                  <div *ngIf="error['email']" class="alert text-danger"> {{error['email']}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="line1">Address Line 1*</label
                  ><input type="text" name="line1" [(ngModel)]="formData['line1']" (ngModelChange)="validateField('line1')" class="form-control" />
                  <div *ngIf="error['line1']" class="alert text-danger"> {{error['line1']}}</div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="line2">Address Line 2*</label
                  ><input type="text" name="line2" [(ngModel)]="formData['line2']" (ngModelChange)="validateField('line2')" class="form-control" />
                  <div *ngIf="error['line2']" class="alert text-danger"> {{error['line2']}}</div>
                </div>
              </div>
            </div>
            <div
              class="row no-gutters"
              style="
                margin-left: -1.5rem;
                margin-right: -1.5rem;
                border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
              "
            >
              <div
                class="col-12 px-4 py-2 mb-3"
                style="
                  border: 1px;
                  border-color: rgba(0, 0, 0, 0.1);
                  border-style: solid;
                  background-color: rgba(0, 0, 0, 0.02);
                "
              >
                <h2 class="p-0 m-0">Other Details</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="refferedBy">Reffered By: </label
                  ><input type="text" name="refferedBy" [(ngModel)]="formData['refferedBy']" (ngModelChange)="validateField('refferedBy')" class="form-control" />
                </div>
              </div>
            </div>
            <div *ngIf="main_error.visible" class="alert text-danger mb-3 text-left"> {{main_error.message}} </div>
        </fieldset>
        </form>
        </div>
        <div class="card-footer text-right">
          <button class="btn btn-success" [disabled]="loading" (click)="submit()">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
