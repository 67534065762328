import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location, DatePipe } from "@angular/common";
import { PatientService } from "src/app/patient.service";
import Papa from "papaparse";

@Component({
  selector: "app-opd-patients",
  templateUrl: "./opd-patients.component.html",
  styleUrls: ["./opd-patients.component.css"],
})
export class OpdPatientsComponent implements OnInit {
  from = "";
  to = "";
  date = new Date();
  todays_date = new Date();
  edit: false;
  page_no: Number = 0;
  offset;
  pages;
  total;
  search;
  exporting = false;
  loading = false;
  page_numbers = [];
  dbPaitents: any[] = undefined;
  paitents: any[] = undefined;
  showPaging = true;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    public patientService: PatientService
  ) {}

  prev() {
    this.date = new Date(this.date.setDate(this.date.getDate() - 1));
    this.search = "";
    this.router.navigate(["/opd-patients/1"]);
    if (this.page_no == 1) this.refreshPatients();
  }

  next() {
    this.date = new Date(this.date.setDate(this.date.getDate() + 1));
    this.search = "";
    this.router.navigate(["/opd-patients/1"]);
    if (this.page_no == 1) this.refreshPatients();
  }

  setDate = function (date) {
    this.date = new Date(date);
    this.search = "";
    this.router.navigate(["/opd-patients/1"]);
    if (this.page_no == 1) this.refreshPatients();
    this.edit = false;
  };

  ngOnInit(): void {
    let from = new Date();
    let to = new Date();
    let datePipe = new DatePipe("en-US");
    //from.setDate(1);
    from.setHours(0, 0, 0, 0);
    //to.setMonth(+to.getMonth() + 1);
    to.setHours(0, 0, 0, 0);
    //to.setDate(0);
    this.to = datePipe.transform(to, "yyyy-MM-dd");
    this.from = datePipe.transform(from, "yyyy-MM-dd");
    this.route.params.subscribe((params) => {
      if (!params.page_no) this.router.navigate["/opd-patients/1"];
      this.page_no = Number(params["page_no"]);
      if (!this.page_no) this.location.back();
      this.refreshPatients();
    });
  }

  opdModalSuccess() {
    this.refreshPatients();
  }

  async refreshPatients() {
    this.loading = true;
    this.paitents = undefined;

    //let res = await this.patientService.getOpdByDate(this.page_no,this.date.getDate(),this.date.getMonth(),this.date.getFullYear());
    let res = await this.patientService.getOpdByDateRange(
      this.to,
      this.from,
      this.page_no
    );
    this.page_no = Number(res.page);
    this.pages = res.pages;
    this.page_numbers = new Array(this.pages).fill(1).map((v, i) => i + 1);
    this.total = res;
    this.offset = res.offset;
    this.dbPaitents = res.patients;
    if (res.pages < this.page_no)
      this.router.navigate(["/opd-patients/" + res.pages]);
    this.paitents = this.dbPaitents;
    this.showPaging = true;
    this.loading = false;
  }

  async export() {
    this.exporting = true;
    let datepipe = new DatePipe("en-US");

    //let fields = ["OPD Visit Date", "Visit Time", "Name", "CR Number", "Gender", "Date of Birth", "Adress"];
    let fields = [
      "OPD Visit Date",
      "Visit Time",
      "Name",
      "CR Number",
      "Gender",
      "Date of Birth",
      "Adress",
      "Appointment Status",
      "Walk-In Status",
      "Type",
    ];
    let data, downloadLnk;

    if (!this.search) {
      //let patients = await this.patientService.getAllOpdByDate(this.date.getDate(),this.date.getMonth(),this.date.getFullYear());
      let patients = await this.patientService.getAllOpdByDateRange(
        this.to,
        this.from
      );
      downloadLnk = `opd-patients-${datepipe.transform(
        patients[0].entryCreatedAt,
        "mediumDate"
      )}.csv`;
      data = patients.map((patient) => {
        let name =
          patient.title + " " + patient.firstName + " " + patient.lastName;
        return [
          datepipe.transform(patient.entryCreatedAt, "mediumDate"),
          datepipe.transform(patient.entryCreatedAt, "mediumTime"),
          name,
          patient.crNumber,
          patient.gender,
          patient.dob,
          patient.line1 + ", " + patient.line2,
          patient.tokenType == "A"
            ? patient.isCompleted
              ? "Yes"
              : "No"
            : "No",
          patient.tokenType == "W"
            ? patient.isCompleted
              ? "Yes"
              : "No"
            : "No",
          patient.tokenType,
        ];
      });
    } else {
      let patients = this.paitents;
      downloadLnk = `opd-patients-${datepipe.transform(
        patients[0].createdAt,
        "mediumDate"
      )}.csv`;
      data = patients.map((patient) => {
        let name =
          patient.title + " " + patient.firstName + " " + patient.lastName;
        return [
          datepipe.transform(patient.createdAt, "mediumDate"),
          datepipe.transform(patient.createdAt, "mediumTime"),
          name,
          patient.crNumber,
          patient.gender,
          patient.dob,
          patient.line1 + ", " + patient.line2,
          patient.tokenType == "A"
            ? patient.isCompleted
              ? "Yes"
              : "No"
            : "No",
          patient.tokenType == "W"
            ? patient.isCompleted
              ? "Yes"
              : "No"
            : "No",
          patient.tokenType,
        ];
      });
    }

    let exportData = { fields, data };

    let csv = Papa.unparse(exportData, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
      columns: null,
    });
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = downloadLnk;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.exporting = false;
  }

  async searchHandler(e) {
    if (!e || e == "") return (this.paitents = this.dbPaitents);
    this.paitents = [];
    this.loading = true;
    this.paitents = await this.patientService.searchOPDPatient(e);
    this.showPaging = false;
    this.loading = false;
  }

  async cancel() {
    this.search = "";
    this.paitents = this.dbPaitents;
    this.showPaging = true;
  }
}
