import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth.service";
import { Location, DatePipe } from "@angular/common";
import { TransactionsService } from "src/app/transactions.service";
import { ActivatedRoute, Router } from "@angular/router";
import Papa from "papaparse";
import { startCase } from "lodash";

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"],
})
export class TransactionsComponent implements OnInit {
  transactions = undefined;
  objectKeys = Object.keys;
  from = "";
  to = "";
  page_no: Number = 0;
  offset;
  pages;
  total;
  page_numbers = [];
  page: any = {};
  totalRecords: number;
  exporting = false;
  startCase = startCase;
  refundModalOpen = false;
  selectedTransaction;
  viewModalOpen = false;
  items;

  constructor(
    public auth: AuthService,
    public location: Location,
    public router: Router,
    public transactionsService: TransactionsService,
    public route: ActivatedRoute
  ) {}

  refund(transaction) {
    this.selectedTransaction = transaction;
    this.refundModalOpen = true;
  }

  view(items) {
    this.items = items;
    this.viewModalOpen = true;
  }

  ngOnInit(): void {
    let from = new Date();
    let to = new Date();
    let datePipe = new DatePipe("en-US");
    from.setDate(1);
    from.setHours(0, 0, 0, 0);
    to.setMonth(+to.getMonth() + 1);
    to.setHours(0, 0, 0, 0);
    to.setDate(0);
    this.to = datePipe.transform(to, "yyyy-MM-dd");
    this.from = datePipe.transform(from, "yyyy-MM-dd");
    this.route.params.subscribe((params) => {
      if (!params.page_no) this.router.navigate["/transactions/1"];
      this.page_no = Number(params["page_no"]);
      if (!this.page_no) this.location.back();
      this.refreshTransactions();
    });
  }

  async export() {
    this.exporting = true;
    let transactions = await this.transactionsService.getAllTransactionsByDate(
      this.to,
      this.from
    );
    let datePipe = new DatePipe("en-US");

    let fields = [
      "Date",
      "Time",
      "Type",
      "For",
      "Patient",
      "Mode of Payment",
      "Initiated By",
      "Amount",
      "Opening Balance",
      "Closing Balance",
    ];

    let data = transactions.map((transaction) => {
      let dateTime = new Date(transaction.createdAt);
      let formatedDate = datePipe.transform(dateTime, "mediumDate");
      let formatedTime = datePipe.transform(dateTime, "mediumTime");
      let _for =
        transaction.name == "other" && transaction.items[0]
          ? transaction.items[0].name
          : startCase(transaction.name);
      let name =
        transaction.patient_id.title +
        " " +
        transaction.patient_id.firstName +
        " " +
        transaction.patient_id.lastName;

      return [
        formatedDate,
        formatedTime,
        transaction.type.toUpperCase(),
        _for,
        name,
        transaction.mode_of_payment.toUpperCase(),
        transaction.initiated_by.username,
        transaction.amount == 0 ? "Poor Free" : transaction.amount,
        transaction.openingBalance,
        transaction.closingBalance,
      ];
    });

    let exportData = {
      fields,
      data,
    };

    let csv = Papa.unparse(exportData, {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
      columns: null,
    });
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "data.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.exporting = false;
  }

  refreshTransactions() {
    this.transactions = undefined;

    this.transactionsService
      .getTransactionsByDate(this.to, this.from, this.page_no)
      .then((res) => {
        this.transactions = res.transactions;

        console.log(res.transactions);
        this.page_no = Number(res.page);
        this.totalRecords = res.total;
        this.setPage(1);
        // this.pages = res.pages;
        // this.page_numbers = new Array(this.pages).fill(1).map((v, i) => i + 1);
        // this.total = res;
        // this.offset = res.offset;

        if (res.pages < this.page_no)
          this.router.navigate(["/transactions/" + res.pages]);
      });
  }

  async setPage(page: number) {
    this.transactions = undefined;
    this.page = this.getPage(this.totalRecords, page);
    let res = await this.transactionsService.getTransactionsByDate(
      this.to,
      this.from,
      page
    );
    this.transactions = res.transactions;
  }

  getPage(totalItems: number, currentPage: number = 1, pageSize: number = 15) {
    // calculate total pages
    if (totalItems <= 0 || typeof totalItems == "undefined") totalItems = 1;
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 15) {
      // less than 15 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 4) {
        startPage = 1;
        endPage = 15;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 7;
        endPage = totalPages;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
