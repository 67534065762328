<app-default-header></app-default-header>
<div class="container-fluid mt-4">
  <div class="row">
    <div class="col">
      <div class="card shadow border-0">
        <div class="card-header">
          <div class="row no-gutters align-items-center justify-content-between">
            <div class="col-auto">
              <h2>Appointment Calendar</h2>
            </div>
            <div class="col-auto">
            </div>
          </div>
        </div>
        <div class="card-body">
          <!-- <div style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                        <span style="color:#333">No Appointments today</span>
                    </div> -->

          <div class="row text-center align-items-center justify-content-center justify-content-md-start">
            <div class="col-auto">
              <div class="btn-group">
                <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                  (viewDateChange)="getAppointments()">
                  Previous
                </div>
                <div class="btn btn-secondary" mwlCalendarToday [(viewDate)]="viewDate"
                  (viewDateChange)="getAppointments()">
                  Today
                </div>
                <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                  (viewDateChange)="getAppointments()">
                  Next
                </div>
              </div>
            </div>
            <div class="col-12 py-3 col-md py-md-0">
              <h3>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</h3>
            </div>
            <div class="col-auto">
              <div class="btn-group">
                <div class="btn btn-primary" (click)="viewChange(CalendarView.Month)"
                  [class.active]="view === CalendarView.Month">
                  Month
                </div>
                <!-- <div class="btn btn-primary" (click)="viewChange(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                              Week
                            </div> -->
                <div class="btn btn-primary" (click)="viewChange(CalendarView.Day)"
                  [class.active]="view === CalendarView.Day">
                  Day
                </div>
              </div>
            </div>
          </div>
          <br />

          <ng-template #customCellTemplate let-day="day" let-locale="locale">
            <div class="cal-cell-top">
              <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
              <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            <!-- <small style="margin: 5px;">There are {{ day.events.length }} appointments on this day</small> -->
            <div *ngIf="day.inMonth" class="text-right d-none d-md-block">
              <span class="m-1 badge badge-primary">{{day.events.length}} Appointments</span>
            </div>
          </ng-template>

          <div *ngIf="!loading" [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" (dayClicked)="dayClicked($event.day)"
              [events]="events" [cellTemplate]="customCellTemplate">
            </mwl-calendar-month-view>
            <!-- <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [cellTemplate]="customWeekCell">
                        </mwl-calendar-week-view> -->
            <!-- <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" >
                        </mwl-calendar-day-view> -->
            <div *ngSwitchCase="'day'">
              <h1>Appointments </h1>

              <div class="row no-gutters g-0 mb-1 align-items-center">
                <div class="col pb-1">
                  <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control"
                    placeholder="Search by patient name, phone number, email or Appointment No.">
                </div>
                <div *ngIf="search" (click)="cancelSearch()" class="col-auto">
                  <button class="btn btn-warning ml-2 mt--1">Cancel</button>
                </div>
              </div>

              <app-appointments-list [appointments]="appointments" (onSuccess)="getAppointments()">
              </app-appointments-list>
              <!-- <div *ngFor="let event of events">
                                {{event.title}} , {{event.start | date:'mediumDate'}}
                            </div> -->

              <div class="card-footer py-4">
                <nav aria-label="...">
                  <ul *ngIf="page.pages && page.pages.length" class="pagination mb-0" style="float: right;">
                    <li class="page-item {{(page.currentPage===1)?'disabled':''}}">
                      <a class="page-link" (click)="setPage(1)">
                        <i class="fas fa-angle-left"></i>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
    
                    <li *ngFor="let num of page.pages" class="page-item {{page.currentPage == num ? 'active': ''}}">
                      <a class="page-link" (click)="setPage(num)">{{num}}</a>
                    </li>
    
                    <li class="page-item {{(page.currentPage === page.totalPages)?'disabled':''}}">
                      <a class="page-link" (click)="setPage(page.totalPages)">
                        <i class="fas fa-angle-right"></i>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div *ngIf="loading" style="height: 300px; width:100%; background-color: silver;"
            class="d-flex align-items-center justify-content-center">
            <i class="fa fa-spinner fa-spin"></i> Loading....
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
