import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment-settings',
  templateUrl: './appointment-settings.component.html',
  styleUrls: ['./appointment-settings.component.css']
})
export class AppointmentSettingsComponent implements OnInit {

  array = Array(76).fill(1)
  loading = true;
  settings;
  constructor() { }

  ngOnInit(): void {
  }

  deleteExcludedWeekDay(_id) {

  }

}
