import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseUrl } from "./config";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  constructor(private httpClient: HttpClient) {}

  getHeaders() {
    return {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };
  }

  async getPatients(to:string,from:string,page_no) {
    try {
      let res: any = await this.httpClient
        .get(`${baseUrl}patients/${to}/${from}/${page_no}`, this.getHeaders())
        .toPromise();
      console.log(res);
      return res;
    } catch (e) {
      console.log(e);
      return { error: "Some Error Occured Adding Paitent" };
    }
  }

  async getOpdByDate(page_no, date?: Number, month?: Number, year?: Number) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `patients/opd/byDate/${page_no}?date=${date}&month=${month}&year=${year}`,
        this.getHeaders()
      )
      .toPromise();
    return res;
  }

  async getAllOpdByDate(date?: Number, month?: Number, year?: Number) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `patients/opd/byDate/all?date=${date}&month=${month}&year=${year}`,
        this.getHeaders()
      )
      .toPromise();
    return res.patients;
  }

  async getOpdByDateRange(to:string,from:string,page_no) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `patients/opd/byDateRange/${to}/${from}/${page_no}`,
        this.getHeaders()
      )
      .toPromise();
    return res;
  }

  async getAllOpdByDateRange(to:string,from:string) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          `patients/opd/byDateRange/${to}/${from}`,
        this.getHeaders()
      )
      .toPromise();
    return res.patients;
  }

  async getOPDPatients(page_no) {
    try {
      let res: any = await this.httpClient
        .get(`${baseUrl}patients/opd/${page_no}`, this.getHeaders())
        .toPromise();
      console.log(res);
      return res;
    } catch (e) {
      console.log(e);
      return { error: "Some Error Occured Adding Paitent" };
    }
  }
  async searchOPDPatient(query) {
    let res: any = await this.httpClient
      .post(baseUrl + "patients/opd/search", { query }, this.getHeaders())
      .toPromise();
    console.log(res);
    return res.patients;
  }

  async addPatient(patient) {
    try {
      return await this.httpClient
        .post(baseUrl + "patient", patient, this.getHeaders())
        .toPromise();
    } catch (e) {
      console.log(e);
      return { error: "Some Error Occured Adding Paitent" };
    }
  }

  async getPatient(_id) {
    let res: any = await this.httpClient
      .get(baseUrl + "patient/" + _id, this.getHeaders())
      .toPromise();
    return res.patient;
  }

  async getTodaysPatients() {
    let res: any = await this.httpClient
      .get(baseUrl + "patients/today", this.getHeaders())
      .toPromise();
    return res.patients;
  }

  async searchPatient(query) {
    let res: any = await this.httpClient
      .post(baseUrl + "patients/search", { query }, this.getHeaders())
      .toPromise();
    console.log(res);
    return res.patients;
  }

  async markOPDEntry(patient_id, mode_of_payment, purpose, appointment_id?) {
    let queryString = appointment_id ? "?appointment_id=" + appointment_id : "";
    return await this.httpClient
      .post(
        baseUrl + `patient/${patient_id}/markEntry${queryString}`,
        { mode_of_payment, purpose },
        this.getHeaders()
      )
      .toPromise();
  }

  async getOPDEntry(patient_id) {
    return (<any>(
      await this.httpClient
        .get(baseUrl + `patient/${patient_id}/entries`, this.getHeaders())
        .toPromise()
    )).entries;
  }

  async getTransactions(patient_id, to, from, page_no) {
    let res: any = await this.httpClient
      .get(
        baseUrl +
          "patient/" +
          patient_id +
          "/transactions/" +
          to +
          "/" +
          from +
          "/" +
          page_no,
        this.getHeaders()
      )
      .toPromise();
    return res;
  }

  async getAllTransactionsByPatientID(patient_id, page_no) {
    let res: any = await this.httpClient
      .get(
        baseUrl + "patient/" + patient_id + "/transactions/" + page_no,
        this.getHeaders()
      )
      .toPromise();
    return res;
  }

  async markPoorFree(patient_id, poorFree) {
    return (<any>(
      await this.httpClient
        .post(
          baseUrl + `patient/${patient_id}/poorFree`,
          { poorFree },
          this.getHeaders()
        )
        .toPromise()
    )).patient;
  }

  async checkCRNumber(crNumber) {
    return <any>(
      await this.httpClient
        .get(baseUrl + `patient/checkCrNumber/${crNumber}`, this.getHeaders())
        .toPromise()
    );
  }

  //Add Mark OPD Note
  async markOPDNote(body, patient_id) {
    let res: any = await this.httpClient
      .post(
        baseUrl + `patient/${patient_id}/addUpdateNote`,
        body,
        this.getHeaders()
      )
      .toPromise();
    return res.settings;
  }

  async updatePatient(body, patient_id) {
    let res: any = await this.httpClient.post(baseUrl + `patient/${patient_id}/updatepatient`, body, this.getHeaders()).toPromise();
    return res.patient;
  }

  async updatePatPay(body, patient_id) {
    let res: any = await this.httpClient.post(baseUrl + `patient/${patient_id}/updatePatPay`, body, this.getHeaders()).toPromise();
    return res.patient;
  }
}
