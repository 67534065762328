import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {baseUrl} from './config';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(private httpClient: HttpClient) { }

  getHeaders() {
    return {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}};
  }

  async getTransactions(page_no) {
      let res:any = await this.httpClient.get(baseUrl + "transactions/" + page_no,this.getHeaders()).toPromise()
      return res;
  }

  async getAllTransactionsByDate(to:String,from:string) {
    let res:any = await this.httpClient.get(baseUrl + "transactions/all/" + to + "/" + from + "/",this.getHeaders()).toPromise()
    return res.transactions;
  }

  async getTransactionsByDate(to:string,from:string,page_no) {
    let res:any = await this.httpClient.get(baseUrl + "transactions/" + to + "/" + from + "/" + page_no,this.getHeaders()).toPromise()
    return res;
  }

  async getBankResponseTransactionsByDate(to:string,from:string,page_no) {
    let res:any = await this.httpClient.get(baseUrl + "bankresponsetransactions/" + to + "/" + from + "/" + page_no,this.getHeaders()).toPromise()
    return res;
  }

  async getTodaysOverview() {
      let res:any = await this.httpClient.get(baseUrl + "transactions/todays-overview",this.getHeaders()).toPromise()
      return res;
  }

  async postTransaction(body) {
    let res:any = await this.httpClient.post(baseUrl + "transactions",body,this.getHeaders()).toPromise()
    return res;
  }

  async dummyTransaction(body) {
    let res:any = await this.httpClient.post(baseUrl + "dummy-transactions",body,this.getHeaders()).toPromise()
    return res;
  }
}
