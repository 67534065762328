import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApprovalsService } from 'src/app/approvals.service';


@Component({
  selector: 'app-request-refund',
  templateUrl: './request-refund.component.html',
  styleUrls: ['./request-refund.component.css']
})
export class RequestRefundComponent implements OnInit {
  
  @Input() visible;
  @Output() onSuccess:EventEmitter<any> = new EventEmitter();
  @Output() onClose:EventEmitter<any> = new EventEmitter();
  @Input() transaction;
  amount = 0;
  transacted;
  transacting;
  errored;
  validationError;
  
  constructor(public approvalService:ApprovalsService) { }

  ngOnInit(): void {
   
  }

  successHandler() {
    this.onSuccess.emit()
    this.closeHandler();
  }

  closeHandler() {
    this.visible = false;
    this.onClose.emit();
  }

  validateField() {
    let flag = true;
    console.log(this.amount,this.transaction.amount)
    if(Number(this.amount) > Number(this.transaction.amount)) {
      // this.amount = Math.abs(this.maxCash);
      flag = false;
    }

    if(this.amount <= 0 ){ 
      flag = false;
    }

    if(!flag) this.validationError = "Please enter a valid Amount.";
    else this.validationError = undefined;

    return flag;
  }

  changeHandler(e) {
    this.validateField()
  }

  async clickHandler() {
    
    if(!this.validateField()) return;
    console.log("Validated!");
    this.transacting = true;
    
    try {
      await this.approvalService.requestRefund(this.amount,this.transaction.patient_id._id);
      this.errored = false;
      this.transacting = false;
      this.successHandler();
    }
    catch(err) {
      console.log(err);
      this.errored = true;
      return;
    }
  
  }
}
