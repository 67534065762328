<app-default-header></app-default-header>
<div class="container-fluid mt-4">
    <div class="row">
        <div class="col">
            <div class="card shadow border-0">
                <div class="card-header">
                    <div class="row no-gutters align-items-center justify-content-between">
                        <div class="col-auto">
                            <h2>Today's Appointments</h2>
                    </div>
                        <div class="col-auto">
                            
                        </div>
                    </div>
                    
                </div>
                <div class="card-body">
                    <div class="row no-gutters g-0 mb-1 align-items-center">
                        <div class="col pb-1">
                            <input type="text" [(ngModel)]="search" (ngModelChange)="searchHandler($event)" class="form-control" placeholder="Search by patient name, phone number, email or Appointment No.">
                        </div>
                        <div *ngIf="search" (click)="search = undefined" class="col-auto">
                            <button  class="btn btn-warning ml-2 mt--1">Cancel</button>
                        </div>
                    </div>
                    <div *ngIf="loading" style="min-height: 300px; background-color: silver; display: flex; justify-content: center; align-items: center;">
                        <i class="fa-spinner fa fa-spin"></i>Loading...
                    </div>
                    <app-appointments-list *ngIf="appointments" [appointments]="appointments" (onSuccess)="refreshAppointments()"></app-appointments-list>
                </div>
            </div>
        </div>
    </div>
</div>